import { getAsyncAwaitHttpObj, http } from '../..';
import { ContactInformation, SelectedPackage } from '../../models/cart';
import { Contact } from '../../models/contact';
export const createContact = (contact: ContactInformation) => http().post<ContactInformation>('/contacts', contact);
export const contactQuotedOptions = (contact: ContactInformation) =>
    http().post<ContactInformation>('/contacts/quotedOptions', contact);
export const updateDeal = (dealId: string, address: any) => {
    const { address1, address2, city, postcode, region } = address;
    return getAsyncAwaitHttpObj().patch(`Contacts/updateDealAddress/${dealId}`, {
      address1,
      address2,
      city,
      postcode,
      region
    });
  };
  export const updateContactEdit = (contactDetails: Contact) =>
      getAsyncAwaitHttpObj().patch<Contact>(`/contacts/workOrder/email/${contactDetails.email}`, contactDetails);

  export const contactInprogress = (selectedPackage: SelectedPackage) =>
    http().post<ContactInformation>('/contacts/inprogress', selectedPackage);