import { useTheme } from '@mui/material/styles';
import * as React from 'react';

function Hamburger(props: any) {
    const theme = useTheme();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={27} height={27} {...props}>
            <defs>
                <clipPath id="prefix__a">
                    <path className="prefix__a" d="M0 0h26v26H0z" transform="translate(.71)" />
                </clipPath>
                <style>{'.prefix__a{fill:none;opacity:0}'}</style>
            </defs>
            <path className="prefix__a" d="M0 0h27v27H0z" />
            <g transform="translate(.29)" clipPath="url(#prefix__a)">
                <path
                    d="M5.143 21a1.224 1.224 0 010-2.434h17.338a1.223 1.223 0 010 2.434zm0-6.789a1.164 1.164 0 01-1.1-1.221 1.164 1.164 0 011.1-1.212h17.338a1.164 1.164 0 011.101 1.212 1.163 1.163 0 01-1.1 1.217zm0-6.788a1.164 1.164 0 01-1.1-1.216 1.164 1.164 0 011.1-1.217h17.338a1.163 1.163 0 011.1 1.217 1.164 1.164 0 01-1.1 1.217z"
                    fill={`#333`}
                />
            </g>
        </svg>
    );
}

export default Hamburger;
