import { Box, Divider, Grid, useTheme } from '@mui/material';
import { Paragraph } from '../../components/Paragraph';
import React from 'react';
import { Button } from '../../components/Button';
import { useSelector } from 'react-redux';
import { selectScheduleList } from '../../store/selectors/selection';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../models/route';
// @ts-ignore
import CalanderIcon from '../../assets/icons/icon_Calander.svg';
// @ts-ignore
import ClockIcon from '../../assets/icons/icon_Clock.svg';
import { Schedule } from '../../models/cart';
import { Edit } from '@mui/icons-material';

function ScheduleSection() {
    const scheduleList = useSelector(selectScheduleList);
    const theme = useTheme()
    const navigate = useNavigate();
    const formatDate = (date: any) => {
        let convertDate = date.replaceAll('-', '/')
        return new Date(convertDate as Date).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
    };
    return (
        <Box sx={{ p: "20px 18px", mt:3, background: "white", borderRadius:'4px' }}>
        <Grid container spacing={0} alignItems={'center'}>
            <Grid item xs={8} sm={8}>
                <Paragraph headingStyle largeFont bold>
                {`Schedule Details`}
              </Paragraph>
            </Grid>
            <Grid item xs={4} sm={4} display={'flex'} justifyContent={'flex-end'}>
                <Button onClick={() => navigate(AppRoute.Schedule)} outlinedWithBorder>
                <Edit sx={{fontSize:'18px', mr:0.5}} />   EDIT  
                </Button>
            </Grid>
            </Grid>
            <Divider
                    sx={{
                        borderColor: theme.palette.divider,
                        borderBottomWidth: '2px',
                        mt: '15px',
                        mb: '12px',
                    }}
                />
        <Grid container spacing={0} alignItems={'center'}>
            <Grid container spacing={1} sx={{ mt: 0 }}>
                {scheduleList.map((schedule: Schedule, index: number) => {
                    return (
                        <Grid item xs={12} sm={4} key={index}>
                            <Paragraph responsiveFontSize16 sx={{ mb: '10px', pl: '4px' }}>
                                Schedule 0{index + 1}
                            </Paragraph>
                            <Paragraph
                                semiLargeFont
                                bold
                                headingColor
                                sx={{ display: 'flex', mb: '5px', alignItems: 'center' }}
                            >
                                <Box component={'img'} src={CalanderIcon} alt="info icon" sx={{ pr: 1 }} />
                                {formatDate(schedule.preferredDate)}
                            </Paragraph>
                            <Paragraph semiLargeFont bold headingColor sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box component={'img'} src={ClockIcon} alt="info icon" sx={{ pr: 1 }} />
                                {schedule.preferredTimeSlot}
                            </Paragraph>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
        </Box>
    );
}

export default ScheduleSection;
