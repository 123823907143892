import React, { PropsWithChildren } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import FormikWizardInputs from './FormikWizardInputs';
import { Options } from './WizardInputs';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/FormLabel';
import Avatar from '@mui/material/Avatar';
import { Box } from '@mui/material';
import { Grid } from '@mui/material';
import { Button } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { SXCssObject } from '../models/ThemeImages';

const createCssObject = (theme: Theme, props: StyleProps): SXCssObject => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: `calc(100% + ${theme.spacing(2)}px)`,
        margin: -theme.spacing(1),
    },
    image: {
        padding: theme.spacing(1),
        display: {
            xs: 'flex',
            sm: 'inherit',
        },
        justifyContent: {
            xs: 'center',
            sm: 'inherit',
        },
        flex: {
            xs: `0 0 100%`,
            sm: '`0 0 100px`',
        },
        width: {
            xs: '100%',
            sm: 100 + theme.spacing(2),
        },
    },
    formControl: {
        padding: theme.spacing(1),
        flex: {
            xs: `0 0 100%`,
            sm: 1,
        },
        width: {
            xs: '100%',
            sm: 'inherit',
        },
    },
    img: {
        width: 100,
        height: 100,
    },

    tooltipIconButton: {
        minWidth: '0px',
        paddingLeft: '5px',
    },
    labelContainer: {
        justifyContent: 'space-between',
    },
    label: {
        marginBottom: theme.spacing(1.5),
        color: theme.palette.text.primary,
        lineHeight: 2,
        fontWeight: props.isBold ? 500 : 'normal',
    },
    label_1: {
        '&::after': { content: '" "', borderBottom: '1px solid #000', borderColor: theme.palette.grey[600] },
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        color: theme.palette.grey[600],
        fontWeight: props.isBold ? 500 : 'normal',
    },
    wizardQuestionCard: {
        backgroundColor: '#FFFFFF',
        padding: theme.spacing(1.5),
        boxShadow: props.isBold ? '0px 5px 15px #5B5B5B17' : '0px 0px 0px',
        borderRadius: '4px',
        mb:2
    },
});

interface StyleProps {
    isBold: boolean | undefined;
}

interface WizardFormProps {
    pic: string;
}

interface Props {
    id: string;
    name: string;
    label: string;
    pic?: string;
    options: Options[];
    isBold?: boolean;
    handleTooltipOpen?: () => void;
}

export default function WizardSelection(props: Props) {
    const { isBold } = props;
    const styleProps: StyleProps = {
        isBold: isBold,
    };
    // const classes = useStyles(styleProps);
    const cssObject: SXCssObject = createCssObject(useTheme(), styleProps);
    const { id, label, pic, ...other } = props;
    return (
        <Box sx={cssObject.wizardQuestionCard}>
            <FormControl fullWidth>
                {pic ? (
                    <InputLabel id={`${id}-label`} htmlFor={id} sx={cssObject.label}>
                        <Grid sx={cssObject.labelContainer} container spacing={0}>
                            <Grid item>
                                <RadioButtonUncheckedIcon sx={{color: pic, fontSize: '14px'}} />
                            </Grid>
                            <Grid item sx={{ lineHeight: '24px' }} xs={10}>
                                {label}
                            </Grid>
                            <Grid item xs={1}>
                                {props.isBold && (
                                    <Button onClick={props.handleTooltipOpen} sx={cssObject.tooltipIconButton}>
                                        <HelpIcon sx={{ color: 'text.primary' }} />
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </InputLabel>
                ) : (
                    <InputLabel id={`${id}-label`} htmlFor={id} sx={cssObject.label_1}>
                        {label}
                    </InputLabel>
                )}
                <FormikWizardInputs id={id} {...other} />
            </FormControl>
        </Box>
    );
}

export function WizardForm(props: PropsWithChildren<WizardFormProps> & StyleProps) {
    const cssObject: SXCssObject = createCssObject(useTheme(), props);

    const { pic, children } = props;
    return (
        <Box sx={cssObject.root}>
            <Box sx={cssObject.image}>
                <Avatar src={pic} sx={cssObject.img} />
            </Box>
            <Box sx={cssObject.formControl}>{children}</Box>
        </Box>
    );
}
