import { Box, CardMedia, Divider, Grid } from "@mui/material";
import { Paragraph } from "../../components/Paragraph";
import React from "react";
import { Button } from "../../components/Button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Theme, useTheme } from "@mui/material/styles";
import CartSummary from "../../components/CartSummary";
import { get } from "lodash";
import { LevelCart } from "../../components/icons/Level";
import { SXCssObject } from "../../models/ThemeImages";
import { Level } from "../../models/product";
import {
  selectCartItems,
  selectPreCartAddon,
  selectPreCartIAQ,
  selectPreCartMain,
} from "../../store/selectors/selection";
import { AppRoute } from "../../models/route";
import { ItemType, SystemGroup } from "../../models/cart";
import { currencyFormatNoDigit } from "../../components/common/NumberUtil";
import { Edit } from "@mui/icons-material";
const createCssObject = (theme: Theme): SXCssObject => ({
  systemSKU: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    textAlign: "left",
    wordBreak: "break-all",
  },
  systemQuantity: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    textAlign: "left",
    alignContent:{
      xs:'flex-start',
      md:'flex-end'
    }
  },
  imageBox: {
    pb: {
      xs: "10px",
      sm: "inherit",
    },
  },
  img: {
    objectFit: "contain",
    border: "1px solid #D4D4D4",
    borderRadius: "4px",
    width: {
      sm: "83px",
    },
    height: {
      sm: "70px",
    },
  },
});
interface ProductProps {
  image: string;
  level: Level | "Not Today";
  systemType: string;
  quantity: number;
  price: number;
  addOnName?: string;
}
function ProductsSection() {
  const items = useSelector(selectCartItems);
  const preCartItemsMain = useSelector(selectPreCartMain);
  const preCartItemsIAQ = useSelector(selectPreCartIAQ);
  const preCartItemsAddons = useSelector(selectPreCartAddon);
  const theme = useTheme();
  const navigate = useNavigate();
  const cssObject = createCssObject(useTheme());
  const itemLevel = (item: any) => {
    if (item && item.productAttributes) {
      return get(item, "productAttributes.level", "");
    }
  };
  const itemSystemType = (item: any) => {
    if (item && item.productAttributes) {
      return get(item, "productAttributes.systemType", "");
    }
  };

  const ProductDetail = (props: ProductProps) => {
    const { image, level, systemType, price, quantity, addOnName } = props;
    const name = (
      <LevelCart
        level={level}
        systemType={systemType}
        backgroundTileColor={false}
      />
    );

    return (
      <Grid container sx={{ py: 1 }}>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          sx={cssObject.imageBox}
          justifyContent={"center"}
        >
          <CardMedia image={image} component={"img"} sx={cssObject.img} />
        </Grid>

        <Grid item xs={12} sm={4} md={6} container alignItems={"center"}>
          <Paragraph responsiveFontSize16 headingStyle bold sx={{ mb: "6px" }}>
            {addOnName || name}
          </Paragraph>
        </Grid>

        <Grid item xs={12} sm={2} md={2} container>
          {quantity > 1 && (
            <Grid item container direction={"column"} sx={cssObject.systemSKU}>
              <Paragraph fontSizeVerySmall={true}>Quantity</Paragraph>
              <Paragraph
                responsiveFontSize16
                headingStyle
                bold
                sx={{ mb: "6px", pl: 1.5 }}
              >
                {quantity}
              </Paragraph>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} sm={2} md={2} container>
          <Grid
            item
            container
            direction={"column"}
            sx={cssObject.systemQuantity}
            alignContent={"flex-end"}
          >
            <Paragraph fontSizeVerySmall={true}>System Price</Paragraph>
            <Paragraph
              responsiveFontSize16
              headingStyle
              bold
              sx={{ mb: "6px" }}
            >
              {currencyFormatNoDigit.format(price)}
            </Paragraph>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box sx={{ p: "20px 18px", mt: 3, background: "white", borderRadius:'4px' }}>
      <Grid container spacing={0} alignItems={"center"}>
        <Grid item xs={8} sm={8}>
          <Paragraph headingStyle largeFont bold>
                {`Product Details`}
              </Paragraph>
        </Grid>
        <Grid item xs={4} sm={4} display={"flex"} justifyContent={"flex-end"}>
          <Button
            // isReviewSectionEditButton={true}
            onClick={() =>
              navigate(AppRoute.EquipmentSelection, {
                state: {
                  isFromReviewPage: true,
                },
              })
            }
            outlinedWithBorder
          >
          <Edit sx={{fontSize:'18px', mr:0.5}} />   EDIT
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider
            sx={{
              borderColor: theme.palette.divider,
              borderBottomWidth: "2px",
              mt: "15px",
              mb: "12px",
            }}
          />
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          sx={{ pt: 1 }}
        >
          <Grid item xs={12} md={8} lg={9}>
            {items &&
              items?.map((item, index) => {
                return (
                  item && (
                    <Grid
                      item
                      xs={12}
                      key={index}
                      sx={{
                        py: 1,
                        background:'#f5f5f5',
                        px:2,
                        mb:2
                      }}
                    >
                      {item.itemType === ItemType.MainSystem && (
                        <Paragraph>
                          {item.systemGroup === SystemGroup.SystemGroup1
                            ? "First System"
                            : "Second System"}
                        </Paragraph>
                      )}
                      {(!item.itemType || item.itemType === ItemType.AddOn) && (
                        <Paragraph>Other Products</Paragraph>
                      )}
                      <ProductDetail
                        image={item.image}
                        level={itemLevel(item)}
                        systemType={itemSystemType(item)}
                        quantity={item.quantity}
                        price={item.price}
                        addOnName={
                          !item.itemType || item.itemType === ItemType.AddOn
                            ? item.name
                            : ""
                        }
                      />
                    </Grid>
                  )
                );
              })}
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <CartSummary
              itemsMain={preCartItemsMain}
              itemsIAQ={preCartItemsIAQ}
              addOns={preCartItemsAddons}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProductsSection;
