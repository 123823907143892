import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AppRoute } from "../models/route";
import EquipmentOptionContainer from "../pages/equipment-options/EquipmentOptionContainer";
import EquipmentSelectionContainer from "../pages/equipment-options/EquipmentSelectionContainer";
import { ScheduleInspectionContainer } from "../pages/schedule/ScheduleInspectionContainer";
import AboutYourHome from "../pages/wizard/AboutYourHome";
import ReviewSelection from "../pages/confirmation/ReviewSelection";
import EditContact from "../pages/confirmation/EditContact";
import { useSelector } from "react-redux";
import { selectUserProfile } from "../store/selectors/userDetails";
import { isEmpty } from "lodash";

export const RoutesList = () => {
  const PrivateRoute = ({ children }: any) => {
    let userProfile = useSelector(selectUserProfile);
    // return isEmpty(userProfile) ? (
    //   <Navigate to={AppRoute.Home} />
    // ) : (
      return children
    // );
  };

  return (
    <Routes>
      <Route path={AppRoute.Home} element={<AboutYourHome />} />
      <Route
        path={AppRoute.EquipmentOptions}
        element={
          <PrivateRoute>
            <EquipmentOptionContainer />
          </PrivateRoute>
        }
      />
      <Route
        path={AppRoute.EquipmentSelection}
        element={
          <PrivateRoute>
            <EquipmentSelectionContainer />
          </PrivateRoute>
        }
      />
      <Route
        path={AppRoute.ReviewSelection}
        element={
          <PrivateRoute>
            <ReviewSelection />
          </PrivateRoute>
        }
      />
      <Route
        path={AppRoute.EditContact}
        element={
          <PrivateRoute>
            <EditContact />
          </PrivateRoute>
        }
      />
      {/* <Route
                path={AppRoute.CartPage}
                element={
                    <OpenRoute>
                        <CartPageContainer />
                    </OpenRoute>
                }
            /> */}
      <Route
        path={AppRoute.Schedule}
        element={
          <PrivateRoute>
            <ScheduleInspectionContainer />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<Navigate to={AppRoute.Home} />} />
    </Routes>
  );
};
