import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
    getTaxRateService,
} from '../services/taxRateService';
import { combineEpics } from 'redux-observable';
import { updateTaxData, getTaxRate, getTaxRateError } from '../slice/taxRateSlice';

export const verifOtp$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(getTaxRate),
        switchMap((action) =>
            getTaxRateService(action.payload.postalCode).pipe(
                map((res:any) => updateTaxData(res.data)),
                catchError((err) => {
                    return of(getTaxRateError(err));
                })
            )
        )
    );

export default combineEpics(

    verifOtp$
);
