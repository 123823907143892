import * as React from 'react';

function Efficiency(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
            <defs>
                <style>{'.prefix__b6{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'}</style>
            </defs>
            <path
                className="prefix__b6"
                stroke={`#111111`}
                d="M27.803 30.22H12.168A3.168 3.168 0 019 27.052v-10.48a3.169 3.169 0 011.16-2.45l7.818-6.4a3.169 3.169 0 014.015 0l7.817 6.4a3.166 3.166 0 011.16 2.45v10.48a3.168 3.168 0 01-3.167 3.168z"
            />
            <path
                className="prefix__b6"
                stroke={`#111111`}
                d="M21.11 14.487l-3.135 5.063h4.021l-2.978 5.212"
            />
        </svg>
    );
}

export default Efficiency;
