import React from 'react';
import history from './history';
import { RoutesList } from './RoutesListComponent';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
const AppRouter: React.FC = () => {
    return (
        <HistoryRouter history={history} >
            <ScrollToTop />
            <RoutesList />
        </HistoryRouter>
    );
};

export default AppRouter;
