import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Paragraph } from "../../components/Paragraph";
import { Box, Divider, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Heading } from "../../components/Heading";
import { Button } from "../../components/Button";
import { Formik, FormikValues } from "formik";
import FormikTextField from "../../components/FormikTextField";
import FormikCheckBox from "../../components/FormikCheckBox";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingOverlay from "../../components/common/LoadingOverlay";
import {
  extractErrorFromResponseOrShowDefaultMsg,
  getRegion,
} from "../../components/common/Utilities";
import { showSnackbarSuccess } from "../../components/common/Snackbar/SnackbarHelper";
import { getCountryById } from "../../store/slice/countrySlice";
import { selectUserProfile } from "../../store/selectors/userDetails";
import { blockInvalidChar } from "../equipment-options/Utilities";
import { EditContactValidationSchema } from "./contact-schema";
import { AddressValidationModal } from "./AddressValidationModal";
import CloseBlack from "../../components/icons/CloseBlack";
import {
  AddressValidationFields,
  AddressValidationModalData,
  AddressValidationStatus,
  ContactAddressType,
  ValidateAddressResponse,
} from "../../models/userProfile";
import FormikSelectField from "../../components/FormikSelectField";
import { addressValidate } from "../../store/services/userProfileServices";
import {
  EDIT_LABEL,
  jobRegions,
  regions,
} from "../../components/common/constants";
import { updateProfileData } from "../../store/slice/userDetailsSlice";
import {
  updateContactEdit,
  updateDeal,
} from "../../store/services/hubspotServices";

export default function EditContact() {
  const dispatch = useDispatch();
  const contact = useSelector(selectUserProfile);
  const navigate = useNavigate();
  const location: any = useLocation();
  const section = location.state && location.state.section;
  const fromPage = location.state && location.state.fromPage;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [nonValidAddresses, setNonValidAddresses] = React.useState<
    AddressValidationModalData[]
  >([]);
  const country = "US";

  React.useEffect(() => {
    dispatch(getCountryById({ id: country }));
  }, [dispatch]);

  const getInitialState = (state?: string) => {
    const region = state ? getRegion(state, regions) : regions && regions[0];
    return region;
  };
  const getInitialJobState = (state?: string) => {
    const region = state
      ? getRegion(state, jobRegions)
      : jobRegions && jobRegions[0];
    return region;
  };
  const handleUpdateAddress = (values: FormikValues) => {
    const onlySingleAddressNotValid =
      nonValidAddresses.length === 1 && nonValidAddresses[0].flow;
    const address = {
      city: nonValidAddresses[0].suggestedAddress?.city,
      zip: nonValidAddresses[0].suggestedAddress?.postcode,
      state: nonValidAddresses[0].suggestedAddress?.region,
    };
    const newValues = {
      ...values,
      //updating job address only based on sugested address
      // @ts-ignore
      ...(onlySingleAddressNotValid === ContactAddressType.Home
        ? {
            jobCity: address.city,
            jobZip: address.zip,
            jobState: address.state,
          }
        : // @ts-ignore
        onlySingleAddressNotValid === ContactAddressType.Billing
        ? //updating billing address based on sugested address

          {
            city: address.city,
            zip: address.zip,
            state: address.state,
          }
        : //updating both addresses based on sugested address
          {
            jobCity: address.city,
            jobZip: address.zip,
            jobState: address.state,
            city: nonValidAddresses[1].suggestedAddress?.city,
            zip: nonValidAddresses[1].suggestedAddress?.postcode,
            state: nonValidAddresses[1].suggestedAddress?.region,
          }),
    };
    handleContactSubmit(newValues);
  };

  const handleContactSubmit = async (values: FormikValues) => {
    /**
     * As the form is submitted:
     *    1. Update the ContactInfo, address details into redux.
     *    2. Also, Invoke hubspot calls for update contact and deal.
     *    3. Invoke Zillow API.
     */
    try {
      setLoader(true);
      const jobAddressState =
        values?.jobState?.region ||
        getRegion(values?.jobState, jobRegions)?.region;
      const billingAddressState =
        values?.state?.region || getRegion(values?.state, regions)?.region;
      const editContactRequest = {
        ...values,
        workOrderID: contact.workOrderID,
        dealId: contact.dealId,
        email: contact.email,
        ...(values.phone && { phone: values.phone + "" }),
        ...(values.homePhone && { homePhone: values.homePhone + "" }),
        ...(values.workPhone && { workPhone: values.workPhone + "" }),
        ...(values.mobilePhone && { mobilePhone: values.mobilePhone + "" }),
        state: values?.sameAddress ? jobAddressState : billingAddressState,
        jobState: jobAddressState,
        ...(values.sameAddress && {
          city: values?.jobCity,
          zip: values?.jobZip,
          address: values?.jobAddress,
        }),
      };
      await updateContactEdit(editContactRequest);
      await updateDeal(contact.dealId, {
        address1: values.jobAddress,
        // address2,
        city: values.jobCity,
        postcode: values.jobZip,
        region: jobAddressState,
      }).finally(() => {
        dispatch(updateProfileData(editContactRequest));
        navigate(fromPage);
      });
      showSnackbarSuccess("Contact Information Updated.");
    } catch (e) {
      console.log(e);
    }
    setLoader(false);
  };
  const getJobAddress = (values: FormikValues) => {
    return {
      postcode: values.jobZip,
      city: values.jobCity,
      region: values.jobState?.display,
      addressLine: values.jobAddress,
    };
  };
  const getBillingAddress = (values: FormikValues) => {
    return {
      postcode: values.zip,
      city: values.city,
      region: values.state.display,
      addressLine: values.address,
    };
  };
  const handleValidateAddress = (values: FormikValues) => {
    setLoader(true);
    const addressValidationObj: AddressValidationFields[] = [
      {
        postcode: values?.jobZip,
        city: values?.jobCity,
        region: values?.jobState?.display,
        country: country,
      },
      {
        postcode: values?.zip,
        city: values?.city,
        region: values?.state?.display,
        country: country,
      },
    ];
    addressValidate(
      values.sameAddress ? [addressValidationObj[0]] : addressValidationObj
    ).subscribe({
      next: (resp) => {
        let validateAddress: AddressValidationModalData[] = [];
        let areAllAddressesValid = true;
        resp.data.forEach((item: ValidateAddressResponse, index: number) => {
          if (item.status !== AddressValidationStatus.Valid) {
            areAllAddressesValid = false;
            validateAddress.push({
              status: item.status,
              suggestedAddress: item?.suggestedAddress,
              reason: item?.reason || "",
              addressEntered:
                index === 0 ? getJobAddress(values) : getBillingAddress(values),
              // @ts-ignore
              flow:
                index === 0
                  ? ContactAddressType.Home
                  : ContactAddressType.Billing,
            });
          }
        });
        setLoader(false);
        //if both the addresses are valid
        if (areAllAddressesValid) {
          handleContactSubmit(values);
        } else {
          setNonValidAddresses(validateAddress);
          setOpen(true);
        }
      },
      error: (err) => {
        setOpen(false);
        setLoader(false);
        extractErrorFromResponseOrShowDefaultMsg(
          err,
          "Unable to Validate Address"
        );
      },
    });
  };

  return (
    <>
      {/* <LoadingOverlay open={contactLoading || loader} /> */}
      <LoadingOverlay open={loader} />
      <Formik
        initialValues={{
          firstname: contact.firstname,
          lastname: contact.lastname,
          email: contact.email,
          phone: contact.phone,
          homePhone: contact.homePhone,
          workPhone: contact.workPhone,
          mobilePhone: contact.mobilePhone,
          address: contact.address,
          city: contact.city,
          state: getInitialState(contact.state),
          zip: contact.zip,
          jobAddress: contact.jobAddress,
          jobCity: contact.jobCity,
          jobState: getInitialJobState(contact.jobState),
          jobZip: contact.jobZip,
          sameAddress: false,
        }}
        onSubmit={(values) => {
          handleValidateAddress(values);
        }}
        validationSchema={EditContactValidationSchema}
        validateOnMount={true}
        enableReinitialize={true}
      >
        {({ submitForm, isValid, values }) => (
          <>
            <Box
              sx={{ pt: "70px !important", px: "45px", pb: "0px !important" }}
            >
              {open && (
                <AddressValidationModal
                  open={open}
                  handleClose={() => {
                    setOpen(false);
                  }}
                  handleSubmit={() => {
                    handleUpdateAddress(values);
                  }}
                  addressData={nonValidAddresses}
                />
              )}
              <Container maxWidth={"lg"} sx={{ mt: "0px" }}>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={12} container spacing={0}>
                    <Grid item xs={8} sm={8}>
                      <Heading label="Edit contact information" />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      display={"flex"}
                      justifyContent={"flex-end"}
                    >
                      <Button
                        largeButton
                        color={"primary"}
                        onClick={submitForm}
                        disabled={!isValid}
                      >
                        UPDATE
                      </Button>
                      <Box
                        sx={{
                          position: "absolute",
                          top: "20px",
                          right: "20px",
                        }}
                      >
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={() => navigate(fromPage)}
                          component="span"
                        >
                          <CloseBlack />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Box>
            <Box
              sx={{
                pb: "10px",
                mt: "20px",
                mx: "25px",
                overflowY: "auto",
                maxHeight: "calc(100vh - 200px)",
              }}
            >
              <Container maxWidth={"lg"}>
                <Box>
                  {section === EDIT_LABEL.PROFILE && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Divider
                            sx={{
                              borderColor: theme.palette.primary.dark,
                              borderBottomWidth: "2px",
                              mt: "20px",
                              mb: "12px",
                            }}
                          />
                          <Paragraph responsiveFontSize20 bold headingColor>
                            Phone Contact
                          </Paragraph>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                          <FormikTextField
                            id="first-name"
                            name="firstname"
                            label="First Name *"
                            type="text"
                            placeholder="Enter First Name"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <FormikTextField
                            id="last-name"
                            name="lastname"
                            label="Last Name *"
                            type="text"
                            placeholder="Enter Last Name"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <FormikTextField
                            id="email"
                            name="email"
                            label="Email Address *"
                            type="email"
                            shouldDisableInputField
                            placeholder="Enter Email"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}></Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <FormikTextField
                            id="home-number"
                            name="homePhone"
                            label="Home"
                            type="number"
                            onKeyDown={blockInvalidChar}
                            placeholder="Enter Home number"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <FormikTextField
                            id="cell-number"
                            name="mobilePhone"
                            label="Cell *"
                            type="number"
                            onKeyDown={blockInvalidChar}
                            placeholder="Enter Cell"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <FormikTextField
                            id="work-number"
                            name="workPhone"
                            label="Work"
                            type="number"
                            onKeyDown={blockInvalidChar}
                            placeholder="Enter Work Number"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <FormikTextField
                            id="phone"
                            name="phone"
                            label="Phone"
                            type="number"
                            onKeyDown={blockInvalidChar}
                            placeholder="Enter Phone"
                          />
                        </Grid>
                      </Grid>
                      <br />
                    </>
                  )}
                  {section === EDIT_LABEL.HOME_ADDRESS && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Divider
                            sx={{
                              borderColor: theme.palette.primary.dark,
                              borderBottomWidth: "2px",
                              my: "12px",
                            }}
                          />
                          <Paragraph responsiveFontSize20 bold headingColor>
                            Job Address
                          </Paragraph>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <FormikTextField
                            id="job-address-line"
                            name="jobAddress"
                            label="Address Line *"
                            type="text"
                            placeholder="Address Line"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <FormikTextField
                            id="job-city"
                            name="jobCity"
                            label="City *"
                            type="text"
                            placeholder="City"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <FormikSelectField
                            id={"job-state"}
                            name={"jobState"}
                            label={"State *"}
                            fullWidth
                            options={jobRegions}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <FormikTextField
                            id="job-zipcode"
                            name="jobZip"
                            label="Zip Code *"
                            type="text"
                            placeholder="Zip Code"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <FormikCheckBox
                            id={"use-shipping-for-billing-checkbox-input"}
                            name={"sameAddress"}
                            label={"Use Job Address for Billing Address"}
                          />
                        </Grid>
                      </Grid>
                      <br />
                    </>
                  )}
                  {/*  Billing Address */}
                  {section === EDIT_LABEL.BILLING_ADDRESS && (
                    <>
                      {!values.sameAddress && (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Divider
                              sx={{
                                borderColor: theme.palette.primary.dark,
                                borderBottomWidth: "2px",
                                my: "12px",
                              }}
                            />
                            <Paragraph responsiveFontSize20 bold headingColor>
                              Billing Address
                            </Paragraph>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <FormikTextField
                              id="billing-address-line"
                              name="address"
                              label="Address Line *"
                              type="text"
                              placeholder="Address Line"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <FormikTextField
                              id="billing-city"
                              name="city"
                              label="City *"
                              type="text"
                              placeholder="City"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <FormikSelectField
                              id={"billing-state"}
                              name={"state"}
                              label={"State *"}
                              fullWidth
                              options={regions}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <FormikTextField
                              id="billing-zipcode"
                              name="zip"
                              label="Zip Code *"
                              type="text"
                              placeholder="Zip Code"
                            />
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}
                </Box>
              </Container>
            </Box>
          </>
        )}
      </Formik>
      <br />
    </>
  );
}
