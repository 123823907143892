import * as React from "react"
import { SVGProps } from "react"
const SelectedAddress = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <circle
      cx={12}
      cy={12}
      r={12}
      style={{
        fill: "#333",
      }}
    />
    <path
      d="m.3-.056 3.111 3.131L9.209-2.2"
      style={{
        fill: "none",
        stroke: "#fff",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "2.5px",
      }}
      transform="translate(7.389 11.668)"
    />
  </svg>
)
export default SelectedAddress
