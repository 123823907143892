export enum LoginMessages {
  MSG_SUCCESS_LOGOUT = "You have logged out successfully",
  MSG_SUCCESS_LOGIN = "You have logged in successfully",
  MSG_FAILURE_LOGIN = "Unable to login",
  OTP_SEND_SUCCESS = "OTP has been sent succesffully",
  OTP_SEND_FAIL = "Unable to send OTP to email",
  MSG_SUCCESS_REGISTER = "Your account is registered successfully",
  MSG_FAILURE_REGISTER = "Unable to register",
  EMAIL_EXISTS_FAIL = "Unable to verify your email",
  ACTIVATION_SEND_FAILURE = "Unable to send Activation code to email",
  ACTIVATION_SEND_SUCCESS = "Activation code has been sent successfully",
  MSG_EMAIL_ALREADY_EXIST = "Email already exists. Please login",
  MSG_EMAIL_NOT_REGISTERED = "Email is not registered. Please sign up",
  MSG_SIGN_UP_VERIFY_FAIL = "Unable to Verify Activation code",
  MSG_SIGN_UP_VERIFY_SUCCESS = "Your account is activated successfully",
}
export const PROMO_CODE_REMOVE = "Unable to remove Promocode";
export const CURRENCY = "USD";
export const NOT_TODAY = "Not Today";
export enum InvalidAmountText {
  LowerLimit = "Minimum down-payment should be 50%.",
  HigherLimit = "The entered amount exceeds the order total",
  InvalidAmt = "Enter a valid amount",
}

export enum DOMEvent {
  EVENT_SHOW_CONTRACTOR_MODAL_AFTER_MANDATORY_BILLING_CAPTURE = "ShowContractorModalAfterBillingCapture",
}

export enum FPL_STRESS_FREE_ROUTE {
  HOME = "https://www.stress-freeac.com/home.html",
  ABOUT = "https://www.stress-freeac.com/home.html#About",
  BENEFITS = "https://www.stress-freeac.com/home.html#Benefits",
  BLOGS = "https://www.stress-freeac.com/blog.html",
  FAQS = "https://www.stress-freeac.com/faqs.html",
  PROMOS = "https://www.stress-freeac.com/promos.html",
  CUSTOMER_LOGIN = "https://community.home-plans.com/customer/s/login/?brand=FPLHS",
  CONTRACTOR_LOGIN = "https://community.home-plans.com/sales/s/login/?brand=FPLHS",
  PHONE = "tel:1-833-970-3579",
  FACEBOOK = "https://www.facebook.com/fplhome/",
  INSTAGRAM = "https://www.instagram.com/fplhome/",
  TWITTER = "https://x.com/i/flow/login?redirect_after_login=%2Ffplhome%2F",
  TERMS = "https://www.nexteraenergy.com/legal-notice.html",
  PRIVACY_POLICY = "https://www.nexteraenergy.com/privacy-policy.html",
}

export enum EDIT_LABEL { 
  PROFILE = 'Profile',
  HOME_ADDRESS = 'Home',
  BILLING_ADDRESS = 'Billing'
}

export const SCHEDULE_TOOLTIP =
  "Select “Schedule Inspection” to choose the inspection date/times that work best for you. This visit allows the contractor an opportunity to verify that the selected HVAC product will meet your needs and determine what materials will be needed to complete the installation. The inspection is free of charge.";

export const jobRegions = [
      {
        id: 1,
        region: "AZ",
        display: "Arizona",
      },
      {
        id: 2,
        region: "AR",
        display: "Arkansas",
      },
      {
        id: 3,
        region: "FL",
        display: "Florida",
      },
      {
        id: 4,
        region: "GA",
        display: "Georgia",
      },
      {
        id: 5,
        region: "SC",
        display: "South Carolina",
      },
      {
        id: 6,
        region: "NC",
        display: "North Carolina",
      },
      {
        id: 7,
        region: "TX",
        display: "Texas",
      }
]
export const regions = [
  {
    id: 1,
    region: "AL",
    display: "Alabama",
  },
  {
    id: 2,
    region: "AK",
    display: "Alaska",
  },
  {
    id: 3,
    region: "AS",
    display: "American Samoa",
  },
  {
    id: 4,
    region: "AZ",
    display: "Arizona",
  },
  {
    id: 5,
    region: "AR",
    display: "Arkansas",
  },
  {
    id: 6,
    region: "AE",
    display: "Armed Forces Africa",
  },
  {
    id: 7,
    region: "AA",
    display: "Armed Forces Americas",
  },
  {
    id: 8,
    region: "AE",
    display: "Armed Forces Canada",
  },
  {
    id: 9,
    region: "AE",
    display: "Armed Forces Europe",
  },
  {
    id: 10,
    region: "AE",
    display: "Armed Forces Middle East",
  },
  {
    id: 11,
    region: "AP",
    display: "Armed Forces Pacific",
  },
  {
    id: 12,
    region: "CA",
    display: "California",
  },
  {
    id: 13,
    region: "CO",
    display: "Colorado",
  },
  {
    id: 14,
    region: "CT",
    display: "Connecticut",
  },
  {
    id: 15,
    region: "DE",
    display: "Delaware",
  },
  {
    id: 16,
    region: "DC",
    display: "District of Columbia",
  },
  {
    id: 17,
    region: "FM",
    display: "Federated States Of Micronesia",
  },
  {
    id: 18,
    region: "FL",
    display: "Florida",
  },
  {
    id: 19,
    region: "GA",
    display: "Georgia",
  },
  {
    id: 20,
    region: "GU",
    display: "Guam",
  },
  {
    id: 21,
    region: "HI",
    display: "Hawaii",
  },
  {
    id: 22,
    region: "ID",
    display: "Idaho",
  },
  {
    id: 23,
    region: "IL",
    display: "Illinois",
  },
  {
    id: 24,
    region: "IN",
    display: "Indiana",
  },
  {
    id: 25,
    region: "IA",
    display: "Iowa",
  },
  {
    id: 26,
    region: "KS",
    display: "Kansas",
  },
  {
    id: 27,
    region: "KY",
    display: "Kentucky",
  },
  {
    id: 28,
    region: "LA",
    display: "Louisiana",
  },
  {
    id: 29,
    region: "ME",
    display: "Maine",
  },
  {
    id: 30,
    region: "MH",
    display: "Marshall Islands",
  },
  {
    id: 31,
    region: "MD",
    display: "Maryland",
  },
  {
    id: 32,
    region: "MA",
    display: "Massachusetts",
  },
  {
    id: 33,
    region: "MI",
    display: "Michigan",
  },
  {
    id: 34,
    region: "MN",
    display: "Minnesota",
  },
  {
    id: 35,
    region: "MS",
    display: "Mississippi",
  },
  {
    id: 36,
    region: "MO",
    display: "Missouri",
  },
  {
    id: 37,
    region: "MT",
    display: "Montana",
  },
  {
    id: 38,
    region: "NE",
    display: "Nebraska",
  },
  {
    id: 39,
    region: "NV",
    display: "Nevada",
  },
  {
    id: 40,
    region: "NH",
    display: "New Hampshire",
  },
  {
    id: 41,
    region: "NJ",
    display: "New Jersey",
  },
  {
    id: 42,
    region: "NM",
    display: "New Mexico",
  },
  {
    id: 43,
    region: "NY",
    display: "New York",
  },
  {
    id: 44,
    region: "NC",
    display: "North Carolina",
  },
  {
    id: 45,
    region: "ND",
    display: "North Dakota",
  },
  {
    id: 46,
    region: "MP",
    display: "Northern Mariana Islands",
  },
  {
    id: 47,
    region: "OH",
    display: "Ohio",
  },
  {
    id: 48,
    region: "OK",
    display: "Oklahoma",
  },
  {
    id: 49,
    region: "OR",
    display: "Oregon",
  },
  {
    id: 50,
    region: "PW",
    display: "Palau",
  },
  {
    id: 51,
    region: "PA",
    display: "Pennsylvania",
  },
  {
    id: 52,
    region: "PR",
    display: "Puerto Rico",
  },
  {
    id: 53,
    region: "RI",
    display: "Rhode Island",
  },
  {
    id: 54,
    region: "SC",
    display: "South Carolina",
  },
  {
    id: 55,
    region: "SD",
    display: "South Dakota",
  },
  {
    id: 56,
    region: "TN",
    display: "Tennessee",
  },
  {
    id: 57,
    region: "TX",
    display: "Texas",
  },
  {
    id: 58,
    region: "UT",
    display: "Utah",
  },
  {
    id: 59,
    region: "VT",
    display: "Vermont",
  },
  {
    id: 60,
    region: "VI",
    display: "Virgin Islands",
  },
  {
    id: 61,
    region: "VA",
    display: "Virginia",
  },
  {
    id: 62,
    region: "WA",
    display: "Washington",
  },
  {
    id: 63,
    region: "WV",
    display: "West Virginia",
  },
  {
    id: 64,
    region: "WI",
    display: "Wisconsin",
  },
  {
    id: 65,
    region: "WY",
    display: "Wyoming",
  },
];

