import { Address } from  "../../models/cart";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
    loading: false,
    showChooseAddressPopup: false,
    showEditAddressPopup: false,
    addressList: [],
    addressToEdit: null,
};

const addressSlice = createSlice({
  name: "addressSlice",
  initialState: initialState,
  reducers: {
    getAddressListAction: (state, action:PayloadAction<boolean>) => {
      return {
        ...state,
        addressList: action.payload ? [] : [...state.addressList],
        loading: true,
      };
    },
    getAddressListSuccess: (state, action:PayloadAction<Address[]>) => {
      return {
        ...state,
        addressList: action.payload,
        loading: false,
      };
    },
    getAddressListFailure: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    resetData: (state, action) => {
        return {
            ...initialState,
        };
      },
      showChooseAddressPopupAction: (state) => {
        return {
            ...state,
            showChooseAddressPopup: true,
        };
      },
      hideChooseAddressPopupAction: (state, action) => {
        return {
            ...state,
            showChooseAddressPopup: false,
        };
      },
      setAddressToEdit: (state, action:PayloadAction<{address?: Address}>) => {
        return {
            ...state,
            addressToEdit: action.payload.address!,
        };
      },
      hideEditAddressPopupAction: (state, action) => {
        return {
            ...state,
            showEditAddressPopup: false,
            addressToEdit: null,
        };
      },
      showEditAddressPopupAction: (state) => {
        return {
            ...state,
            showEditAddressPopup: true,
            showChooseAddressPopup: false,
        };
      },
  },
});

export default addressSlice.reducer;
export const {
    resetData,
    getAddressListAction,
    getAddressListFailure,
    getAddressListSuccess,
    hideChooseAddressPopupAction,
    hideEditAddressPopupAction,
    setAddressToEdit,
    showChooseAddressPopupAction,
    showEditAddressPopupAction,
} = addressSlice.actions
