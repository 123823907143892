import { Category, RelatedProduct } from './product';
import { NumberOfHVAC, ProductCriteria } from './productCriteria';
export enum SystemGroup {
    SystemGroup1 = '1_SYSTEM_GROUP',
    SystemGroup2 = '2_SYSTEM_GROUP',
    AddOn = 'ADD_ON',
}
export enum ItemType {
    MainSystem = '1_MAIN_SYSTEM',
    CrossSell = '2_CROSS_SELL',
    AddOn = 'ADD_ON',
}
export enum AddressType {
    Home = 'Home',
    Billing = 'Billing',
    Properties = 'Properties',
}
export interface Cart {
    cartId?: string;
    email?: string;
    items?: CartItem[];
    billingAddress?: Address;
    shippingAddress?: Address;
    prices?: CartPrice;
    contactInformation?: ContactInformation;
    paymentMethod?: PaymentMethod;
    loading?: boolean;
    error?: any;
    schedules?: Schedule[];
    status?: CartStatus;
    appliedCoupons?: AppliedCoupon[];
    isErrorInCoupon?: string;
    couponApplyError?: string;
    shouldCheckForAddOnCompatibility?: boolean; //This will be set to true on cart item delete/update.
    instructions?: string;
    orderId?: string;
}

export interface ContactInformation {
    firstname?: string;
    lastname?: string;
    email?: string;
    phone?: string;
    quotedOptions?: object;
    quotedOptionsB?: object;
    selectedPackage?: string;
    paymentMethod?: string;
    remark?: string;
    wizardSelectedOptions?: WizardSelectedOptions;
    searchCriteria?: SearchCriteria;
}

export interface SearchCriteria extends ProductCriteria {
    firstReplacement?: ProductCriteria;
    secondReplacement?: ProductCriteria;
}
export interface WizardSelectedOptions {
    numberOfSystems: NumberOfHVAC;
}

export interface CartItem {
    categories: Category[];
    name: string;
    sku: string;
    quantity: number;
    id: number;
    description: string;
    image: string;
    price: number;
    thumbnail: string;
    totalPrice: number;
    productAttributes?: any;
    itemType?: ItemType;
    relatedProducts: RelatedProduct[];
    systemGroup?: SystemGroup;
}
export interface CartPayload {
    cartId?: string;
    email?: string;
    items?: ItemPayload[];
    billingAddress?: Address;
    shippingAddress?: Address;
    prices?: CartPrice;
    contactInformation?: ContactInformation;
    paymentMethod?: PaymentMethod;
    loading?: boolean;
    error?: any;
    schedules?: Schedule[];
    status?: CartStatus;
    appliedCoupons?: AppliedCoupon[];
    isErrorInCoupon?: string;
    shouldCheckForAddOnCompatibility?: boolean; //This will be set to true on cart item delete/update.
}
export interface ItemPayload {
    id?: number;
    sku: string;
    quantity: number;
    itemType?: ItemType;
    systemGroup?: SystemGroup;
}
export interface CartPrice {
    appliedTaxes: AppliedTaxes[];
    subtotalExcludingTax: number;
    subtotalIncludingTax: number;
    grandTotal: number;
    subtotalDiscountExcludingTax: number;
    discounts: Discount[];
    currentGrandTotal?: number;
}

export interface Address {
    id?: string;
    customerAddressId?: string;
    city?: string;
    company?: string;
    country?: string;
    firstname?: string;
    lastname?: string;
    postcode?: string;
    region?: string;
    address1?: string;
    address2?: string;
    telephone?: string;
    email?: string;
    addressName?: string;
    defaultShipping?: boolean;
    defaultBilling?: boolean;
    saveInAddressBook?: boolean;
    defaultBillingFirstname?: string;
    defaultBillingLastname?: string;
    duplicateAddressCheck?: boolean;
}

export interface ShippingMethod {
    amount: number;
    baseAmount: number;
    carrierCode: string;
    carrierTitle: string;
    methodCode: string;
    methodTitle: string;
    id: number;
}
export interface AppliedCoupon {
    code: string;
}
export interface Discount {
    amount: number;
    label: string;
}
export interface PaymentMethod {
    code: string;
    title?: string;
    purchaseOrderNumber?: string;
    additionalData?: Object;
    id?: number;
    method?: string;
    amount?: string;
}

export interface Schedule {
    preferredTimeSlot: string;
    schedule: string;
    preferredDate: Date | string;
}

export interface ScheduleApiRequest {
    schedules: Schedule[];
    notes?: string;
    searchCriteria?: object;
}

export enum CartStatus {
    READY_TO_SCHEDULE = 'READY_TO_SCHEDULE',
    PENDING_INSPECTION = 'PENDING_INSPECTION',
    READY_FOR_PAYMENT = 'READY_FOR_PAYMENT',
    EQ_READY_FOR_PAYMENT = 'EQ_READY_FOR_PAYMENT',
}

export interface AppliedTaxes {
    amount: number;
    label: string;
}

export interface AddCartItemActionType {
    cartItems: CartItem[];
    takeNumberOfSystemsFromWizard?: boolean;
    shouldBlockSaveForLaterApi?: boolean;
    shouldShowSnackbarOnSuccess?: boolean;
}
export interface CartItems {
    firstSystemMain?: CartItem;
    secondSystemMain?: CartItem;
    firstSystemIAQ?: CartItem;
    secondSystemIAQ?: CartItem;
    addOnsList?: CartItem[];
}

export interface SelectedPackage {
    email: string;
    cart: { items: SelectedItem[] };
}
export interface SelectedItem {
    name: string;
    systemGroup: any;
    itemType: any;
    price: number;
    quantity: number;
    productAttributes: {
        level: string;
    };
}

export interface SchedulePayload {
    email: string;
    imeWorkOrderId?: string;
    dealId: string;
    searchCriteria: object;
    items: ItemPayload[];
    prices: any;
    schedules: Schedule[];
}
export interface generateQuotePayload {
    email: string;
    imeWorkOrderId?: string;
    dealId: string;
    searchCriteria: object;
    items: ItemPayload[];
    prices: any;
}
export interface ScheduleResp extends SchedulePayload {
    ticketId: string;
    motiliWorkOrderNumber: string;
}

export interface SendQuoteApiPayload {
    email: string;
    imeWorkOrderId?: string;
    searchCriteria: object;
    items: ItemPayload[];
    prices: any;
}