import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import { FormHelperText } from '@mui/material';

export default function SelectInput(props: Prop) {
    const classes = createCssObject(useTheme(), props);
    const {
        id,
        label,
        value,
        options,
        errorIcon,
        errorMsg,
        error,
        helperText,
        enableErrorDisplay,
        onSelect,
        fullWidth,
        ...other
    } = props;
    const handleChange = (event: SelectChangeEvent<{ value: unknown }>) => {
        if (options?.length > 0) {
            const selectedItem = options.find((item: any) => item.id === event.target.value);
            if (selectedItem) {
                onSelect(selectedItem);
            }
        }
    };
    return (
        <FormControl variant={'outlined'} sx={classes.formControl} fullWidth={fullWidth} error={enableErrorDisplay}>
            {label && (
                <FormLabel id={`${id}-select-filled-label`} sx={classes.label}>
                    {label}
                </FormLabel>
            )}
            <Select
                id={`${id}-select-filled`}
                value={value?.id || ''}
                fullWidth={fullWidth}
                onChange={handleChange}
                sx={classes.select}
                displayEmpty
                renderValue={value !== '' ? undefined : () => 'Select One'}
                MenuProps={{
                    sx: classes.menu,
                    id: `${id}-select-menu`,
                    disableScrollLock: true,
                    MenuListProps: {
                        id: `${id}-select-list`,
                        sx: classes.menuItem,
                    },
                }}
                {...other}
            >
                {options?.length > 0 &&
                    options.map((item: any, index: number) => {
                        return (
                            <MenuItem key={index} value={item.id}>
                                {item.display}
                            </MenuItem>
                        );
                    })}
            </Select>
            {enableErrorDisplay && (
                <FormHelperText id={`${id}-helper`} sx={classes.helperText}>
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
}

const createCssObject = (theme: Theme, props: Prop) => ({
    formControl: {
        minWidth: 100,
    },
    errorIcon: {
        position: 'absolute',
        top: '80px',
        mx: '0px',
        lineHeight: '14px',
    },
    label: {
        marginBottom: theme.spacing(1),
        color: theme.palette.text.primary,
        '&.Mui-focused': {
            color: theme.palette.text.primary,
        },
        '&.Mui-error': {
            color: theme.palette.error.main,
        },
    },
    select: {
        borderRadius: '4px',
        backgroundColor: theme.palette.text.secondary,
        color: theme.typography.h2.color,
        height: '48px',
        '&.MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: theme.palette.text.primary,
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.divider}`,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.text.primary,
                borderWidth: '1px',
            },
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: 'inherit',
        },
    },
    menu: {
        zIndex: 10,
        '& .MuiPaper-rounded': {
            borderRadius: '1px',
        },
    },
    menuItem: {
        '& .MuiMenuItem-root': {
            color: theme.typography.h2.color,
            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
                color: theme.typography.h2.color,
                backgroundColor: theme.palette.divider,
            },
        },
    },
    helperText: {
        color: theme.palette.text.primary,
    },
});

export interface Prop {
    id: any;
    options?: any;
    value?: any;
    label?: string;
    onSelect: (item: any) => void;
    error?: boolean;
    enableErrorDisplay?: boolean;
    helperText?: string;
    fullWidth?: boolean;
    disabled?: boolean;
    errorIcon?: JSX.Element;
    errorMsg?: string;
    zIndex?: number;
}
