import { ProductAttribute, ProductAttributeId } from  "../../models/productAttributes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clearData } from "./clearData";

export type ProductAttributesState = {
    [id in ProductAttributeId]?: ProductAttribute;
} & {
    loading: boolean;
};

const initialState: ProductAttributesState = {
    loading: false,
};

const productAttributesSlice = createSlice({
  name: "productAttributesSlice",
  initialState: initialState,
  reducers: {
    getProductAttribute: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getProductAttributeSuccess: (state, action:PayloadAction<{ id: ProductAttributeId; value: ProductAttribute }>) => {
      return {
        ...state,
        [action.payload.id]: action.payload.value,
        loading: false,
      };
    },
    getProductAttributeError: (state, action:PayloadAction<Error>) => {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearData, (state, action) => {
      return initialState;
    });
  },
});

export default productAttributesSlice.reducer;
export const {
    getProductAttribute,
    getProductAttributeSuccess,
    getProductAttributeError,
} = productAttributesSlice.actions
