import React from "react";
import ContentContainer from "../../components/ContentContainer";
import Grid from "@mui/material/Grid";
import { Theme, useTheme } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import { Paragraph } from "../../components/Paragraph";
import { Options } from "../../components/WizardInputs";
import { FormikProps, withFormik } from "formik";
import { Box, Card, CardContent } from "@mui/material";
import Container from "@mui/material/Container";
import { schemaSchedule } from "../wizard/wizard-schema";
import FormikDateFieldSchedulePage from "../../components/FormikDateFieldSchedulePage";
import { Schedule } from "../../models/cart";
import { Button } from "../../components/Button";
import { SXCssObject } from "../../models/ThemeImages";
import WizardHeader from "../../components/WizardHeader";
import WizardSelectionSchedule from "../../components/WizardSelectionSchedule";
import { format } from "date-fns";
import Footer from "../../components/common/Footer";
import { Heading } from "../../components/Heading";
import LoadingOverlay from "../../components/common/LoadingOverlay";
import { useSelector } from "react-redux";
import { selectCartLoading } from "../../store/selectors/cartSelector";

const createCssObject = (theme: Theme): SXCssObject => ({
  root: {
    position: "relative",
  },
  contentRoot: {
    pointerEvents: "auto",
    opacity: 1,
  },
  header: {
    py: {
      xs: theme.spacing(3),
      sm: theme.spacing(4),
      md: theme.spacing(5),
    },
  },
  subHeadingStyle: {
    mt: {
      xs: "20px",
    },
  },
  divider: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  gridContainerRow: {
    mt: "0px",
  },
  card: {
    mb: "20px",
    boxShadow: "none",
    borderRadius:'4px'
  },
  dividerRoot: {
    height: "1px",
    borderTop: `1px solid ${theme.customProperties.borderDivider}`,
    borderBottom: "0px",
    mb: {
      xs: "20px",
      md: "32px",
    },
    mt: {
      xs: "5px",
      md: "8px",
    },
  },
  whiteBg: {
    backgroundColor: theme.palette.secondary.dark,
    padding: {
      xs: "15px 5px 15px",
      md: "15px 0px",
    },
  },
  labelStyle: {
    paddingBottom: "10px",
    fontSize: "0.875rem",
    color: theme.palette.text.primary,
  },

  nextBtnStyle: {
    width: "100%",
    display: "inline-block",
    flex: {
      xs: "2",
      md: "3",
    },
  },
  previousBtnStyle: {
    width: "100%",
    display: "inline-block",
    flex: {
      sm: "2",
    },
  },
  contentContainer: {
    minHeight: `calc(100vh - ${theme.spacing(15)})`,
  },
});

interface Props {
  schedule: Schedule[];
  onReview: (scheduleList: Schedule[]) => void;
  onNext: (scheduleList: Schedule[]) => void;
  onPrev: () => void;
  minDate: Date;
  maxDate: Date;
  isDisableBtn: Boolean;
}
// const errorSnackbarKey = 'Schedule-sameslot-error';
// const snackbarParams = { timeout: null, preventDuplicate: true, key: errorSnackbarKey };
function ScheduleHomeInspection(props: Props & FormikProps<FormValues>) {
  const cssObject: SXCssObject = createCssObject(useTheme());
  const { minDate, maxDate, values, isDisableBtn } = props;
  const loading = useSelector(selectCartLoading);
  // const confirmOptions: Options[] = [{ id: 'confirm-yes', value: 'yes', label: 'YES' }];

  React.useEffect(() => {
    const inputList = document.querySelectorAll(
      ".MuiOutlinedInput-input"
    ) as any;
    for (let i = 0; i < inputList.length; i++) {
      inputList[i].disabled = true;
    }
  }, []);

  const timeSlotOptions: Options[] = [
    { id: "time-slot-1", value: "08:00AM-12:00PM", label: "08:00 AM-12:00 PM" },
    { id: "time-slot-2", value: "10:00AM-02:00PM", label: "10:00 AM-02:00 PM" },
    { id: "time-slot-3", value: "01:00PM-05:00PM", label: "01:00 PM-05:00 PM" },
  ];

  const shouldDisableDate = (date: Date) => {
    return date.getDay() === 0;
  };
  const handleScheduleSubmit = (isReview?: boolean) => {
    const apiData: Schedule[] = values.schedule.map((item, key) => {
      return {
        preferredTimeSlot: item.preferredTimeSlot.replace(/\s/g, ""),
        schedule: `schedule${key + 1}`,
        preferredDate: format(
          new Date(item.preferredDate as Date),
          "MM-dd-yyyy"
        ),
      };
    });
    isReview ? props.onReview(apiData) : props.onNext(apiData);
  };
  return (
    <ContentContainer disableGrid background={"bg2"} sx={cssObject.root}>
      <LoadingOverlay open={loading || false} />
      <Box sx={[cssObject.contentContainer, cssObject.contentRoot]}>
        <WizardHeader shadow={false} showRestartButton={true} isFixed={true} />
        <ContentContainer disableGrid>
          <Container maxWidth={"lg"} sx={{ pb: "40px", pt: {
            xs:'100px',
            md:"140px"
          } }}>
            <Grid container>
              <Grid item xs={12} sm={7}>
                <Heading
                  label={
                    "Schedule Times for Their In-Home HVAC Pre-Installation Inspection"
                  }
                />
                <Paragraph sx={{py:2}}>
                Let’s select the ideal dates for me to get our contractor out to your home​
                </Paragraph>
              </Grid>
            </Grid>
            <Card sx={cssObject.card}>
              <CardContent sx={{ p: "25px" }}>
                <Paragraph largeFont semibold headingColor>
                  Schedule 01
                </Paragraph>
                <Grid spacing={2} container sx={cssObject.gridContainerRow}>
                  <Grid item xs={12} md={4}>
                    <FormikDateFieldSchedulePage
                      name={"schedule[0].preferredDate"}
                      id="sch1"
                      label={"Preferred Date"}
                      fullWidth={true}
                      maxDate={maxDate}
                      minDate={minDate}
                      shouldDisableDate={(date: Date) =>
                        shouldDisableDate(date)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <FormControl style={{ width: "100%" }}>
                      <ContentContainer disableGrid>
                        <Grid container>
                          <WizardSelectionSchedule
                            id={"schedule-day-1"}
                            name="schedule[0].preferredTimeSlot"
                            label={"Preferred Time"}
                            options={timeSlotOptions}
                          />
                        </Grid>
                      </ContentContainer>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card sx={cssObject.card}>
              <CardContent sx={{ p: "25px" }}>
                <Paragraph largeFont semibold headingColor>
                  Schedule 02
                </Paragraph>
                <Grid spacing={2} container sx={cssObject.gridContainerRow}>
                  <Grid item xs={12} md={4}>
                    <FormikDateFieldSchedulePage
                      name={"schedule[1].preferredDate"}
                      id="sch2"
                      label={"Preferred Date"}
                      fullWidth={true}
                      maxDate={maxDate}
                      minDate={minDate}
                      shouldDisableDate={(date: Date) =>
                        shouldDisableDate(date)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <FormControl style={{ width: "100%" }}>
                      <ContentContainer disableGrid>
                        <Grid container>
                          <WizardSelectionSchedule
                            id={"schedule-day-2"}
                            name="schedule[1].preferredTimeSlot"
                            label={"Preferred Time"}
                            options={timeSlotOptions}
                          />
                        </Grid>
                      </ContentContainer>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card sx={cssObject.card}>
              <CardContent sx={{ p: "25px" }}>
                <Paragraph largeFont semibold headingColor>
                  Schedule 03
                </Paragraph>
                <Grid spacing={2} container sx={cssObject.gridContainerRow}>
                  <Grid item xs={12} md={4}>
                    <FormikDateFieldSchedulePage
                      name={"schedule[2].preferredDate"}
                      id="sch3"
                      label={"Preferred Date"}
                      fullWidth={true}
                      maxDate={maxDate}
                      minDate={minDate}
                      shouldDisableDate={(date: Date) =>
                        shouldDisableDate(date)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <FormControl style={{ width: "100%" }}>
                      <ContentContainer disableGrid>
                        <Grid container>
                          <WizardSelectionSchedule
                            id={"schedule-day-3"}
                            name="schedule[2].preferredTimeSlot"
                            label={"Preferred Time"}
                            options={timeSlotOptions}
                          />
                        </Grid>
                      </ContentContainer>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Container>
        </ContentContainer>
      </Box>

      <Box
        style={{
          // position: 'fixed',
          // bottom: '0',
          width: "100%",
          // border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <ContentContainer disableGrid sx={cssObject.whiteBg}>
          <Container maxWidth={"lg"}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={10}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={4}
                    sx={cssObject.previousBtnStyle}
                  >
                    <Button
                      id={`schedule-back-btn`}
                      isFullWidth={true}
                      outlinedWithBorder
                      onClick={props.onPrev}
                      disabled={isDisableBtn}
                    >
                      Previous
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={5} md={4} sx={cssObject.nextBtnStyle}>
                    <Button
                      isFullWidth={true}
                      id={`schedule-next-btn`}
                      color={"primary"}
                      disabled={!props.isValid || isDisableBtn}
                      onClick={() => handleScheduleSubmit()}
                    >
                      SKIP REVIEW AND CONTINUE
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} sx={cssObject.nextBtnStyle}>
                    <Button
                      isFullWidth={true}
                      id={`schedule-next-btn`}
                      outlinedWithBorder
                      disabled={!props.isValid || isDisableBtn}
                      onClick={() => handleScheduleSubmit(true)}
                    >
                      CONTINUE FOR REVIEW
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </ContentContainer>
      </Box>
      <Footer />
    </ContentContainer>
  );
}

interface FormValues {
  schedule: Schedule[];
}

const scheduleComponent = withFormik<Props, FormValues>({
  mapPropsToValues: ({ schedule }) => ({
    schedule: schedule.map((item, key) => {
      return {
        preferredTimeSlot: item.preferredTimeSlot,
        schedule: `schedule${key}`,
        preferredDate: new Date(item.preferredDate),
      };
    }),
  }),

  handleSubmit: () => {},
  validateOnMount: true,
  validationSchema: schemaSchedule,

  validate: (values) => {
    const apiData: Schedule[] = values.schedule.map((item, key) => {
      return {
        preferredTimeSlot: item.preferredTimeSlot.replace(/\s/g, ""),
        schedule: `schedule${key + 1}`,
        preferredDate: format(
          new Date(item.preferredDate as Date),
          "MM-dd-yyyy"
        ),
      };
    });
    if (
      apiData[0].preferredDate !== "" &&
      apiData[1].preferredDate !== "" &&
      apiData[2].preferredDate !== "" &&
      apiData[0].preferredTimeSlot !== "" &&
      apiData[1].preferredTimeSlot !== "" &&
      apiData[2].preferredTimeSlot !== ""
    ) {
      if (
        apiData[0].preferredDate === apiData[1].preferredDate &&
        apiData[0].preferredTimeSlot === apiData[1].preferredTimeSlot
      ) {
        // showSnackbarError('Date and Time Slots for Schedule 1 and Schedule 2 are the same.', snackbarParams);
        return {
          schedule: [
            {},
            {
              preferredDate:
                "Date and Time Slots for Schedule 1 and Schedule 2 are the same.",
            },
          ],
        };
      } else if (
        apiData[1].preferredDate === apiData[2].preferredDate &&
        apiData[1].preferredTimeSlot === apiData[2].preferredTimeSlot
      ) {
        // showSnackbarError('Date and Time Slots for Schedule 2 and Schedule 3 are the same.', snackbarParams);
        return {
          schedule: [
            {},
            {
              preferredDate:
                "Date and Time Slots for Schedule 2 and Schedule 3 are the same.",
            },
          ],
        };
      } else if (
        apiData[0].preferredDate === apiData[2].preferredDate &&
        apiData[0].preferredTimeSlot === apiData[2].preferredTimeSlot
      ) {
        // showSnackbarError('Date and Time Slots for Schedule 1 and Schedule 3 are the same.', snackbarParams);
        return {
          schedule: [
            {},
            {
              preferredDate:
                "Date and Time Slots for Schedule 1 and Schedule 3 are the same.",
            },
          ],
        };
      }
    } else {
      // closeSnack(errorSnackbarKey);
    }
  },
})(ScheduleHomeInspection);

export default scheduleComponent;
