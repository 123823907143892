import * as Yup from 'yup';
import { StringSchema } from 'yup';
import {
    SquareFootage,
    SplitOrPackage,
    NumberOfHVAC,
    HomeSystem,
    SplitReplacementType,
    PackageReplacementType,
    FurnaceEfficiency,
    PartialSystemType,
    DuctlessNumberOfRooms,
    DuctlessFirstSquareFootage,
    DuctlessSecondSquareFootage,
} from '../../models/productCriteria';

export const bidBackSchema = Yup.object({
    isThisGoodTimeToTalk:  Yup.string().required('Please confirm your good time to proceed'),
    soundslikeaplan: Yup.string().when(['isThisGoodTimeToTalk'], {
        is: (isThisGoodTimeToTalk: string) => isThisGoodTimeToTalk === 'YES',
        then: (schema: StringSchema) => Yup.string().required('Please confirm your good time to proceed'),
    })
})

export const schemaAboutYouHome = Yup.object({
    numberOfHVAC: Yup.string().required('Hvac system is required to continue').oneOf(Object.values(NumberOfHVAC)),
    homeSystem: Yup.string().when('numberOfHVAC', {
        is: (numberOfHVAC: string) => {
            return (numberOfHVAC === NumberOfHVAC.Single || numberOfHVAC === NumberOfHVAC.Double)
        },
        then: (schema: StringSchema) => schema.required('Select a Complete Or Partial Or Ductless before continuing').oneOf(Object.values(HomeSystem))
    }
    ),
    //3
    splitOrPackage: Yup.string().when(
        ['homeSystem', 'numberOfHVAC'], {
        is: (homeSystem: string, numberOfHVAC: string) => {
            return (homeSystem === HomeSystem.Complete &&
                (numberOfHVAC === NumberOfHVAC.Single || numberOfHVAC === NumberOfHVAC.Double)
            )
        },
        then: (schema: StringSchema) => schema.required('Select a Split or Package before continuing').oneOf(Object.values(SplitOrPackage))
    }
    ),
    //3.1
    splitReplacementType: Yup.string().when(
        ['splitOrPackage', 'homeSystem', 'numberOfHVAC'], {
        is: (splitOrPackage: string, homeSystem: string, numberOfHVAC: string) => {
            return (
                splitOrPackage === SplitOrPackage.Split &&
                homeSystem === HomeSystem.Complete &&
                (numberOfHVAC === NumberOfHVAC.Single || numberOfHVAC === NumberOfHVAC.Double)
            )
        },
        then: (schema: StringSchema) => schema.required('Select a SplitReplacementType before continuing').oneOf(Object.values(SplitReplacementType))
    }

    ),
    //3.1
    packageReplacementType: Yup.string().when(
        ['splitOrPackage', 'homeSystem', 'numberOfHVAC'],
        {
            is: (splitOrPackage: string, homeSystem: string, numberOfHVAC: string) => {
                return (
                    splitOrPackage === SplitOrPackage.Package &&
                    homeSystem === HomeSystem.Complete &&
                    (numberOfHVAC === NumberOfHVAC.Single || numberOfHVAC === NumberOfHVAC.Double)
                )
            },
            then: (schema: StringSchema) => schema
                .required('Select a PackageTypeReplacement before continuing')
                .oneOf(Object.values(PackageReplacementType))
        },
    ),
    //3
    partialSystemType: Yup.string().when(
        ['homeSystem', 'numberOfHVAC'], {
        is: (homeSystem: string, numberOfHVAC: string) => {
            return (
                homeSystem === HomeSystem.Partial &&
                (numberOfHVAC === NumberOfHVAC.Single || numberOfHVAC === NumberOfHVAC.Double)
            )
        },
        then: (schema: StringSchema) => schema
            .required('Select a partialSystemType before continuing')
            .oneOf(Object.values(PartialSystemType))
    }
    ),
    efficiency: Yup.string().when(
        ['splitReplacementType', 'partialSystemType', 'numberOfHVAC', 'homeSystem'], {
        is: (
            splitReplacementType: string,
            partialSystemType: string,
            numberOfHVAC: string,
            homeSystem: string,
        ) => {
            return (
                (splitReplacementType === SplitReplacementType.FurnaceAC ||
                    partialSystemType === PartialSystemType.Furnace) &&
                (numberOfHVAC === NumberOfHVAC.Single || numberOfHVAC === NumberOfHVAC.Double) &&
                (homeSystem === HomeSystem.Partial || homeSystem === HomeSystem.Complete)
            )
        },
        then: (schema: StringSchema) => schema.required('You must choose either 80% or 90%').oneOf(Object.values(FurnaceEfficiency))
    }

    ),
    squareFootage: Yup.string().when(
        ['homeSystem', 'numberOfHVAC'], {
        is: (homeSystem: string, numberOfHVAC: string) => {
            return (
                (homeSystem === HomeSystem.Complete || homeSystem === HomeSystem.Partial) &&
                (numberOfHVAC === NumberOfHVAC.Single || numberOfHVAC === NumberOfHVAC.Double)
            )
        },
        then: (schema: StringSchema) => schema.required('Select a SquareFootage before continuing').oneOf(Object.values(SquareFootage))
    }
    ),
    ductlessNumberOfRooms: Yup.string().when(['homeSystem', 'numberOfHVAC'], {
        is: (homeSystem: string, numberOfHVAC: string) => {
            return (
                homeSystem === HomeSystem.Ductless &&
                (numberOfHVAC === NumberOfHVAC.Single || numberOfHVAC === NumberOfHVAC.Double)
            );
        },
        then: (schema: StringSchema) =>
            schema.required('Select a Ductless Rooms before continuing').oneOf(Object.values(DuctlessNumberOfRooms)),
    }),

    ductlessFirstSquareFootage: Yup.string().when(['ductlessNumberOfRooms', 'homeSystem', 'numberOfHVAC'], {
        is: (ductlessNumberOfRooms: string, homeSystem: string, numberOfHVAC: string, schema: StringSchema) => {
            return (
                (ductlessNumberOfRooms === DuctlessNumberOfRooms.Single ||
                    ductlessNumberOfRooms === DuctlessNumberOfRooms.Double) &&
                homeSystem === HomeSystem.Ductless &&
                (numberOfHVAC === NumberOfHVAC.Single || numberOfHVAC === NumberOfHVAC.Double)
            );
        },
        then: (schema: StringSchema) =>
            schema
                .required('You must choose DuctlessFirstSquareFootage of Ductless Rooms')
                .oneOf(Object.values(DuctlessFirstSquareFootage)),
    }),
    ductlessSecondSquareFootage: Yup.string().when(['ductlessNumberOfRooms', 'homeSystem', 'numberOfHVAC'], {
        is: (ductlessNumberOfRooms: string, homeSystem: string, numberOfHVAC: string, schema: StringSchema) => {
            return (
                ductlessNumberOfRooms === DuctlessNumberOfRooms.Double &&
                homeSystem === HomeSystem.Ductless &&
                (numberOfHVAC === NumberOfHVAC.Single || numberOfHVAC === NumberOfHVAC.Double)
            );
        },
        then: (schema: StringSchema) =>
            schema
                .required('You must choose DuctlessSecondSquareFootage of Ductless Rooms')
                .oneOf(Object.values(DuctlessSecondSquareFootage)),
    }),
});

export const schemaSchedule = Yup.object({
    schedule: Yup.array().of(
        Yup.object().shape({
            schedule: Yup.string().required(),
            preferredDate: Yup.date().required(),
            preferredTimeSlot: Yup.string().required(),
        })
    ),
});
