import { Action } from 'ts-action';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError, filter } from 'rxjs/operators';
import { getCountryById as getServiceCountry } from '../services/storeConfigSevices';
import { AxiosResponse } from 'axios';
import { getCountryById, getCountryByIdError, getCountryByIdSuccess } from '../slice/countrySlice';
import { showSnackbarError } from '../../components/common/Snackbar/SnackbarHelper';
import { Country } from '../../models/country';

export const getCountry$ = (action$: Observable<Action>) =>
    action$.pipe(
        filter(getCountryById.match),
        switchMap((action) =>
            getServiceCountry(action.payload.id).pipe(
                map((resp: AxiosResponse<Country>) => getCountryByIdSuccess(resp.data)),
                catchError((error) => {
                    showSnackbarError('Unable to load country data');
                    return of(getCountryByIdError(error));
                })
            )
        )
    );
