import LimitedEquipmentWarranty from "../../components/icons/LimitedEquipmentWarranty";
import FullWarranty from "../../components/icons/FullWarranty";
import FullInstallation from "../../components/icons/FullInstallation";
import ExtendedLabourWarranty from "../../components/icons/ExtendedLabourWarranty";
import NoHiddenFee from "../../components/icons/NoHiddenFee";

export const RECOMMENDATION_FEATURES_ABOVE_TAB = [
  {
    heading: "10-Year",
    paragraph: "Limited Equipment Warranty",
    icon: LimitedEquipmentWarranty,
  },
  {
    heading: "1-Year",
    paragraph: "Full Labor Warranty",
    icon: FullWarranty,
  },
  {
    heading: "Full Installation",
    paragraph: "Including equipment,necessary parts, labor and disposal",
    icon: FullInstallation,
  },
  {
    heading: "Up to 10-Year",
    paragraph: "Extended Labor Warranty Available",
    icon: ExtendedLabourWarranty,
  },
  {
    heading: "A Fixed Cost",
    paragraph: "No Hidden Fees",
    icon: NoHiddenFee,
  },
];

export const RECOMMENDATION_FEATURES_MOBILE = [
  {
    heading: "Up to 10-Year",
    paragraph: "Extended Labor Warranty Available",
    icon: ExtendedLabourWarranty,
  },
  {
    heading: "No Hidden Fees",
    paragraph: "Transparent Process",
    icon: NoHiddenFee,
  },
];

export const IAQ_HEADING_PARAGRAPH_1 = `Particles or gasses that are not normally part of the air affect air quality. This is called air pollution. Air pollution can also happen indoors. Why is this? You bring outdoor air inside any time you open a door or window. You can also bring in pollen and smoke. Leaks around doors and windows can let in polluted outdoor air. Indoor air can have allergens like dust, pet dander and mold. Humidity can also lead to higher dust mite levels. Particles are released into the air by furnaces, wood-burning fires and candles. Cooking sends oil and fat particulates into the air. Newly installed floors and furniture can release chemicals.In short, Indoor air quality can be two to five times more polluted than outdoor air quality. Outdoor air quality is an issue. But the air inside, where we spend most of our time, has a number of pollutants added to it that make it even more Cutting edge indoor air quality systems are incredibly effective at reducing allergies, alleviating asthma, reducing illness, improving sleep and simply helping us breath easier.`;
export const blockInvalidChar = (e: {
  key: string;
  preventDefault: () => any;
}) => ["e", "E"].includes(e.key) && e.preventDefault();
