import * as React from "react"
import { SVGProps } from "react"
const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <defs>
      <style>
        {".b{fill:none;stroke:#607dce;stroke-linecap:round;stroke-width:2.5px}"}
      </style>
    </defs>
    <circle
      cx={12}
      cy={12}
      r={12}
      style={{
        fill: "#e4ebff",
      }}
      transform="rotate(180 12 12)"
    />
    <path d="M12 12v5M12 8h0" className="b" />
  </svg>
)
export default InfoIcon
