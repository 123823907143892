import React from "react";
import config from "./components/common/config";
import Axios from "axios-observable";
import axios from "axios";
import ReactDOM from "react-dom/client";
import { showSnackbarError } from 'components/common/Snackbar/SnackbarHelper';
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";
import { makeStore } from "./store";
import { Provider } from "react-redux";

// import reportWebVitals from './reportWebVitals';
const { store, persistor } = makeStore();

export const http = () => {
  const axiosObj = Axios.create(getAxiosObj());
  axiosObj.interceptors.response.use((response) => {
    return response;
  }, errorCallback);
  return axiosObj;
};

const errorCallback = (err) => {
  if (err.response.status === 403 || err.response.status === 401) {
    // store.dispatch(clearLoginData());
    showSnackbarError("Your session expired");
  }
  return Promise.reject(err);
};

const getAxiosObj = () => {
  return {
    baseURL: config.apiHost,
    headers: getHeaders(),
    timeout: config.httpTimeout,
    withCredentials: true,
  };
};

export const getHeaders = () => {
  let obj = {
    store: config.storeCode,
  };
  return obj;
};

export const getAsyncAwaitHttpObj = () => {
  const axiosObj = axios.create(getAxiosObj());
  axiosObj.interceptors.response.use((response) => {
    return response;
  }, errorCallback);
  return axiosObj;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
