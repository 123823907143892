import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import WizardHeader from "../../components/WizardHeader";
import Grid from "@mui/material/Grid";
import { Heading } from "../../components/Heading";
import { Product } from "../../models/product";
import { NumberOfHVAC, ProductCriteria } from "../../models/productCriteria";
import ProductDetail from "../../components/ProductDetail";
import LoadingOverlay from "../../components/common/LoadingOverlay";
import { Box, Link } from "@mui/material";
import WizardCriteriaFooterNew from "../../components/WizardCriteriaFooterNew";
import EquipmentFeature from "./EquipmentFeature";
import { SXCssObject } from "../../models/ThemeImages";
import Footer from "../../components/common/Footer";
import DisclaimerText from "../../components/common/DisclaimerText";
import { Paragraph } from "../../components/Paragraph";

const createCssObject = (theme: Theme): SXCssObject => ({
  contentContainer: {
    minHeight: `calc(100vh - ${theme.spacing(8)})`,
  },
  header: {
    pt: {
      xs: "30px",
      sm: "43px",
      md: "35px",
    },
    pb: {
      xs: "20px",
      md: "30px",
    },
  },
  divider: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  promotionalContentBlock: {
    width: "100%",
    mt: "40px",
    mb: "60px",
  },
  managePromotionsWrapper: {
    backgroundColor: theme.palette.common.white,
    display: "inline-flex",
    justifyContent: "center",
    p: "8px",
  },
  noProductList: {
    textAlign: "center",
    minHeight: "100vh",
  },
  actions: {
    width: {
      sm: "100%",
    },
    padding: {
      xs: "15px 5px 15px",
      md: "15px 0px",
    },
    backgroundColor: theme.palette.secondary.dark,
  },
  helpFooter: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1, 0, 1, 0),
  },
  buttonSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    mb: "-15px",
    p: {
      xs: "0px !important ",
      sm: "50px 0px 0px 0px !important",
    },
    display: {
      xs: "flex ",
      sm: "inherit",
    },
    justifyContent: {
      xs: "center",
      sm: "inherit",
    },
  },
  checkIcon: {
    color: theme.customProperties.svgSecondaryColor,
    verticalAlign: "middle",
    fontSize: "15px",
    fontWeight: 900,
  },
  productDetailLabel: {
    pl: {
      xs: theme.spacing(0),
      lg: "inherit",
    },
  },
  iaqContent: {
    mt: {
      sm: "20px !important",
    },
    pl: {
      sm: "0px !important",
      md: "inherit",
    },
  },
  iaqBlock: {
    border: `1px solid ${theme.palette.primary.dark}`,
    backgroundColor: theme.palette.primary.dark,
    margin: "30px 0px 10px 0px",
    width: "100%",
    marginTop: {
      xs: "30px",
    },
    height: {
      lg: "265px",
    },
  },
  productsContainer: {
    padding: {
      xs:"100px 0 20px 0",
      md:"140px 0 20px 0",
    }
  },
});

interface Props {
  loading: boolean;
  products?: Product[];
  criteria: ProductCriteria;
  mainSku?: string;
  selectionMainSku?: string;
  editMainProductSku?: string;
  onSelectProduct: (product: Product) => void;
  onSelectIAQ: () => void;
  onContinue: () => void;
  isSecondSystemFlow?: boolean;
  isIAQProducts?: Product[];
}
function EquipmentOptions(props: Props) {
  const theme = useTheme();
  const {
    products,
    loading,
    selectionMainSku,
    isSecondSystemFlow,
    editMainProductSku,
    mainSku,
    criteria,
  } = props;
  const cssObject: SXCssObject = createCssObject(theme);
  const shouldRedirectToSecondSystemFlow =
    (!isSecondSystemFlow &&
      criteria.numberOfHVAC === NumberOfHVAC.Double &&
      !selectionMainSku) ||
    editMainProductSku;
  const systemType =
    props.products &&
    props.products.length > 0 &&
    props.products[0].productAttributes &&
    props?.products[0].productAttributes.systemType;

  let homeSystem = "";
  const iaqSystem =
    systemType && systemType?.indexOf("IAQ") !== -1 ? true : false;
  const ductlessSystem =
    systemType && systemType?.indexOf("Ductless") !== -1 ? true : false;
  if (systemType) {
    if (iaqSystem) {
      homeSystem = "Indoor Air Quality Package";
    } else if (ductlessSystem) {
      homeSystem = "Ductless System";
    } else if (
      systemType === "AC & Coil Only" ||
      systemType === "Furnace Upgrade Only" ||
      systemType === "Furnace Only"
    ) {
      homeSystem = "Partial HVAC";
    } else homeSystem = "Complete HVAC";
  }
  const [isContinueEnabled, setIsContinueEnabled] = React.useState(
    selectionMainSku || editMainProductSku || mainSku
  );

  const handleSubmit = () => {
    if (props.onContinue) {
      props.onContinue();
    }
  };

  return (
    <Box>
      <Box sx={cssObject.contentContainer}>
        <LoadingOverlay open={loading} />
        <WizardHeader shadow={false} showRestartButton={true} isFixed={true} />
        <Container maxWidth={"lg"}>
          <Box sx={cssObject.productsContainer}>
            <Grid container padding="0">
              {products && products.length > 0 && (
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent={"space-around"}
                  alignItems={"center"}
                >
                  <Grid item xs={12} sm={9} style={{ padding: "0px 5px" }}>
                    <Heading
                      label={`Recommendations For ${
                        homeSystem ? homeSystem : "HVAC"
                      }`}
                    />
                    <Paragraph sx={{ my: 2 }}>
                      Goodman brand systems are{" "}
                      <Link
                        href={
                          "https://www.goodmanmfg.com/resources/customer-reviews"
                        }
                        target={"_blank"}
                      >
                        {"highly rated by consumers"}
                      </Link>
                      &nbsp;and proudly made in the USA.<br />
                      All prices INCLUDE expert installation, 10 Year Limited
                      System Warranty, 1 Year Full Labor Warranty, taxes, and
                      our 100% Homeowner Satisfaction Pledge. Our expert HVAC
                      installers will confirm your selection is the best fit for
                      your home prior to final purchase and installation.
                    </Paragraph>
                  </Grid>
                  <Grid item xs={3}>
                    {/* <MinimisedNotesDrawer /> */}
                  </Grid>
                </Grid>
              )}
              {products && products.length > 0 && (
                <EquipmentFeature products={products} />
              )}

              {(!products || !products.length) && (
                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  sx={cssObject.noProductList}
                >
                  <Grid item xs={12}>
                    <Heading
                      variant={"h2"}
                      label={
                        "Could not find any products based on your requirements."
                      }
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        </Container>
        {products && products.length > 0 && (
          <Container maxWidth={"lg"}>
            <Grid sx={cssObject.productList} container>
              {products?.map((product, index) => (
                <Grid key={index} item xs={12} container direction={"column"}>
                  <ProductDetail
                    item={product}
                    onSelect={(isButtonSelected, componentSku) => {
                      isButtonSelected
                        ? setIsContinueEnabled(componentSku)
                        : setIsContinueEnabled("");
                      isButtonSelected && props.onSelectProduct(product);
                    }}
                    selectedButtonSku={isContinueEnabled}
                  />
                  {products?.length !== index + 1 && (
                    <Grid item sx={cssObject.divider}>
                      {""}
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid>
            <DisclaimerText />
          </Container>
        )}
      </Box>
      <br />
      <br />
      <br />
      <br />
      <Box sx={[cssObject.actions, { display: "flex", alignItems: "center" }]}>
        <Container maxWidth={"lg"}>
          <Grid container>
            <Grid
              container
              alignItems={"center"}
              item
              xs={12}
              sm={6}
              md={8}
            >
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={cssObject.buttonSection}>
              <WizardCriteriaFooterNew
                nextLabel={"Continue"}
                onNext={handleSubmit}
                isSmallerFont={shouldRedirectToSecondSystemFlow ? true : false}
                disabled={!isContinueEnabled}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}

export default EquipmentOptions;
