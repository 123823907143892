import { Action } from "ts-action";
import { ofType } from "ts-action-operators";
import { EMPTY, Observable } from "rxjs";
import {
  switchMap,
  ignoreElements,
  catchError,
  withLatestFrom,
  map,
} from "rxjs/operators";
import { selectCart } from "../selectors/cartSelector";
import { selectCombinedListInProgress } from "../selectors/selection";
import { combineEpics } from "redux-observable";
import { RootState } from "..";
import { SelectedItem } from "../../models/cart";
import {
  contactInprogress,
  contactQuotedOptions,
} from "../services/hubspotServices";
import { selectUserProfile } from "../selectors/userDetails";
import {
  contactInprogressSuccess,
  createContactSuccess,
  createContact as createContactAction,
  contactInprogress as contactInprogressAction,
} from "../slice/cartSlice";

export const createContact$ = (
  action$: Observable<Action>,
  state$: Observable<RootState>
) =>
  action$.pipe(
    ofType(createContactAction),
    map(() => createContactSuccess({ secondSystem: false })),
    catchError((error) => {
      return EMPTY;
    })
  );
export const quotedOptions$ = (
  action$: Observable<Action>,
  state$: Observable<RootState>
) =>
  action$.pipe(
    ofType(createContactSuccess),
    withLatestFrom(state$.pipe(map(selectCart))),
    switchMap(([action, cart]) => {
      if (
        !action.payload.secondSystem &&
        cart.contactInformation &&
        !isEmpty(cart.contactInformation.quotedOptions)
      ) {
        return contactQuotedOptions({ ...cart.contactInformation }).pipe(
          catchError((error) => {
            console.error(error);
            return EMPTY;
          })
        );
      } else if (
        action.payload.secondSystem &&
        cart.contactInformation &&
        !isEmpty(cart.contactInformation.quotedOptionsB)
      ) {
        return contactQuotedOptions({ ...cart.contactInformation }).pipe(
          catchError((error) => {
            console.error(error);
            return EMPTY;
          })
        );
      } else return EMPTY;
    }),
    ignoreElements()
  );
export const updateContactInProgress$ = (
  action$: Observable<Action>,
  state$: Observable<RootState>
) =>
  action$.pipe(
    ofType(contactInprogressAction),
    withLatestFrom(
      state$.pipe(map(selectCombinedListInProgress)),
      state$.pipe(map(selectUserProfile))
    ),
    switchMap(([action, items, contact]) => {
      const inProgressPayload = passDataToInProgress(items, contact.email);
      return contactInprogress(inProgressPayload).pipe(
        map((res) => contactInprogressSuccess(res.data)),
        catchError((error) => {
          console.error(error);
          return EMPTY;
        })
      );
    }),
    ignoreElements()
  );

const passDataToInProgress = (items: SelectedItem[], email: string) => {
  return {
    email: email,
    cart: {
      items: items,
    },
  };
};
const isEmpty = (object?: object) => {
  return object
    ? Object.values(object).every(
        (x) => x === null || x === "" || x === undefined
      )
    : null;
};
export default combineEpics(
  createContact$,
  quotedOptions$,
  updateContactInProgress$
);
