// import heroDesktop from  "../assets/images/hero/fpl_hero_big.webp"
// @ts-ignore
import heroDesktop from  "assets/images/hero/fpl_hero_big.webp";
// @ts-ignore
import heroSmall from  "assets/images/hero/fpl_hero_big.webp"
// @ts-ignore
import homeFinancingImage from  "assets/images/t3_home_financing_image.webp"
// @ts-ignore
import logoMobile from  "assets/icons/logo_Black_Green.svg"
// @ts-ignore
import logoDesktop from "assets/icons/logo_fpl.png"
import { ThemeOptions } from '@mui/material/styles';
import { customPropertiesObj, muiComponentTheme } from './CommonThemeProperties';
import { ThemeContent, ThemeImages } from "../models/ThemeImages";

export const FONTFAMILY_PARAGRAPH = 'Roboto';
export const FONTFAMILY_HEADING = 'Poppins'

declare module '@mui/material/styles' {
    interface Theme {
        customProperties?: any;
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        customProperties?: any;
    }
}
export const fplImages: ThemeImages = {
    logoMobile,
    logoDesktop,
    homeFinancingImage,
    homePage: {
        heroSmall: [{ imageUrl: heroSmall }],
        heroDesktop: [{ imageUrl: heroDesktop }],
    },
};

const COLOR_HEADING = '#1F1F1F';
export const theme: ThemeOptions = {
    components: { ...muiComponentTheme },
    palette: {
        background: {
            default: '#f5f5f5',
        },
        primary: {
            main: '#1D99D5', // variable; Button background
            light: '#E4F0F5', // variable; light primary
            dark: '#147aac', // variable; button hover
            contrastText: '#BFDCAA', // primary medium light color
        },
        secondary: {
            main: '#F5F5F5', //Secondary button background color
            light: '#3A3A3A', // Secondary button text color
            dark: '#E1E1E1', //Secondary button background(on hover) color
            contrastText: '#ADADAD', // Secondary button disabled.
        },
        success:{
            main: '#67BD45', 
            light:'#E9F5E0',
            dark: '#5B9730', 
            contrastText: '#f6faf4', 
        },
        text: {
            primary: '#4A4A4A', // Text
            secondary: '#FFFFFF', // Text
        },
        divider: '#CCCCCC', // also used in border color
        action: {
            active: COLOR_HEADING, // Link active/hover state
            selected: '#8F9197', // variable; Wizard button selected
            hover: '#3A3A3A', // Outlined button border color in hover state
            disabled: '#c8c8c8',
        },
    },
    customProperties: {
        ...customPropertiesObj,
        svgPrimaryColor: '#0096DB',
        svgPrimaryLightColor: '#D1EAF5',
    },
    typography: {
        fontWeightMedium: 500,
        h1: {
            fontFamily: FONTFAMILY_HEADING, // variable
            fontWeight: 700, // variable
            fontSize: '2.6875rem',
            color: COLOR_HEADING,
            lineHeight: '3.125rem',
            letterSpacing: 0,
            textTransform: 'uppercase',
        },
        h2: {
            fontFamily: FONTFAMILY_HEADING, // variable
            fontWeight: 700, // variable
            fontSize: '2rem', //
            color: COLOR_HEADING,
            lineHeight: '2.25rem',
            letterSpacing: 0,
        },
        h3: {
            fontFamily: FONTFAMILY_HEADING, // variable
            fontWeight: 700, // variable
            fontSize: '1.5rem',
            color: COLOR_HEADING,
            lineHeight: '1.75rem',
            letterSpacing: 0,
        },
        h4: {
            fontFamily: FONTFAMILY_HEADING, // variable
            fontWeight: 700, // variable
            fontSize: '1.25rem',
            color: COLOR_HEADING,
            lineHeight: '1.75rem',
            letterSpacing: 0,
        },
        h5: {
            fontFamily: FONTFAMILY_HEADING, // variable
            fontWeight: 700, // variable
            fontSize: '1rem',
            color: COLOR_HEADING,
            lineHeight: '1.75rem',
            letterSpacing: 0,
            textTransform: 'uppercase',
        },
        body1: {
            // Paragraph
            fontFamily: FONTFAMILY_PARAGRAPH, // variable
            fontWeight: 400, // variable
            fontSize: '1rem',
            lineHeight: '1.5rem',
            letterSpacing: 0,
        },
        body2: {
            // Paragraph Small
            fontFamily: FONTFAMILY_PARAGRAPH, // variable
            fontWeight: 400, // variable
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            letterSpacing: 0,
        },
        subtitle1: {
            // Link
            fontFamily: FONTFAMILY_PARAGRAPH, // variable
            fontWeight: 500, // variable
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            letterSpacing: 0,
            textTransform: 'uppercase',
            color: COLOR_HEADING,
        },
        subtitle2: {
            // Footer
            fontFamily: FONTFAMILY_PARAGRAPH, // variable
            fontWeight: 500, // variable
            fontSize: '0.75rem',
            lineHeight: '1rem',
            letterSpacing: 0,
            textTransform: 'uppercase',
            color: '#6D6D6D',
        },
        button: {
            // Button
            fontFamily: FONTFAMILY_PARAGRAPH, // variable
            fontWeight: 500, // variable
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            textAlign: 'center',
            textTransform: 'uppercase',
        },
    },
    spacing: 10,
    shape: {
        borderRadius: 20,
    },
};
export const fplContent: ThemeContent = {
    label: {
        shippingAddressLabel: 'Home',
    },
};

export default theme;
