import { combineEpics, Epic, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import {  getStore as getStoreService } from '../services/storeConfigSevices';
import { AxiosResponse } from 'axios';
import { getStore, getStoreError, getStoreSuccess } from '../slice/storeSlice';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { showSnackbarError } from '../../components/common/Snackbar/SnackbarHelper';
import { Store } from '../../models/storeConfig';

export const getStore$:Epic = (action$: Observable<ActionCreatorWithoutPayload<any>>) =>
    action$.pipe(
        ofType(getStore),
        switchMap(() =>
            getStoreService().pipe(
                switchMap((resp: AxiosResponse<Store>) => [getStoreSuccess(resp.data)]),
                catchError((error) => {
                    showSnackbarError('Unable to load store data');
                    return of(getStoreError(error));
                })
            )
        )
    );


export default combineEpics(getStore$);

// action: PayloadAction<Cake[], string, { currentPage: number }>,
