import React, { useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useDispatch } from "react-redux";

import WebFont from "webfontloader";
import Router from "./router";
import { theme } from "./storeDetails/theme";
import { IS_ALIVE } from "./components/common/Utilities";
import Cookies from "js-cookie";
import { resetUserDetails } from "./store/slice/userDetailsSlice";
import { resetSelection } from "./store/slice/selectionSlice";
import { resetMainProductSearch } from "./store/slice/productSearchSlice";
import { resetProductCriteria } from "./store/slice/productCriteriaSlice";
import { resetTaxRate } from "./store/slice/taxRateSlice";
import { SnackbarAlertProvider } from "./components/common/Snackbar/SnackbarAlert";

const App: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!Cookies.get(IS_ALIVE)) {
      dispatch(resetUserDetails());
      // @ts-ignore
      dispatch(resetSelection());
      dispatch(resetMainProductSearch());
      dispatch(resetProductCriteria());
      dispatch(resetTaxRate());
    }
  }, [dispatch]);
  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Roboto:300,400,500,600",
          "Poppins:400,700",
          "Ubuntu:400,700",
          "Carrois Gothic:300,400,500,600",
          "IBM Plex Sans:400,700",
        ],
      },
    });
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarAlertProvider>
        <Router />
      </SnackbarAlertProvider>
    </ThemeProvider>
  );
};
export default App;
