import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Link as MuiLink } from "@mui/material";
import { SXCssObject } from "../models/ThemeImages";
// import Link from 'next/link'

const createCssObject = (theme: Theme, props: Props): SXCssObject => ({
  root: {
    "&:hover": {
      color: theme.palette.action.active,
      cursor: "pointer",
    },
  },
  active: {
    color: `${theme.palette.action.active} !important`,
  },
  fontColor: {
    color: `${props.fontColor} !important`,
    fontWeight: theme.typography.fontWeightMedium,
  },
  footer: {
    color: theme.typography.subtitle2.color,
  },
  header: {
    color: theme.typography.subtitle2.color,
  },
  smallFont: {
    fontSize: "14px",
  },
});

interface Props {
  id?: string;
  label?: string;
  href?: string;
  active?: boolean;
  color?: "primary" | "secondary";
  variant?: "subtitle1" | "subtitle2";
  fontColor?: any;
  smallFont?: any;
  onClick?: () => void;
  children?: any;
}

export function Link(props: Props) {
  const cssObject = createCssObject(useTheme(), props);
  const { label, active = false, href = "#", ...other } = props;
  return (
    <MuiLink
      underline={"none"}
      variant="subtitle1"
      sx={[cssObject.root, cssObject.header, active && cssObject.active]}
      // component={Link}
      href={href}
      {...other}
    >
      {label}
    </MuiLink>
  );
}
export function CommonLink(props: Props) {
  const cssObject = createCssObject(useTheme(), props);

  const { label, smallFont, fontColor, href = "#", ...other } = props;
  return (
    <MuiLink
      underline={"none"}
      target={"_blank"}
      sx={[fontColor && cssObject.fontColor, smallFont && cssObject.smallFont]}
      href={href}
      {...other}
    >
      {label}
    </MuiLink>
  );
}
export function CommonLinkInline(props: Props) {
  const cssObject = createCssObject(useTheme(), props);

  const { label, fontColor, href = "#" } = props;
  return (
    <MuiLink
      underline={"none"}
      sx={[fontColor && cssObject.fontColor]}
      href={href}
    >
      {label}
    </MuiLink>
  );
}
export function FooterLink(props: Props) {
  const cssObject = createCssObject(useTheme(), props);

  const { label, href = "#", ...other } = props;
  // const redirectToExternal = href.indexOf("http") !== -1;
  return (
    <MuiLink
      underline={"none"}
      variant="subtitle2"
      sx={[cssObject.root, cssObject.footer]}
      // {...(redirectToExternal
      //   ? { href: href, target: "_blank" }
      //   : { component: '', to: href })}
        href={href}
      {...other}
    >
      {label}
    </MuiLink>
  );
}

export function ExternalLink(props: Props) {
  const cssObject = createCssObject(useTheme(), props);

  const { label, children, href, ...other } = props;
  return (
    <MuiLink
      underline={"none"}
      target={"_blank"}
      variant="subtitle1"
      sx={[cssObject.root, cssObject.header]}
      href={href}
      {...other}
    >
      {label || children}
    </MuiLink>
  );
}
