import React, { PropsWithChildren, useEffect } from "react";
import { FormikProps, withFormik } from "formik";
import { styled, Theme, useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ContentContainer from "../../components/ContentContainer";
import WizardHeader from "../../components/WizardHeader";
import FirstSystemInfo from "../wizard/FirstSystemInfo";
import WizardSelection from "../../components/WizardSelection";
import HeadlineParagraph from "../../components/HeadlineParagraph";
import LoadingOverlay from "../../components/common/LoadingOverlay";
import {
  SquareFootage,
  ProductCriteria,
  SplitOrPackage,
  NumberOfHVAC,
  HomeSystem,
  SplitReplacementType,
  PackageReplacementType,
  PartialSystemType,
  FurnaceEfficiency,
  DuctlessNumberOfRooms,
  DuctlessFirstSquareFootage,
  DuctlessSecondSquareFootage,
} from "../../models/productCriteria";
import WizardCriteriaFooterNew from "../../components/WizardCriteriaFooterNew";
import { Box, ClickAwayListener, Icon, LinearProgress } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Button, Button as CustomButton } from "../../components/Button";
import TooltipIcon from "../../components/icons/TooltipIcon";
import InfoIcon from "../../components/icons/InfoToolTip";
import {
  CompleteOrPartialOrDuctlessOptions,
  DuctlessFirstSquareFootageOptions,
  DuctlessRoomsOptions,
  DuctlessSecondSquareFootageOptions,
  EfficiencyOptions,
  HvacSystemOptions,
  PackagedTypeReplacementOptions,
  PartialTypeReplacementOptions,
  SplitOrPackageOptions,
  SplitTypeReplacementOptions,
  SquareFeetOptions,
  tooltipParagraphText,
} from "./wizard-constants";
import { SXCssObject } from "../../models/ThemeImages";
import { ThemeWithAdditionalData } from "../../storeDetails/theme";
import { useLocation, useNavigate } from "react-router-dom";
import { Paragraph } from "../../components/Paragraph";
import { LeadIntakeForm } from "./LeadIntakeForm";
import { useSelector } from "react-redux";
import { selectShouldShowLeadIntakeForm } from "../../store/selectors/userDetails";
import Footer from "../../components/common/Footer";

const createCssObject = (theme: Theme): SXCssObject => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height:'100%',
  },
  main: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
    paddingTop: {
      xs:10,
      md:14
    },
  },
  footerContainer: {
    position: {
      xs:"inherit",
      md:"absolute"
    },
    bottom: 0,
    width: "100%",
  },
  actions: {
    width: {
      sm: "100%",
    },
    padding: {
      xs: "15px 5px 15px",
      md: "15px 0px",
    },
    backgroundColor: theme.palette.secondary.dark,
  },
  helpFooter: {
    padding: theme.spacing(1, 0, 1, 0),
    paddingRight: {
      xs: theme.spacing(0),
      lg: "inherit",
    },
  },
  footerButton: {
    paddingLeft: {
      md: theme.spacing(10),
      lg: "inherit",
    },
  },

  paragraphPadding: {
    paddingBottom: {
      xs: theme.spacing(0.5),
      sm: theme.spacing(1.5),
    },
  },
  fix: {
    position: "fixed",
  },
  wizardQuestionCard: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1.5),
  },
  subHeadingPadding: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1.5),
    paddingRight: {
      lg: theme.spacing(1),
    },
  },
  tooltipIconButton: {
    minWidth: theme.spacing(0.5),
    padding: "0px !important",
    height: "auto",
    background: "none",
    "&:hover": {
      backgroundColor: "none",
    },
  },
  boxShadow: {
    boxShadow: "0px 5px 15px #5B5B5B17",
  },
  tooltip: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: "5px 0px 5px 5px",
    fontSize: "1.875rem",
    padding: "0 20px 20px 20px",
    margin: "10px 0 0 0",
    lineHeight: "1.25rem",
    boxShadow: "0px 6px 17px #00000012",
    pointerEvents: "auto",
    border: `1px solid ${theme.palette.primary.main}`,
  },
  tooltipArrow: {
    transform: "rotate(180deg)",
    float: "right",
    position: "relative",
    top: "-12.5px",
    color: theme.palette.primary.light,
    left: "28.5px",
    stroke: theme.palette.primary.main,
    strokeWidth: "1px",
    strokeDasharray: "27 13 28.284",
  },
  tooltipParagraph: {
    textAlign: "justify",
    fontSize: {
      xs: "0.875rem",
      lg: "1rem",
    },
    lineHeight: {
      lg: "1.375rem",
    },
    letterSpacing: "0px",
  },
  tolltipHeading: {
    textAlign: "left",
    fontSize: "1.4375rem",
    letterSpacing: "0px",
    paddingBottom: "10px",
    color: theme.typography.h1.color,
    fontWeight: theme.typography.fontWeightMedium,
    display: "flex",
    alignItems: "center",
  },
  tooltipButtonContainer: {
    display: "flex",
    alignItems: "center",
    paddingTop: {
      xs: "10px",
      sm: "inherit",
    },
    justifyContent: {
      xs: "left",
      sm: "flex-end",
    },
  },
  tooltipIcon: {
    width: "29px",
    height: "27px",
    marginRight: "5px",
    marginBottom: "-3px",
  },
  scrollable: {
    overflowY: "auto",
    listStyle: "none",
    mt: 0,
    mb: 2,
    maxHeight: {
      xs:`calc(100vh - ${theme.spacing(10)})`,
      md:`calc(100vh - ${theme.spacing(40)})`
    },
    // minHeight: `calc(100vh - ${theme.spacing(34)})`,
    "&::-webkit-scrollbar": {
      width: "0px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 0px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent",
    },
  },
});

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  width: "auto",
  [theme.breakpoints.up("sm")]: {
    width: "266px",
  },
  marginBottom: "20px",
  backgroundColor: `${
    theme.palette.grey[theme.palette.mode === "light" ? 200 : 700]
  } !important`,
  [theme.breakpoints.up("xs")]: {
    marginBottom: theme.spacing(1),
  },
  "& .MuiLinearProgress-colorPrimary": {
    backgroundColor: `${
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 700]
    } !important`,
  },
  "& 	.MuiLinearProgress-bar": {
    borderRadius: 5,
    backgroundColor: `${theme.palette.success.main}`,
  },
}));
interface FormValues {
  squareFootage: string;
  homeSystem: any;
  splitOrPackage: string;
  numberOfHVAC: string;
  splitReplacementType: string;
  packageReplacementType: string;
  partialSystemType: string;
  efficiency: string;
  ductlessFirstSquareFootage: string;
  ductlessSecondSquareFootage: string;
  ductlessNumberOfRooms: string;
}

interface Props {
  loading: boolean;
  criteria: ProductCriteria;
  isDoubleSystem: boolean;
  onNext: (data: ProductCriteria) => void;
  onExit: () => void;
  width?: "xs" | "sm" | "md" | "lg" | "xl";
}

export const AlwaysScrollToBottom = () => {
  const elementRef: any = React.useRef();
  React.useEffect(() =>
    elementRef.current.scrollIntoView({ behavior: "smooth" })
  );
  return <div ref={elementRef} />;
};

function WizardHomeInfo(
  props: PropsWithChildren<Props> & FormikProps<FormValues>
) {
  const location = useLocation();
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  let { numberOfHVAC } = props.values;
  const criteria = props.criteria;
  const showLeadIntakeForm = useSelector(selectShouldShowLeadIntakeForm);
  const [refreshButton, setRefreshButton] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const cssObject: SXCssObject = createCssObject(useTheme());
  const { loading, submitForm, isDoubleSystem } = props;
  const theme = useTheme<ThemeWithAdditionalData>();
  const [questionProgress, setQuestionProgress] = React.useState<number>(
    isDoubleSystem ? 50 : 0
  );
  // eslint-disable-next-line
  const [itemTrigger, setItemTrigger] = React.useState(0);
  const hvacDoubleSystem = props.values.numberOfHVAC === NumberOfHVAC.Double;
  /**
   * This flag captures whether the static first question(Paragraph that says the process) is read out and marked.
   * We are displaying wizard questions, only after this question is read out.
   *  */
  //   const isFirstQnShown = useSelector(selectWizardFirstQnStatus);
  useEffect(() => {
    criteria.numberOfHVAC ? setRefreshButton(true) : setRefreshButton(false);
    criteria.homeSystem && handleOnChangeHomeSystem(criteria.homeSystem);
    criteria.splitOrPackage &&
      handleOnChangeSplitOrPackage(criteria.splitOrPackage);
    criteria.splitReplacementType &&
      handleOnChangeSplitReplacementType(criteria.splitReplacementType);
    criteria.packageReplacementType &&
      handleOnChangePackageReplacementType(criteria.packageReplacementType);
    criteria.partialSystemType &&
      handleOnChangePartialSystemType(criteria.partialSystemType);
    criteria.squareFootage &&
      handleOnChangeSquareFootage(criteria.squareFootage);
    criteria.ductlessNumberOfRooms &&
      handleOnChangeDuctlessNumberOfRooms(criteria.ductlessNumberOfRooms);
    criteria.ductlessFirstSquareFootage &&
      handleOnChangeDuctlessFirst(criteria.ductlessFirstSquareFootage);
    criteria.ductlessSecondSquareFootage &&
      handleOnChangeDuctlessSecond(criteria.ductlessSecondSquareFootage);
    criteria.efficiency && handleOnChangeFurnaceEfficiency(criteria.efficiency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function clearFormValues() {
    let updatedFormObject: any = {};
    updatedFormObject.squareFootage = "";
    updatedFormObject.homeSystem = "";
    updatedFormObject.splitOrPackage = "";
    updatedFormObject.numberOfHVAC = "";
    updatedFormObject.splitReplacementType = "";
    updatedFormObject.packageReplacementType = "";
    updatedFormObject.partialSystemType = "";
    updatedFormObject.efficiency = "";
    updatedFormObject.ductlessFirstSquareFootage = "";
    updatedFormObject.ductlessSecondSquareFootage = "";
    updatedFormObject.ductlessNumberOfRooms = "";
    props.setValues({
      ...props.values,
      ...updatedFormObject,
    });
    setQuestionProgress(0);
    setRefreshButton(false);
    navigate(location, {});
  }

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const invokeCommonEvents = () => {
    setIsSubmit(false);
    setItemTrigger((prev) => prev + 1);
    handleTooltipClose();
  };

  // Progress Calculation
  // const progressCalculation = (currentProgress: number, totalprogresslength: number) => {
  //     const progressPercentage = (currentProgress / totalprogresslength) * 100;
  //     setQuestionProgress(Math.floor(progressPercentage));
  // }

  // clearing form values after the selected wizard selection
  const handleOnChangeHAVC = (props: any, value: string) => {
    invokeCommonEvents();
    let updatedFormObject: any = {};
    if (
      value === NumberOfHVAC.Single ||
      value === NumberOfHVAC.Double ||
      value === NumberOfHVAC.Multiple
    ) {
      setRefreshButton(true);
      if (value === NumberOfHVAC.Single) {
        setQuestionProgress(20);
      } else if (value === NumberOfHVAC.Double) {
        setQuestionProgress(10);
      } else if (value === NumberOfHVAC.Multiple) {
        props.isDoubleSystem
          ? setQuestionProgress(100)
          : setQuestionProgress(hvacDoubleSystem ? 50 : 100);
        setIsSubmit(true);
      }
    } else {
      setQuestionProgress(0);
    }
    updatedFormObject.homeSystem = "";
    updatedFormObject.squareFootage = "";
    updatedFormObject.splitOrPackage = "";
    updatedFormObject.splitReplacementType = "";
    updatedFormObject.packageReplacementType = "";
    updatedFormObject.partialSystemType = "";
    updatedFormObject.ductlessNumberOfRooms = "";
    updatedFormObject.ductlessFirstSquareFootage = "";
    updatedFormObject.ductlessSecondSquareFootage = "";
    updatedFormObject.efficiency = "";

    props.setValues({
      ...props.values,
      numberOfHVAC: value,
      ...updatedFormObject,
    });
  };

  const handleOnChangeHomeSystem = (value: string) => {
    invokeCommonEvents();
    let updatedFormObject: any = {};
    if (
      value === HomeSystem.Complete ||
      value === HomeSystem.Partial ||
      value === HomeSystem.Ductless ||
      value === HomeSystem.IAQ
    ) {
      if (value === HomeSystem.Complete) {
        props.isDoubleSystem
          ? setQuestionProgress(62)
          : setQuestionProgress(hvacDoubleSystem ? 20 : 40);
      } else if (
        value === HomeSystem.Partial ||
        value === HomeSystem.Ductless
      ) {
        props.isDoubleSystem
          ? setQuestionProgress(66)
          : setQuestionProgress(hvacDoubleSystem ? 25 : 50);
      } else if (value === HomeSystem.IAQ) {
        props.isDoubleSystem
          ? setQuestionProgress(100)
          : setQuestionProgress(hvacDoubleSystem ? 50 : 100);
        setIsSubmit(true);
      }
    } else {
      props.isDoubleSystem ? setQuestionProgress(50) : setQuestionProgress(20);
    }
    updatedFormObject.squareFootage = "";
    updatedFormObject.splitOrPackage = "";
    updatedFormObject.splitReplacementType = "";
    updatedFormObject.packageReplacementType = "";
    updatedFormObject.partialSystemType = "";
    updatedFormObject.ductlessNumberOfRooms = "";
    updatedFormObject.ductlessFirstSquareFootage = "";
    updatedFormObject.ductlessSecondSquareFootage = "";
    updatedFormObject.efficiency = "";
    props.setValues({
      ...props.values,
      homeSystem: value,
      ...updatedFormObject,
    });
  };

  const handleOnChangeSplitOrPackage = (value: string) => {
    invokeCommonEvents();
    let updatedFormObject: any = {};
    if (value === SplitOrPackage.Split || value === SplitOrPackage.Package) {
      props.isDoubleSystem
        ? setQuestionProgress(75)
        : setQuestionProgress(hvacDoubleSystem ? 30 : 60);
    } else {
      props.isDoubleSystem ? setQuestionProgress(62) : setQuestionProgress(37);
    }
    updatedFormObject.squareFootage = "";
    updatedFormObject.splitReplacementType = "";
    updatedFormObject.packageReplacementType = "";
    updatedFormObject.partialSystemType = "";
    updatedFormObject.efficiency = "";
    props.setValues({
      ...props.values,
      splitOrPackage: value,
      ...updatedFormObject,
    });
  };

  const handleOnChangeSplitReplacementType = (value: string) => {
    invokeCommonEvents();
    let updatedFormObject: any = {};
    if (
      value === SplitReplacementType.HeatPump ||
      value === SplitReplacementType.ElectricalFurnaceAC ||
      value === SplitReplacementType.FurnaceAC ||
      value === SplitReplacementType.HighEfficiencyFurnaceAC
    ) {
      if (
        value === SplitReplacementType.HeatPump ||
        value === SplitReplacementType.ElectricalFurnaceAC ||
        value === SplitReplacementType.HighEfficiencyFurnaceAC
      ) {
        props.isDoubleSystem
          ? setQuestionProgress(87)
          : setQuestionProgress(hvacDoubleSystem ? 40 : 80);
      } else if (value === SplitReplacementType.FurnaceAC) {
        props.isDoubleSystem
          ? setQuestionProgress(80)
          : setQuestionProgress(hvacDoubleSystem ? 33 : 66);
      }
    } else {
      props.isDoubleSystem ? setQuestionProgress(75) : setQuestionProgress(60);
    }
    updatedFormObject.squareFootage = "";
    updatedFormObject.packageReplacementType = "";
    updatedFormObject.partialSystemType = "";
    updatedFormObject.efficiency = "";
    props.setValues({
      ...props.values,
      splitReplacementType: value,
      ...updatedFormObject,
    });
  };

  const handleOnChangePackageReplacementType = (value: string) => {
    invokeCommonEvents();
    let updatedFormObject: any = {};
    if (
      value === PackageReplacementType.HeatPumpPack ||
      value === PackageReplacementType.ElectricalHeatAC ||
      value === PackageReplacementType.GasHeatAC
    ) {
      setQuestionProgress(hvacDoubleSystem ? 40 : 80);
    } else {
      props.isDoubleSystem ? setQuestionProgress(87) : setQuestionProgress(57);
    }
    updatedFormObject.squareFootage = "";
    updatedFormObject.partialSystemType = "";
    updatedFormObject.efficiency = "";
    props.setValues({
      ...props.values,
      packageReplacementType: value,
      ...updatedFormObject,
    });
  };

  const handleOnChangePartialSystemType = (value: string) => {
    invokeCommonEvents();
    let updatedFormObject: any = {};
    if (
      value === PartialSystemType.CoilAC ||
      value === PartialSystemType.Furnace ||
      value === PartialSystemType.FurnaceEfficiency
    ) {
      if (
        value === PartialSystemType.CoilAC ||
        value === PartialSystemType.FurnaceEfficiency
      ) {
        props.isDoubleSystem
          ? setQuestionProgress(83)
          : setQuestionProgress(hvacDoubleSystem ? 37 : 75);
      } else if (value === PartialSystemType.Furnace) {
        props.isDoubleSystem
          ? setQuestionProgress(75)
          : setQuestionProgress(hvacDoubleSystem ? 30 : 60);
      }
    } else {
      setQuestionProgress(50);
    }
    updatedFormObject.squareFootage = "";
    updatedFormObject.efficiency = "";
    props.setValues({
      ...props.values,
      partialSystemType: value,
      ...updatedFormObject,
    });
  };

  const handleOnChangeFurnaceEfficiency = (value: string) => {
    invokeCommonEvents();
    let updatedFormObject: any = {};
    if (
      value === FurnaceEfficiency.Percent80 ||
      value === FurnaceEfficiency.Percent90
    ) {
      props.isDoubleSystem
        ? setQuestionProgress(87)
        : setQuestionProgress(hvacDoubleSystem ? 41 : 83);
    } else {
      setQuestionProgress(71);
    }
    updatedFormObject.squareFootage = "";
    props.setValues({
      ...props.values,
      efficiency: value,
      ...updatedFormObject,
    });
  };

  const handleOnChangeSquareFootage = (value: string) => {
    invokeCommonEvents();
    if (
      value === SquareFootage.Square750 ||
      value === SquareFootage.Square1000 ||
      value === SquareFootage.Square1250 ||
      value === SquareFootage.Square1500 ||
      value === SquareFootage.Square1750 ||
      value === SquareFootage.Square2000 ||
      value === SquareFootage.Square2500 ||
      value === SquareFootage.Square2600
    ) {
      props.isDoubleSystem
        ? setQuestionProgress(100)
        : setQuestionProgress(hvacDoubleSystem ? 50 : 100);
      setIsSubmit(true);
    } else {
      props.isDoubleSystem ? setQuestionProgress(87) : setQuestionProgress(80);
    }
    props.setValues({
      ...props.values,
      squareFootage: value,
    });
  };

  const handleOnChangeDuctlessNumberOfRooms = (value: string) => {
    invokeCommonEvents();
    if (
      value === DuctlessNumberOfRooms.Single ||
      value === DuctlessNumberOfRooms.Double ||
      value === DuctlessNumberOfRooms.Multiple
    ) {
      if (value === DuctlessNumberOfRooms.Single) {
        props.isDoubleSystem
          ? setQuestionProgress(83)
          : setQuestionProgress(hvacDoubleSystem ? 37 : 75);
      } else if (value === DuctlessNumberOfRooms.Double) {
        props.isDoubleSystem
          ? setQuestionProgress(75)
          : setQuestionProgress(hvacDoubleSystem ? 30 : 60);
      } else if (value === DuctlessNumberOfRooms.Multiple) {
        props.isDoubleSystem
          ? setQuestionProgress(100)
          : setQuestionProgress(hvacDoubleSystem ? 50 : 100);
        setIsSubmit(true);
      }
    } else {
      props.isDoubleSystem ? setQuestionProgress(83) : setQuestionProgress(62);
    }
    props.setFieldValue("ductlessFirstSquareFootage", "");
    props.setFieldValue("ductlessSecondSquareFootage", "");
    props.setFieldValue("ductlessNumberOfRooms", value);
  };

  const handleOnChangeDuctlessFirst = (value: string) => {
    if (
      value === DuctlessFirstSquareFootage.Square250 ||
      value === DuctlessFirstSquareFootage.Square400 ||
      value === DuctlessFirstSquareFootage.Square600 ||
      value === DuctlessFirstSquareFootage.Square850
    ) {
      if (props.values.ductlessNumberOfRooms === DuctlessNumberOfRooms.Single) {
        props.isDoubleSystem
          ? setQuestionProgress(100)
          : setQuestionProgress(hvacDoubleSystem ? 50 : 100);
        setIsSubmit(true);
      } else if (
        props.values.ductlessNumberOfRooms === DuctlessNumberOfRooms.Double
      ) {
        if (props.values.ductlessSecondSquareFootage) {
          props.isDoubleSystem
            ? setQuestionProgress(100)
            : setQuestionProgress(hvacDoubleSystem ? 50 : 100);
          setIsSubmit(true);
        } else {
          props.isDoubleSystem
            ? setQuestionProgress(87)
            : setQuestionProgress(hvacDoubleSystem ? 40 : 80);
        }
      }
    } else {
      props.isDoubleSystem
        ? setQuestionProgress(80)
        : setQuestionProgress(hvacDoubleSystem ? 40 : 80);
      setIsSubmit(false);
    }
    props.setValues({
      ...props.values,
      ductlessFirstSquareFootage: value,
    });
    setItemTrigger((prev) => prev + 1);
  };

  const handleOnChangeDuctlessSecond = (value: string) => {
    if (
      value === DuctlessSecondSquareFootage.Square250 ||
      value === DuctlessSecondSquareFootage.Square400 ||
      value === DuctlessSecondSquareFootage.Square600 ||
      value === DuctlessSecondSquareFootage.Square850
    ) {
      if (props.values.ductlessFirstSquareFootage) {
        props.isDoubleSystem
          ? setQuestionProgress(100)
          : setQuestionProgress(hvacDoubleSystem ? 50 : 100);
        setIsSubmit(true);
      } else {
        props.isDoubleSystem
          ? setQuestionProgress(87)
          : setQuestionProgress(hvacDoubleSystem ? 40 : 80);
      }
    } else {
      props.isDoubleSystem
        ? setQuestionProgress(80)
        : setQuestionProgress(hvacDoubleSystem ? 40 : 80);
      setIsSubmit(false);
    }
    props.setFieldValue("ductlessSecondSquareFootage", value);
    setItemTrigger((prev) => prev + 1);
  };

  const tooltipContent = (text?: string) => (
    <ClickAwayListener touchEvent={false} onClickAway={handleTooltipClose}>
      <Box sx={cssObject.tooltip}>
        <span>
          <PlayArrowIcon sx={cssObject.tooltipArrow} />
        </span>
        <Grid container>
          <Grid item xs={12} sm={9}>
            <Box sx={cssObject.tolltipHeading}>
              <TooltipIcon /> &nbsp; HVAC Tips
            </Box>
            <Box sx={cssObject.tooltipParagraph}>
              {!!text ? (
                <div dangerouslySetInnerHTML={{ __html: text }}></div>
              ) : (
                "Air and Heat Done Right offers turnkey HVAC replacement solutions for your home, whether your are replacing your entire system, part of your system, or adding a new system to your home."
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} sx={cssObject.tooltipButtonContainer}>
            <CustomButton onClick={handleTooltipClose} filledButton>
              GOT IT
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
    </ClickAwayListener>
  );
  return (
    <Box sx={cssObject.root}>
      {props.children}
      <LoadingOverlay open={loading} />
      <WizardHeader
        showRestartButton={refreshButton}
        productCriteria={clearFormValues}
        isFixed={true}
        showExitButton={true}
      />
      {showLeadIntakeForm && <LeadIntakeForm />}
      <Box sx={cssObject.main}>
        <ContentContainer disableGrid background={"bg2"}>
          <Container maxWidth={"lg"}>
            <Grid container spacing={0} sx={cssObject.commonContent}>
              <Grid
                sx={cssObject.scrollable}
                item
                container
                spacing={0}
                xs={12}
                sm={12}
                md={5}
                lg={6}
              >
                <Box sx={{ mr: 1.5 }}>
                  <HeadlineParagraph
                    shouldHideEmptyGridAtBottom={true}
                    label={"Select their equipment"}
                    headlineSize={"h2"}
                  ></HeadlineParagraph>
                  <Box sx={cssObject.subHeadingPadding}>
                    <Paragraph>
                      Answer a few simple questions about your HVAC home
                      project, and we’ll provide you with HVAC options for your
                      home and budget.
                    </Paragraph>
                  </Box>
                  <BorderLinearProgress
                    variant="determinate"
                    value={questionProgress}
                  />
                  <Paragraph semibold>{questionProgress}% completed</Paragraph>
                </Box>
              </Grid>
              <Grid container item xs={12} sm={12} md={7} lg={6}>
                <Grid sx={[cssObject.scrollable]} item>
                  {props.isDoubleSystem && (
                    <Box sx={{ mb: "15px" }}>
                      <FirstSystemInfo />{" "}
                    </Box>
                  )}

                  {!props.isDoubleSystem && (
                    <>
                      {/* Question #1  */}
                      <WizardSelection
                        id={"question-hvac-system"}
                        name="numberOfHVAC"
                        pic={
                          props.values.numberOfHVAC
                            ? theme.customProperties.commonGreenDark
                            : theme.customProperties.commonOrange
                        }
                        label={
                          "How many separate thermostats do you use to regulate your home?"
                        }
                        isBold={props.values.numberOfHVAC ? false : true}
                        options={HvacSystemOptions}
                        handleTooltipOpen={handleTooltipOpen}
                        // @ts-ignore
                        onChange={(v) => {
                          handleOnChangeHAVC(props, v);
                        }}
                      />
                      {open &&
                        !props.values.numberOfHVAC &&
                        tooltipContent(tooltipParagraphText.HVACSystem)}
                    </>
                  )}
                  {(numberOfHVAC === NumberOfHVAC.Single ||
                    numberOfHVAC === NumberOfHVAC.Double) && (
                    <>
                      {/* Question #2 */}
                      <WizardSelection
                        id={"question-HomeSystem"}
                        name="homeSystem"
                        pic={
                          props.values.homeSystem
                            ? theme.customProperties.commonGreenDark
                            : theme.customProperties.commonOrange
                        }
                        label={
                          "What HVAC products are you upgrading in your home?"
                        }
                        isBold={props.values.homeSystem ? false : true}
                        options={CompleteOrPartialOrDuctlessOptions}
                        // @ts-ignore
                        onChange={handleOnChangeHomeSystem}
                        handleTooltipOpen={handleTooltipOpen}
                      />
                      {open &&
                        !props.values.homeSystem &&
                        tooltipContent(tooltipParagraphText.HVACProductTypes)}
                    </>
                  )}
                  {props.values.homeSystem === HomeSystem.Complete && (
                    <>
                      {/* Question #3A */}
                      <WizardSelection
                        id={"question-home-system"}
                        name="splitOrPackage"
                        pic={
                          props.values.splitOrPackage
                            ? theme.customProperties.commonGreenDark
                            : theme.customProperties.commonOrange
                        }
                        isBold={props.values.splitOrPackage ? false : true}
                        label={"Do you need a split system or a packaged unit?"}
                        options={SplitOrPackageOptions}
                        // @ts-ignore
                        onChange={handleOnChangeSplitOrPackage}
                        handleTooltipOpen={handleTooltipOpen}
                      />
                      {open &&
                        !props.values.splitOrPackage &&
                        tooltipContent(tooltipParagraphText.SplitOrPackage)}
                    </>
                  )}
                  {props.values.splitOrPackage === SplitOrPackage.Split &&
                    props.values.homeSystem === HomeSystem.Complete && (
                      <>
                        {/* Question number 4A */}
                        <WizardSelection
                          id={"question-split-system"}
                          name="splitReplacementType"
                          pic={
                            props.values.splitReplacementType
                              ? theme.customProperties.commonGreenDark
                              : theme.customProperties.commonOrange
                          }
                          label={"What type of replacement system do you need?"}
                          isBold={
                            props.values.splitReplacementType ? false : true
                          }
                          options={SplitTypeReplacementOptions}
                          // @ts-ignore
                          onChange={handleOnChangeSplitReplacementType}
                          handleTooltipOpen={handleTooltipOpen}
                        />
                        {open &&
                          !props.values.splitReplacementType &&
                          tooltipContent(tooltipParagraphText.SplitReplacement)}
                      </>
                    )}
                  {props.values.splitOrPackage === SplitOrPackage.Package &&
                    props.values.homeSystem === HomeSystem.Complete && (
                      <>
                        {/* Question number 4B */}
                        <WizardSelection
                          id={"question-package-location"}
                          name="packageReplacementType"
                          pic={
                            props.values.packageReplacementType
                              ? theme.customProperties.commonGreenDark
                              : theme.customProperties.commonOrange
                          }
                          label={"What type of replacement do you prefer?"}
                          isBold={
                            props.values.packageReplacementType ? false : true
                          }
                          options={PackagedTypeReplacementOptions}
                          // @ts-ignore
                          onChange={handleOnChangePackageReplacementType}
                          handleTooltipOpen={handleTooltipOpen}
                        />
                        {open &&
                          !props.values.packageReplacementType &&
                          tooltipContent(
                            tooltipParagraphText.PackageReplacement
                          )}
                      </>
                    )}

                  {props.values.homeSystem === HomeSystem.Partial && (
                    <>
                      {/* Question number 3B */}
                      <WizardSelection
                        id={"question-partial-type"}
                        name="partialSystemType"
                        pic={
                          props.values.partialSystemType
                            ? theme.customProperties.commonGreenDark
                            : theme.customProperties.commonOrange
                        }
                        label={"What type of replacement do you prefer?"}
                        isBold={props.values.partialSystemType ? false : true}
                        options={PartialTypeReplacementOptions}
                        // @ts-ignore
                        onChange={handleOnChangePartialSystemType}
                        handleTooltipOpen={handleTooltipOpen}
                      />
                      {open &&
                        !props.values.partialSystemType &&
                        tooltipContent(tooltipParagraphText.PartialSystem)}
                    </>
                  )}

                  {props.values.homeSystem === HomeSystem.Partial &&
                    props.values.partialSystemType ===
                      PartialSystemType.Furnace && (
                      <>
                        {/* Question number 4 */}
                        <WizardSelection
                          id={"question-furnace-efficiency_1"}
                          name="efficiency"
                          pic={
                            props.values.efficiency
                              ? theme.customProperties.commonGreenDark
                              : theme.customProperties.commonOrange
                          }
                          label={
                            "What is the efficiency of your current furnace?"
                          }
                          isBold={props.values.efficiency ? false : true}
                          options={EfficiencyOptions}
                          // @ts-ignore
                          onChange={handleOnChangeFurnaceEfficiency}
                          handleTooltipOpen={handleTooltipOpen}
                        />
                        {open &&
                          !props.values.efficiency &&
                          tooltipContent(tooltipParagraphText.efficiency)}
                      </>
                    )}
                  {props.values.splitReplacementType ===
                    SplitReplacementType.FurnaceAC &&
                    props.values.splitOrPackage === SplitOrPackage.Split &&
                    props.values.homeSystem === HomeSystem.Complete && (
                      <>
                        {/* Question number 4 */}
                        <WizardSelection
                          id={"question-furnace-efficiency_2"}
                          name="efficiency"
                          pic={
                            props.values.efficiency
                              ? theme.customProperties.commonGreenDark
                              : theme.customProperties.commonOrange
                          }
                          label={
                            "What is the efficiency of your current furnace?"
                          }
                          isBold={props.values.efficiency ? false : true}
                          options={EfficiencyOptions}
                          // @ts-ignore
                          onChange={handleOnChangeFurnaceEfficiency}
                          handleTooltipOpen={handleTooltipOpen}
                        />
                        {open &&
                          !props.values.efficiency &&
                          tooltipContent(tooltipParagraphText.efficiency)}
                      </>
                    )}
                  {/* Ductless question */}
                  {props.values.homeSystem === HomeSystem.Ductless && (
                    <>
                      {/* Question number 2A */}
                      <WizardSelection
                        id={"question-ductless-type"}
                        name="ductlessNumberOfRooms"
                        pic={
                          props.values.ductlessNumberOfRooms
                            ? theme.customProperties.commonGreenDark
                            : theme.customProperties.commonOrange
                        }
                        label={
                          "How many separate rooms will the system heat and cool? "
                        }
                        isBold={
                          props.values.ductlessNumberOfRooms ? false : true
                        }
                        options={DuctlessRoomsOptions}
                        // @ts-ignore
                        onChange={handleOnChangeDuctlessNumberOfRooms}
                        handleTooltipOpen={handleTooltipOpen}
                      />
                      {open &&
                        !props.values.ductlessNumberOfRooms &&
                        tooltipContent(tooltipParagraphText.ductless)}
                    </>
                  )}
                  {props.values.homeSystem === HomeSystem.Ductless &&
                    props.values.ductlessNumberOfRooms ===
                      DuctlessNumberOfRooms.Single && (
                      <>
                        {/* Question number 2Bi */}
                        <WizardSelection
                          id={"question-ductless-area-type"}
                          name="ductlessFirstSquareFootage"
                          pic={
                            props.values.ductlessFirstSquareFootage
                              ? theme.customProperties.commonGreenDark
                              : theme.customProperties.commonOrange
                          }
                          label={"How big is the room?"}
                          isBold={
                            !props.values.ductlessFirstSquareFootage
                              ? true
                              : false
                          }
                          options={DuctlessFirstSquareFootageOptions}
                          // @ts-ignore
                          onChange={handleOnChangeDuctlessFirst}
                          handleTooltipOpen={handleTooltipOpen}
                        />
                        {open &&
                          !props.values.ductlessFirstSquareFootage &&
                          tooltipContent(tooltipParagraphText.ductlessOneRoom)}
                      </>
                    )}

                  {props.values.homeSystem === HomeSystem.Ductless &&
                    props.values.ductlessNumberOfRooms ===
                      DuctlessNumberOfRooms.Double && (
                      <Grid item container xs={12}>
                        <Grid
                          container
                          item
                          sx={[
                            cssObject.wizardQuestionCard,
                            !props.values.ductlessSecondSquareFootage &&
                              cssObject.boxShadow,
                          ]}
                        >
                          {/* Question number 2Bii */}
                          <Grid item>
                            <Icon
                              style={{
                                color:
                                  props.values.ductlessFirstSquareFootage &&
                                  props.values.ductlessSecondSquareFootage
                                    ? theme.customProperties.commonGreenDark
                                    : theme.customProperties.commonOrange,
                                fontSize: "14px",
                                padding: "1.5px 0 0 0",
                                marginRight: "10px",
                              }}
                            >
                              radio_button_unchecked
                            </Icon>
                          </Grid>
                          <Grid item xs={10}>
                            <Paragraph
                              semiLargeFont
                              sx={{ mt: 0 }}
                              semibold={
                                props.values.ductlessFirstSquareFootage &&
                                props.values.ductlessSecondSquareFootage
                                  ? false
                                  : true
                              }
                            >
                              How big is each room?
                            </Paragraph>
                          </Grid>
                          <Grid item xs={1}>
                            {!props.values.ductlessFirstSquareFootage && (
                              <Button
                                onClick={handleTooltipOpen}
                                isTransparent
                                sx={cssObject.tooltipIconButton}
                              >
                                <InfoIcon />
                              </Button>
                            )}
                          </Grid>
                          <Box>
                            <WizardSelection
                              id={"question-ductless-area-type"}
                              name="ductlessFirstSquareFootage"
                              label={"Room 1"}
                              options={DuctlessFirstSquareFootageOptions}
                              isBold={false}
                              // @ts-ignore
                              onChange={handleOnChangeDuctlessFirst}
                              handleTooltipOpen={handleTooltipOpen}
                            />
                          </Box>
                          <Box>
                            <WizardSelection
                              id={"question-ductless-area-type"}
                              name="ductlessSecondSquareFootage"
                              // @ts-ignore
                              onChange={handleOnChangeDuctlessSecond}
                              label={"Room 2"}
                              isBold={false}
                              options={DuctlessSecondSquareFootageOptions}
                              handleTooltipOpen={handleTooltipOpen}
                            />
                          </Box>
                        </Grid>
                        {open &&
                          !props.values.ductlessFirstSquareFootage &&
                          !props.values.ductlessSecondSquareFootage &&
                          tooltipContent(tooltipParagraphText.ductlessTwoRoom)}
                      </Grid>
                    )}

                  {((props.values.splitReplacementType ||
                    props.values.packageReplacementType ||
                    props.values.partialSystemType) &&
                    props.values.splitReplacementType !==
                      SplitReplacementType.FurnaceAC &&
                    props.values.partialSystemType !==
                      PartialSystemType.Furnace) ||
                  props.values.efficiency ? (
                    <>
                      {/* Question number 5 */}
                      <WizardSelection
                        id={"question-square-footage"}
                        name="squareFootage"
                        pic={
                          props.values.squareFootage
                            ? theme.customProperties.commonGreenDark
                            : theme.customProperties.commonOrange
                        }
                        label={
                          "What's the total square footage of your home, excluding basement?"
                        }
                        isBold={props.values.squareFootage ? false : true}
                        options={SquareFeetOptions}
                        // @ts-ignore
                        onChange={handleOnChangeSquareFootage}
                        handleTooltipOpen={handleTooltipOpen}
                      />
                      {open &&
                        !props.values.squareFootage &&
                        tooltipContent(tooltipParagraphText.squareFootage)}
                    </>
                  ) : (
                    ""
                  )}
                  {props.values.homeSystem === HomeSystem.IAQ && (
                    // Added this block for time being, as there is a space between First qn and second qn, when IAQ is choosen.
                    <>
                      <br />
                      <br />
                    </>
                  )}

                  <AlwaysScrollToBottom />
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </ContentContainer>
      </Box>
      <Box sx={cssObject.footerContainer}>
        <Box
          sx={[cssObject.actions, { display: "flex", alignItems: "center" }]}
        >
          <Container disableGutters maxWidth="xl">
            <Container maxWidth={"lg"}>
              <Grid container justifyContent={"flex-end"}>
                <Grid item xs={12} sm={3} md={3}>
                  <WizardCriteriaFooterNew
                    nextLabel="Continue"
                    onNext={submitForm}
                    disabled={!isSubmit}
                  />
                </Grid>
              </Grid>
            </Container>
          </Container>
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}

const formikComponent = withFormik<Props, FormValues>({
  enableReinitialize: true,
  mapPropsToValues: ({ criteria }) => ({
    numberOfHVAC: criteria.numberOfHVAC || "",
    squareFootage: criteria.squareFootage || "",
    homeSystem: criteria.homeSystem || "",
    splitOrPackage: criteria.splitOrPackage || "",
    splitReplacementType: criteria.splitReplacementType || "",
    packageReplacementType: criteria.packageReplacementType || "",
    partialSystemType: criteria.partialSystemType || "",
    ductlessNumberOfRooms: criteria.ductlessNumberOfRooms || "",
    ductlessFirstSquareFootage: criteria.ductlessFirstSquareFootage || "",
    ductlessSecondSquareFootage: criteria.ductlessSecondSquareFootage || "",
    efficiency: criteria.efficiency || "",
  }),
  handleSubmit: (values, { props }) => {
    props.onNext({
      numberOfHVAC: values.numberOfHVAC as NumberOfHVAC,
      squareFootage: values.squareFootage as SquareFootage,
      homeSystem: values.homeSystem as HomeSystem,
      splitOrPackage: values.splitOrPackage as SplitOrPackage,
      splitReplacementType: values.splitReplacementType as SplitReplacementType,
      packageReplacementType:
        values.packageReplacementType as PackageReplacementType,
      partialSystemType: values.partialSystemType as PartialSystemType,
      ductlessNumberOfRooms:
        values.ductlessNumberOfRooms as DuctlessNumberOfRooms,
      ductlessFirstSquareFootage:
        values.ductlessFirstSquareFootage as DuctlessFirstSquareFootage,
      ductlessSecondSquareFootage:
        values.ductlessSecondSquareFootage as DuctlessSecondSquareFootage,
      efficiency: values.efficiency as FurnaceEfficiency,
    });
  },
  validateOnMount: true,
  displayName: "WizardForm",
})(WizardHomeInfo);
export default formikComponent;
