import { createSelector } from 'reselect';
import { RootState } from '../index';
import { SystemGroup } from '../../models/cart';
import { selectTaxRate } from './taxRate';
import { taxCalculation } from '../../components/common/NumberUtil';

export const selectPreCart = (state: RootState) => state.selection;
export const selectPreCartProcessing = createSelector(selectPreCart, (selection) => selection.loading || false);
export const selectPreCartMain = createSelector(selectPreCart, (selection) => selection.items || []);
export const selectPreCartIAQ = createSelector(selectPreCart, (selection) => selection.itemsIAQ || []);
export const selectPreCartAddon = createSelector(selectPreCart, (selection) => selection.itemsAddOn || []);
export const selectPreCartItems = createSelector(
    selectPreCartMain,
    selectPreCartIAQ,
    selectPreCartAddon,
    (mainItems, IAQitems, addOns) => {
        return {
            ...(mainItems.length > 0 && {
                firstSystemMain: mainItems.find((item) => item.systemGroup === SystemGroup.SystemGroup1),
            }),
            ...(mainItems.length > 1 && {
                secondSystemMain: mainItems.find((item) => item.systemGroup === SystemGroup.SystemGroup2),
            }),
            ...(IAQitems.length > 0 && {
                firstSystemIAQ: IAQitems.find((item) => item.systemGroup === SystemGroup.SystemGroup1),
            }),
            ...(IAQitems.length > 0 && {
                secondSystemIAQ: IAQitems.find((item) => item.systemGroup === SystemGroup.SystemGroup2),
            }),
            addOnsList: addOns,
        };
    }
);
export const selectCombinedList = createSelector(selectPreCart, (selection) => {
    const mainItems = selection.items || [];
    const itemsAddOnWithQuantity = selection.itemsAddOn.filter((addOn) => addOn.quantity > 0);
    return [...mainItems, ...selection.itemsIAQ, ...itemsAddOnWithQuantity];
});

export const selectShippingAddressStatus = createSelector(
    selectPreCart,
    (selection) => selection.shippingAddressCaptured
);
export const selectFirstSystemCriteria = createSelector(selectPreCart, (selection) => selection.firstSystemCriteria);

export const selectFirstSystemMain = createSelector(
    selectPreCartMain,
    (items) => items && items.find((item) => item.systemGroup === SystemGroup.SystemGroup1)
);
export const selectSecondSystemMain = createSelector(
    selectPreCartMain,
    (items) => items && items.find((item) => item.systemGroup === SystemGroup.SystemGroup2)
);
export const selectFirstSystemIAQ = createSelector(
    selectPreCartIAQ,
    (items) => items && items.find((item) => item.systemGroup === SystemGroup.SystemGroup1)
);
export const selectSecondSystemIAQ = createSelector(
    selectPreCartIAQ,
    (items) => items && items.find((item) => item.systemGroup === SystemGroup.SystemGroup2)
);
export const selectAddons = createSelector(
    selectPreCartAddon,
    (items) => items && items && items.filter((cartItem) => cartItem.quantity > 0)
);
export const selectCartItems = createSelector(
    selectFirstSystemMain,
    selectFirstSystemIAQ,
    selectSecondSystemMain,
    selectSecondSystemIAQ,
    selectAddons,
    (firstSystemMain, firstSystemIAQ, secondSystemMain, secondSystemIAQ, addOns) => {
        return [
            ...(firstSystemMain ? [firstSystemMain] : []),
            ...(firstSystemIAQ ? [firstSystemIAQ] : []),
            ...(secondSystemMain ? [secondSystemMain] : []),
            ...(secondSystemIAQ ? [secondSystemIAQ] : []),
            ...(addOns && addOns.length > 0 ? addOns : []),
        ];
    }
);

export const selectCombinedListInProgress = createSelector(selectPreCart, (selection) => {
    const mainItems = selection.items || [];
    const itemsAddOnWithQuantity = selection.itemsAddOn.filter((addOn) => addOn.quantity > 0);
    return [...mainItems, ...selection.itemsIAQ, ...itemsAddOnWithQuantity].map((item) => {
        return {
            name: item.name,
            systemGroup: item.systemGroup,
            itemType: item.itemType,
            price: item.price,
            quantity: item.quantity,
            productAttributes: {
                level: item.productAttributes.level,
            },
        };
    });
});
export const selectCombinedListWithSubtotal = createSelector(selectPreCart, selectTaxRate, (selection, taxRate) => {
    const mainItems = selection.items || [];
    let subtotal = 0;
    const itemsAddOnWithQuantity = selection.itemsAddOn.filter((addOn) => addOn.quantity > 0);
    return {
        items: [...mainItems, ...selection.itemsIAQ, ...itemsAddOnWithQuantity].map((item) => {
            subtotal += item.price * item.quantity;
            return {
                name: item.name,
                sku: item.sku,
                quantity: item.quantity,
                price: item.price,
                totalPrice: item.totalPrice || item.price * item.quantity,
                taxPercent: taxCalculation(100, taxRate),
                taxAmount:
                    taxCalculation(item.totalPrice, taxRate) || taxCalculation(item.price * item.quantity, taxRate),
                systemGroup: item.systemGroup,
                itemType: item.itemType,
            };
        }),
        subtotal: subtotal,
    };
});

export const selectSubTotal = createSelector(selectPreCart, selectTaxRate, (selection, taxRate) => {
    const mainItems = selection.items || [];
    let subtotal = 0;
    const itemsAddOnWithQuantity = selection.itemsAddOn.filter((addOn) => addOn.quantity > 0);
    [...mainItems, ...selection.itemsIAQ, ...itemsAddOnWithQuantity].forEach((item) => {
        subtotal += item.price * item.quantity;
    });
    return subtotal;
});

export const selectScheduleList = createSelector(selectPreCart, (selection) => selection.schedule);
