import React from "react";
import { Theme } from "@mui/material/styles";
import ContentContainer from "../../components/ContentContainer";
import WizardHeader from "../../components/WizardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { Heading } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import {
  DoubleSystemWizardState,
  NumberOfHVAC,
  ProductCriteria,
} from "../../models/productCriteria";
import LoadingOverlay from "../../components/common/LoadingOverlay";
import { useDispatch, useSelector } from "react-redux";
import { SystemGroup, ItemType } from "../../models/cart";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Container from "@mui/material/Container";
import { CartItem } from "../../models/cart";
import CartSummary from "../../components/CartSummary";
import { AppRoute } from "../../models/route";
import {
  selectProducts,
  selectProductsIAQ,
  selectSecondSysProducts,
} from "../../store/selectors/productSearch";
import { selectProductCriteriaState } from "../../store/selectors/productCriteria";
import CartItemDetail from "../../components/CartItemDetail";
import { SXCssObject } from "../../models/ThemeImages";
import { Button } from "../../components/Button";
import { getSystemType } from "../../components/common/Utilities";
import {
  setDoubleSystemFlag,
  storeFirstProductCriteria,
} from "../../store/slice/productCriteriaSlice";
import { useNavigate } from "react-router-dom";
import { changeProduct } from "../../store/slice/productSearchSlice";
import Footer from "../../components/common/Footer";
import AddressComponent from "../../components/AddressComponent";
import {
  contactInprogress,
  generateQuote,
  sendQuotations,
} from "../../store/slice/cartSlice";
import { EDIT_LABEL } from "../../components/common/constants";
import { selectScheduleList } from "../../store/selectors/selection";

const createCssObject = (theme: Theme): SXCssObject => ({
  contentContainer: {
    minHeight: `calc(100vh - ${theme.spacing(8)})`,
  },
  summary: {
    pt: {
      xs: "20px",
      sm: 0,
    },
  },
  header: {
    pt: {
      xs: "20px",
      sm: "40px",
    },
    pb: {
      xs: "20px",
      sm: "24px",
    },
    backgroundColor: {
      xs: theme.palette.common.white,
      sm: "inherit",
    },
  },
  contentArea: {
    backgroundColor: {
      xs: theme.palette.common.white,
      sm: "inherit",
    },
  },
  addOnContainer: {
    mt: {
      sm: "20px",
    },
  },
  dividerStyle: {
    border: "none",
    width: "100%",
    borderBottom: `1px solid ${theme.customProperties.borderDivider}`,
    opacity: "0.4",
    margin: "0px",
    display: {
      sm: "none",
    },
  },
  divider2: {
    border: "none",
    width: "100%",
    borderBottom: `1px solid ${theme.customProperties.borderDivider}`,
    opacity: "0.4",
    marginTop: "24px",
    marginBottom: "0px",
    display: {
      sm: "none",
    },
  },
  actions: {
    width: {
      md: "100%",
    },
    padding: {
      xs: "15px 5px 15px",
      md: "15px 0px",
    },
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.dark,
  },
  helpFooter: {
    backgroundColor: theme.palette.common.white,
    fontSize: "12px !important",
    p: {
      xs: theme.spacing(2, 0, 2, 0),
      sm: theme.spacing(3, 0, 1, 0),
    },
  },
  tabScrollControl: {
    overflowX: "auto",
    msOverflowStyle: "none" /* IE and Edge */,
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tabHeaderContainer: {
    display: "flex",
    width: {
      xs: "140%",
      sm: "80%",
      md: "66%",
      lg: "62%",
    },
    mb: {
      xs: "0px",
      md: "24px",
    },
    backgroundColor: {
      xs: theme.palette.background.default,
      sm: "inherit",
    },
    p: {
      xs: "8px 0px 8px 16px",
      sm: "inherit",
    },
    minWidth: {
      sm: "500px",
      lg: "1000px",
    },
  },
  tabSelected: {
    backgroundColor: theme.palette.common.white,
    p: {
      sm: "10px",
    },
  },
  tabNonSelected: {
    border: "1px solid #CCCCCC",
    backgroundColor: "#F5F5F5",
  },
  tabHeaderStyle: {
    width: "50%",
    cursor: "pointer",
    display: "flex",
    borderRadius: "4px",
    alignContent: "center",
    WebkitTapHighlightColor: "transparent",
    mr: {
      xs: "8px",
      sm: "inherit",
    },
  },
  tabHeadingContentSelected: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    outline: "none",
    border: `1px solid ${theme.palette.primary.dark}`,
    backgroundColor: theme.palette.primary.light,
    padding: "16px",
    borderRadius: "4px",
  },
  tabHeadingContentNonSelected: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    outline: "none",
    padding: "16px",
  },
  centerColumn: {
    display: "flex",
    justifyContent: "center",
    fontSize: "20px",
    flexDirection: "column",
    flex: 6,
  },
  tabheadingText: {
    fontWeight: theme.typography.fontWeightMedium,
    mt: "4px",
    color: theme.typography.h1.color,
    fontSize: {
      xs: "0.875rem",
      md: "1.125rem",
      lg: "1.25rem",
    },
  },
  tabSubheading: {
    mt: {
      xs: "6px",
      sm: "inherit",
    },
  },
  submitButton: {
    whiteSpace: "normal",
  },
});

interface Props {
  preCartItemsMain: CartItem[];
  preCartItemsIAQ: CartItem[];
  preCartItemsAddons: CartItem[];
  secondSystemWizard: any;
  criteria: ProductCriteria;
  loading: boolean;
}

function EquipmentSelection(props: Props) {
  const {
    preCartItemsMain,
    preCartItemsIAQ,
    preCartItemsAddons,
    loading,
    secondSystemWizard,
  } = props;
  const cssObject = createCssObject(useTheme());
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const isXsDown = useMediaQuery(theme.breakpoints.only("xs"));
  const firstSystemProducts = useSelector(selectProducts);
  const secondSystemProducts = useSelector(selectSecondSysProducts);
  //for setting the local state of IAQ when toggled
  const secondSystem =
    secondSystemWizard.state && secondSystemWizard.state.isSecondSystem;

  const [selectedTab, setSelectedTab] = React.useState(secondSystem ? 1 : 0);
  const productsIAQ = useSelector(selectProductsIAQ);
  const criteria = useSelector(selectProductCriteriaState);

  const scheduleList = useSelector(selectScheduleList);

  const getItemIAQ = () => {
    if (productsIAQ) {
      if (selectedTab === 0) {
        return preCartItemsIAQ.find(
          (item) => item.systemGroup === SystemGroup.SystemGroup1
        );
      } else {
        return preCartItemsIAQ.find(
          (item) => item.systemGroup === SystemGroup.SystemGroup2
        );
      }
    }
    return undefined;
  };
  const itemIAQ = getItemIAQ();

  const itemPrimary = (() => {
    if (preCartItemsMain && preCartItemsMain.length > 0) {
      if (selectedTab === 0) {
        return preCartItemsMain.find(
          (item) => item.systemGroup === SystemGroup.SystemGroup1
        );
      } else {
        return preCartItemsMain.find(
          (item) => item.systemGroup === SystemGroup.SystemGroup2
        );
      }
    }
  })();
  const shouldShowTab = () => {
    const itemMain = preCartItemsMain.filter(
      (item) => item.itemType === ItemType.MainSystem
    );
    return itemMain && itemMain.length > 1;
  };
  const shouldRedirectToSecondSystemFlow =
    !secondSystem &&
    criteria.numberOfHVAC === NumberOfHVAC.Double &&
    !shouldShowTab();

  const handleChangeIndex = (index: number, e: any) => {
    if (selectedTab === index) return;
    setSelectedTab(index);
  };

  // const isDuctless = () => {
  //   if (itemPrimary && itemPrimary.productAttributes) {
  //     return (
  //       itemPrimary.productAttributes.systemType
  //         .toLowerCase()
  //         .indexOf("ductless") !== -1
  //     );
  //   }
  //   return false;
  // };
  // const isMainProductIAQ = () => {
  //   if (itemPrimary && itemPrimary.productAttributes) {
  //     return (
  //       itemPrimary.productAttributes.systemType
  //         .toLowerCase()
  //         .indexOf("iaq") !== -1
  //     );
  //   }
  //   return false;
  // };

  const checkAndGetProducts = (sku: string) => {
    if (
      selectedTab === 0 &&
      firstSystemProducts &&
      firstSystemProducts.length > 0
    ) {
      dispatch(
        changeProduct({
          sku: sku,
          isChangeForSecondSystem: false,
          isByClickingChangeinSelection: sku,
          changingSystemGroup: SystemGroup.SystemGroup1,
        })
      );
    } else if (
      selectedTab === 1 &&
      secondSystemProducts &&
      secondSystemProducts.length > 0
    ) {
      dispatch(
        changeProduct({
          sku: sku,
          isChangeForSecondSystem: true,
          isByClickingChangeinSelection: sku,
          changingSystemGroup: SystemGroup.SystemGroup2,
        })
      );
    } else if (itemPrimary) {
      dispatch(
        changeProduct({
          sku: itemPrimary.sku,
          isChangeForSecondSystem: selectedTab === 1,
        })
      );
    }
  };
  const handleSubmit = () => {
    if (shouldRedirectToSecondSystemFlow) {
      dispatch(storeFirstProductCriteria(criteria));
      dispatch(
        setDoubleSystemFlag({
          doubleSystemFlag: DoubleSystemWizardState.Second,
        })
      );
      navigate(AppRoute.AboutYourHome, { state: { isSecondSystem: true } });
    } else if (scheduleList && scheduleList.length > 0) {
      navigate(AppRoute.ReviewSelection);
    } else {
      dispatch(contactInprogress());
      dispatch(sendQuotations());
      navigate(AppRoute.Schedule);
    }
  };
  const generateQuoteSubmit = () => {
    dispatch(generateQuote());
  };
  return (
    <ContentContainer disableGrid background={"bg2"}>
      <Box>
        <Box sx={cssObject.contentContainer}>
          <LoadingOverlay open={loading} />
          <WizardHeader
            showRestartButton={true}
            shadow={false}
            isFixed={true}
          />
          <Divider />
          <Container
            maxWidth={"lg"}
            style={{ paddingBottom: "24px" }}
            sx={{
              pt: {
                xs: "100px",
                md: "140px",
              },
            }}
          >
            <Grid container alignItems={"center"}>
              <Grid item xs={12} sm={9}>
                <Heading label={"Great choice! Time to finish up."} />
                <Paragraph sx={{ py: 2 }}>
                  The final step is to book your home inspection. We confirm you
                  have the right system, answer your questions, and learn about
                  any special circumstances. No worries if you need to change
                  something later.
                </Paragraph>
              </Grid>
            </Grid>
            <Box sx={{ py: 3 }}>
              <AddressComponent
                onClickHome={() =>
                  navigate(AppRoute.EditContact, {
                    state: {
                      fromPage: AppRoute.EquipmentSelection,
                      section: EDIT_LABEL.HOME_ADDRESS,
                    },
                  })
                }
                onClickBilling={() =>
                  navigate(AppRoute.EditContact, {
                    state: {
                      fromPage: AppRoute.EquipmentSelection,
                      section: EDIT_LABEL.BILLING_ADDRESS,
                    },
                  })
                }
              />
            </Box>
            <Grid container alignItems={"center"}>
              <Grid item xs={12} sm={12}>
                <Paragraph responsiveFontSize18 bold headingStyle>
                  Your Cart
                </Paragraph>
              </Grid>
            </Grid>
          </Container>

          <Container maxWidth={"lg"} style={{ paddingBottom: "100px" }}>
            {shouldShowTab() && (
              <Box sx={cssObject.tabScrollControl}>
                <ContentContainer disableGrid sx={cssObject.tabHeaderContainer}>
                  <Box
                    onClick={(e) => {
                      handleChangeIndex(0, e);
                    }}
                    sx={[
                      cssObject.tabHeaderStyle,
                      selectedTab === 0 && cssObject.tabSelected,
                      selectedTab === 1 && cssObject.tabNonSelected,
                    ]}
                  >
                    <Box
                      sx={[
                        selectedTab === 0 &&
                          cssObject.tabHeadingContentSelected,
                        selectedTab === 1 &&
                          cssObject.tabHeadingContentNonSelected,
                      ]}
                    >
                      <Box sx={cssObject.centerColumn}>
                        <Box component={"span"} sx={cssObject.tabSubheading}>
                          <Paragraph fontSizeVerySmall={isXsDown}>
                            Your First Replacement
                          </Paragraph>
                        </Box>
                        <Box sx={cssObject.tabheadingText}>
                          {getSystemType(
                            preCartItemsMain.find(
                              (item) =>
                                item.systemGroup === SystemGroup.SystemGroup1
                            )!,
                            0
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    onClick={(e) => {
                      handleChangeIndex(1, e);
                    }}
                    sx={[
                      cssObject.tabHeaderStyle,
                      selectedTab === 1 && cssObject.tabSelected,
                      selectedTab === 0 && cssObject.tabNonSelected,
                    ]}
                  >
                    <Box
                      sx={[
                        selectedTab === 1 &&
                          cssObject.tabHeadingContentSelected,
                        selectedTab === 0 &&
                          cssObject.tabHeadingContentNonSelected,
                      ]}
                    >
                      <Box sx={cssObject.centerColumn}>
                        <Box sx={cssObject.tabSubheading}>
                          <Paragraph fontSizeVerySmall={isXsDown}>
                            Your Second Replacement
                          </Paragraph>
                        </Box>
                        <Box sx={cssObject.tabheadingText}>
                          {getSystemType(
                            preCartItemsMain.find(
                              (item) =>
                                item.systemGroup === SystemGroup.SystemGroup2
                            )!,
                            1
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </ContentContainer>
              </Box>
            )}
            <Grid
              container
              spacing={isXsDown ? 0 : 2}
              justifyContent="space-between"
            >
              <Grid xs={12} md={8} lg={9} item>
                <Grid container spacing={isXsDown ? 0 : 2}>
                  {itemPrimary && itemPrimary.productAttributes && (
                    <Grid item sx={{ width: "100%" }}>
                      <CartItemDetail
                        item={itemPrimary}
                        onSelect={() => {
                          checkAndGetProducts(itemPrimary.sku);
                        }}
                      />
                    </Grid>
                  )}
                  {productsIAQ &&
                    productsIAQ.length > 0 &&
                    itemIAQ &&
                    itemIAQ.productAttributes && (
                      <Grid container item>
                        <CartItemDetail
                          item={itemIAQ}
                          onSelect={() => {
                            navigate(AppRoute.IAQOptions, {
                              state: {
                                systemGroup:
                                  selectedTab === 1
                                    ? SystemGroup.SystemGroup2
                                    : SystemGroup.SystemGroup1,
                                isSecondSystem:
                                  selectedTab === 1 ? true : false,
                                isByClickingChangeinSelection: true,
                              },
                            });
                          }}
                        />
                      </Grid>
                    )}
                  {/* {productsIAQ &&
                    productsIAQ.length > 0 &&
                    !itemIAQ &&
                    !isDuctless() &&
                    !isMainProductIAQ() && (
                      <Grid container item>
                        <CartItemDetail
                          onSelect={() => {
                            navigate(AppRoute.IAQOptions, {
                              state: {
                                systemGroup:
                                  selectedTab === 1
                                    ? SystemGroup.SystemGroup2
                                    : SystemGroup.SystemGroup1,
                                isSecondSystem:
                                  selectedTab === 1 ? true : false,
                                isByClickingChangeinSelection: true,
                              },
                            });
                          }}
                        />
                      </Grid>
                    )} */}
                </Grid>
                {/* {preCartItemsAddons && preCartItemsAddons.length > 0 && (
                  <Grid item sx={cssObject.addOnContainer}>
                    <AddOnProducts addOnList={preCartItemsAddons} />
                  </Grid>
                )} */}
              </Grid>
              {/* cart summary */}
              <Grid sx={cssObject.summary} item xs={12} md={4} lg={3}>
                <CartSummary
                  itemsMain={preCartItemsMain}
                  itemsIAQ={preCartItemsIAQ}
                  addOns={preCartItemsAddons}
                />
              </Grid>
              <Box component={"hr"} sx={cssObject.divider2} />
            </Grid>

            <ContentContainer disableGrid={!isXsDown}>
              <br />
            </ContentContainer>
            <ContentContainer background={"bg3"} disableGrid>
              <Box component={"hr"} sx={cssObject.dividerStyle} />
            </ContentContainer>
          </Container>
        </Box>

        <Grid sx={cssObject.actions}>
          <Container maxWidth={"lg"}>
            <Grid
              container
              justifyContent={"flex-end"}
              alignItems={"center"}
              spacing={2}
            >
              <Grid item xs={12} sm={6} md={3} justifyContent={"flex-end"}>
                <Button
                  id={`schedule-back-btn`}
                  isFullWidth={true}
                  outlinedWithBorder
                  onClick={generateQuoteSubmit}
                >
                  Generate quote
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={shouldRedirectToSecondSystemFlow ? 5 : 3}
                container
                justifyContent={"flex-end"}
              >
                <Button
                  sx={cssObject.submitButton}
                  id={`cart-continue-withtooltip-btn`}
                  color={"primary"}
                  onClick={handleSubmit}
                >
                  {shouldRedirectToSecondSystemFlow
                    ? "Continue with Another Replacement"
                    : scheduleList && scheduleList.length > 0
                    ? "CONTINUE FOR REVIEW"
                    : "CONTINUE"}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Footer />
      </Box>
    </ContentContainer>
  );
}
export default EquipmentSelection;
