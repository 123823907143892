import React from 'react';
import { useField } from 'formik';
import { TextField } from './TextField';

interface Props {
    id?: string;
    fullWidth?: boolean;
    name: string;
    label?: string;
    type?: string;
    placeholder?: string;
    enableErrorDisplay?: boolean;
    validating?: boolean;
    shouldDisableInputField?: boolean;
    showInfoMsgAlone?: boolean;
    onBlur?: (e: any) => void;
    onChange?: (e: any) => void;
    isTransparentBorderForInputField?: boolean;
    fontWeightIsMedium?: boolean;
    maxRows?: number | string;
    minRows?: number | string;
    multiLine?: boolean;
    onKeyDown?: (e: any) => void;
}

export default function FormikTextField(props: Props) {
    const {
        id,
        placeholder,
        fullWidth = true,
        name,
        label,
        shouldDisableInputField,
        isTransparentBorderForInputField,
        fontWeightIsMedium,
        onKeyDown,
        ...other
    } = props;
    const [field, meta] = useField(props);
    return (
        <TextField
            id={id ? id : `${name}-input`}
            label={label}
            fullWidth={fullWidth}
            onKeyDown={onKeyDown}
            isTransparentBorderForInputField={isTransparentBorderForInputField}
            placeholder={placeholder}
            fontWeightIsMedium={fontWeightIsMedium}
            isDisabled={shouldDisableInputField}
            error={Boolean(meta.error)}
            helperText={meta.error}
            {...other}
            {...field}
            onBlur={(e) => {
                props.onBlur && props.onBlur(e);
                field.onBlur(e);
            }}
            onChange={(e) => {
                props.onChange && props.onChange(e);
                field.onChange(e);
            }}
        />
    );
}
