import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  PersistConfig,
  createTransform,
} from "redux-persist";
import { History } from "history";
import storeSlice from "./slice/storeSlice";
import history from "../router/history";
import { createEpicMiddleware } from "redux-observable";
import { rootEpic } from "./epics";
import countrySlice from "./slice/countrySlice";
import selectionSlice from "./slice/selectionSlice";
import addressSlice from "./slice/addressSlice";
import productAttributesSlice from "./slice/productAttributesSlice";
import productCriteriaSlice from "./slice/productCriteriaSlice";
import userDetailsSlice from "./slice/userDetailsSlice";
import productSearchSlice from "./slice/productSearchSlice";
import taxRateSlice from "./slice/taxRateSlice";
import cartSlice from "./slice/cartSlice";

const ignoreLoadingTransform = createTransform<Object, Object>(
  (inboundState, key) => {
    let returnValue: any = {
      ...inboundState,
      loading: false,
    };
    if (key === "productSearch") {
      returnValue.isIAQSearchLoading = false;
    }
    return returnValue;
  },

  (outboundState, key) => {
    let returnValue: any = {
      ...outboundState,
      loading: false,
    };
    if (key === "productSearch") {
      returnValue.isIAQSearchLoading = false;
    }
    return returnValue;
  },
  {
    whitelist: [
      "store",
      "userDetails",
      "selection",
      "cart",
      "addressData",
      "productAttributes",
      "productCriteria",
      "productSearch",
      "taxRate",
    ],
  }
);

const persistConfig: PersistConfig<any> = {
  key: "fpl-web-portal",
  storage,
  blacklist: [
    "store",
    "country"
  ],
  // transforms: [ignoreLoadingTransform],
};

export interface Dependencies {
  // alert: SnackbarAlert;
  history: History;
}

const combinedReducers = combineReducers({
  store: storeSlice,
  country: countrySlice,
  userDetails: userDetailsSlice,
  selection: selectionSlice,
  cart: cartSlice,
  addressData: addressSlice,
  productAttributes: productAttributesSlice,
  productCriteria: productCriteriaSlice,
  productSearch: productSearchSlice,
  taxRate: taxRateSlice,
});

const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const makeStore = () => {
  const dependencies = {
    // alert,
    // router
    history,
  };
  const epicMiddleware: any = createEpicMiddleware<any, any, any, Dependencies>(
    { dependencies }
  );

  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(epicMiddleware),
  });

  const persistor = persistStore(store);
  epicMiddleware.run(rootEpic as any);
  return {
    store,
    persistor,
  };
};

export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<AppStore['getState']>
// export type AppDispatch = AppStore['dispatch']

export type RootState = ReturnType<typeof combinedReducers>;
