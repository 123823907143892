import React, { forwardRef, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import { useSnackbar, SnackbarContent } from 'notistack';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import _ from 'lodash';
import { Paragraph } from '../Paragraph';

const CustomSnackMessageUI = forwardRef<
    HTMLDivElement,
    { id: string | number; message: string | React.ReactNode; iconLeft: JSX.Element }
>((props, ref) => {
    const theme = useTheme();
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
        closeSnackbar(props.id);
    }, [props.id, closeSnackbar]);
    const Alert = (innerProps: AlertProps) => {
        return <MuiAlert elevation={2} icon={props.iconLeft} variant="filled" {...innerProps} />;
    };
    return (
        <SnackbarContent
            ref={ref}
            style={{
                [theme.breakpoints.up('sm')]: {
                    minWidth: '320px !important',
                },
                [theme.breakpoints.up('xl')]: {
                    minWidth: '360px !important',
                },
            }}
        >
            <Alert
                sx={{
                    width: '100%',
                    mt: {
                        xs: '55px',
                        sm: '67px',
                    },
                    borderRadius: {
                        xs: '20px',
                        sm: '4px',
                    },
                    backgroundColor: 'common.white',
                    color: 'text.primary',
                    '& .MuiAlert-icon': {
                        alignItems: 'center',
                    },
                    '& .MuiAlert-action': {
                        alignItems: 'center',
                        marginRight: '12px',
                        width: '32px',
                        paddingTop: '0px',
                        cursor: 'pointer',
                        '& svg': {
                            width: {
                                xs: '20px',
                                sm: '24px',
                            },
                            height: {
                                xs: '20px',
                                sm: '24px',
                            },
                        },
                    },
                }}
                onClose={handleDismiss}
            >
                {props.message}
            </Alert>
        </SnackbarContent>
    );
});

export const getSnackbarMsg = (boldText: string, text: string) => {
    return (
        <Paragraph >
            <Paragraph semibold component={'span'}>
                {_.truncate(boldText, {
                    'length': 40,
                    'separator': ' '
                })}
            </Paragraph>
            {text}
        </Paragraph>
    );
};

export default CustomSnackMessageUI;
