import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Box, Divider, Link, Stack, useMediaQuery } from "@mui/material";
import { SXCssObject } from "../models/ThemeImages";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import { FPL_STRESS_FREE_ROUTE } from "./common/constants";

const createCssObject = (theme: Theme): SXCssObject => ({
    link: {
        color: "common.white",
        fontSize: "14px",
      },
      socialLink:{
        display:'flex',
        alignItems:'center'
      },
      divider: {
        mx: 1,
        my: 0.5,
      },
});
const TopHeader = () => {
  const cssObject: SXCssObject = createCssObject(useTheme());
  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const isOnlySm = useMediaQuery(theme.breakpoints.only("sm"));
  return (
    <>
    <Box
          sx={{
            width: "100%",
            padding: isUpMd
              ? "5px 60px 5px 60px"
              : isOnlySm
              ? "5px 35px 5px 35px"
              : "5px 15px 5px 15px",
            boxShadow: "0px 40px 40px 0px #F5F5F5",
            display: "flex",
            justifyContent: "right",
            alignItems:'center',
            backgroundColor: theme.customProperties.headerFooter,
          }}
        >
          <Link href={FPL_STRESS_FREE_ROUTE.CUSTOMER_LOGIN} underline="hover" sx={cssObject.link}>
            Customer Login
          </Link>
          <Divider
            sx={cssObject.divider}
            orientation="vertical"
            variant="middle"
            flexItem
          />
          <Link href={FPL_STRESS_FREE_ROUTE.CONTRACTOR_LOGIN} underline="hover" sx={cssObject.link}>
            Contractor Login
          </Link>
          <Divider
            sx={cssObject.divider}
            orientation="vertical"
            variant="middle"
            flexItem
          />
          <Link href={FPL_STRESS_FREE_ROUTE.PHONE} underline="hover" sx={cssObject.link}>
            1-833-970-3579
          </Link>
          <Divider
            sx={cssObject.divider}
            orientation="vertical"
            variant="middle"
            flexItem
          />
          <Link href="#" underline="none" sx={[cssObject.link,{cursor:'none'}]}>
            CONNECT WITH US
          </Link>
          <Divider
            sx={cssObject.divider}
            orientation="vertical"
            variant="middle"
            flexItem
          />
          <Stack direction="row" alignItems={'center'} spacing={2}>
          <Link href={FPL_STRESS_FREE_ROUTE.FACEBOOK} underline="none" sx={cssObject.socialLink} target="_blank" rel="noopener">
            <FacebookOutlinedIcon sx={{ fontSize: "18px" }} color="secondary" />
          </Link>
          <Link href={FPL_STRESS_FREE_ROUTE.INSTAGRAM} underline="none" sx={cssObject.socialLink} target="_blank" rel="noopener">
            <InstagramIcon sx={{ fontSize: "18px" }} color="secondary" />
          </Link>
          <Link href={FPL_STRESS_FREE_ROUTE.TWITTER} target="_blank" rel="noopener" underline="none" sx={cssObject.socialLink}>
            <XIcon sx={{ fontSize: "18px" }} color="secondary" />
          </Link>
          </Stack>
        </Box>
    </>
  );
};
export default TopHeader;
