import React from 'react';
import { Theme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import CardMedia from '@mui/material/CardMedia';
import ContentContainer from './ContentContainer';
import { Button } from './Button';
import { Paragraph } from './Paragraph';
import striptags from 'striptags';
import { Box, Icon, useMediaQuery, useTheme } from '@mui/material';
import DeleteIconDisabledSvg from  "assets/icons/icon_delete_disabled.svg";
import DeleteIcon from './icons/DeleteIcon';
import { currencyFormat } from './common/NumberUtil';
import { SXCssObject } from '../models/ThemeImages';
import { LevelCart, tileColor } from './icons/Level';
import { CartItem, ItemType } from '../models/cart';

const createCssObject = (theme: Theme, props: Props): SXCssObject => ({
    root: {
        padding: '0px !important',
    },
    borderSelected: {
        border: `1px solid ${theme.palette.action.hover}`,
    },
    headerContainer: {
        p: { xs: '16px 8px 8px', sm: 2.4 },
    },

    levelInfo: {
        alignItems: 'center',
        display: 'block',
        textAlign: {
            xs: 'left',
            sm: 'inherit',
        },
        paddingLeft: {
            lg: theme.spacing(1.5),
        },
    },
    systemPrice: {
        textAlign: 'left',
        borderRight: '20px',
        paddingLeft: {
            lg: theme.spacing(8),
        },
        verticalAlign: {
            xs: 'middle',
            sm: 'inherit',
        },
        paddingBottom: {
            xs: theme.spacing(1),
            sm: 'inherit',
        },
    },
    buttonBlock: {
        paddingRight: {
            sm: theme.spacing(1),
            lg: theme.spacing(2.6),
        },
        textAlign: {
            xs: 'center',
            sm: 'right',
        },
        padding: {
            xs: '20px 16px 0px 16px',
            sm: 'inherit',
        },
    },
    buttonBlockNoIAQ: {
        paddingRight: {
            sm: theme.spacing(1),
            lg: theme.spacing(2.6),
        },
        textAlign: {
            xs: 'center',
            sm: 'right',
        },
        padding: {
            xs: '5px 10px 0px 10px',
            sm: 'inherit',
        },
    },
    productDetail: {
        p: { xs: '8px 8px 0px 8px', sm: '0px 24px 24px 24px' },
    },
    imageBox: {
        padding: {
            xs: '0px 7px 8px 7px',
            sm: 'inherit',
        },
    },
    img: {
        height: '96px',
        objectFit: 'contain',
        border: '1px solid #D4D4D4',
        borderRadius: '4px',
    },
    seer: {
        paddingTop: {
            xs: theme.spacing(1),
            sm: 'inherit',
        },
        paddingLeft: {
            xs: theme.spacing(0),
            sm: theme.spacing(1),
            lg: 'inherit',
        },
    },
    buttonSectionXsDown: {
        paddingBottom: '12px',
    },
    readMoreText: {
        color: theme.palette.success.main,
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        fontWeight: theme.typography.fontWeightMedium,
        paddingLeft: theme.spacing(0.3),
        '&:hover': {
            color: `${theme.palette.success.main} !important`,
        },
    },
    productDetailSection: {
        paddingTop: {
            xs: '12px',
            md: theme.spacing(1),
        },
    },
    features: {
        paddingTop: '5px',
        paddingLeft: {
            xs: '15px',
            sm: '20px',
        },
    },
    iaqFeatues: {
        paddingTop: '5px',
        paddingLeft: {
            xs: '10px',
            sm: '20px',
        },
    },
    fullWidth: {
        width: '100%',
    },

    dividerStyle: {
        margin: '16px 16px 4px',
        border: 'none',
        borderBottom: `1px solid ${theme.customProperties.borderDivider}`,
        opacity: '0.4',
        display: {
            sm: 'none',
        },
    },
    deleteIcon: {
        padding: '10px',
        background: 'white',
        cursor: 'pointer',
        border: '1px solid #CCCCCC',
        borderRadius: '4px',
        marginTop: '15px',
        marginRight: '10px',
    },
    deleteIconNew: {
        padding: '8px',
        background: 'white',
        width: 40,
        height: 40,
        cursor: 'pointer',
    },
    deleteIconDisabled: {
        padding: '10px',
        background: 'white',
        border: '1px solid #CCCCCC',
        borderRadius: '4px',
        marginRight: '10px',
    },
    deleteLargeIcon: {
        padding: '10px',
        background: 'white',
        cursor: 'pointer',
        marginRight: '3px',
    },
    deleteLargeIconDiabled: {
        padding: '10px',
        background: 'white',
        marginRight: '3px',
    },
    price: {
        fontFamily: theme.typography.body1.fontFamily,
        color: theme.palette.action.active,
        fontSize: {
            xs: '1.125rem',
            sm: 'inherit',
        },
    },
    checkBox: {
        display: 'flex',
        justifyItems: 'end',
        paddingRight: '5px',
    },

    pricesXsdown: {
        backgroundColor: tileColor(props.item ? props.item.productAttributes.level : 'Not Today'),
        borderRadius: '4px',
        padding: theme.spacing(1.5),
        margin: '10px 7px 0px 7px',
    },
    header: {
        alignItems: 'center',
        backgroundColor: {
            sm: tileColor(props.item ? props.item.productAttributes.level : 'Not Today'),
        },
        borderRadius: {
            sm: '4px',
        },
        padding: {
            xs: theme.spacing(0),
            sm: theme.spacing(1.2),
            lg: theme.spacing(1.6, 0),
        },
        paddingLeft: {
            xs: theme.spacing(0.5),
            sm: 'inherit',
        },
    },
});

interface Props {
    item?: CartItem;
    onSelect?: () => void;
    width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    showDelete?: boolean;
    onDelete?: () => void;
}

function CartItemDetail(props: Props) {
    const { item } = props;
    const productId = item && item.id;
    const itemLevel = item ? item.productAttributes.level : 'Not Today';
    const itemPrice = item && item.price;
    const itemSEER = item && item.productAttributes && item.productAttributes.seer2;
    const itemTonnage = item && item.productAttributes && item.productAttributes.tonnage;
    const itemEfficiency = item && item.productAttributes && item.productAttributes.efficiency;
    const itemDescription = item && item.description;
    const itemImage = item && item.image;
    const hpStages = item?.productAttributes.hpStages;
    const furnaceStages = item?.productAttributes.furnaceStages;
    const acStages = item?.productAttributes.acStages;
    const fanSpeeds = item?.productAttributes.fanSpeeds;
    const motorType = item?.productAttributes.motorType;
    const compressorWarranty = item?.productAttributes.compressorWarranty;
    const heatExchangerWarranty = item?.productAttributes.heatExchangerWarranty;
    const systemType = item && item.productAttributes && item.productAttributes.systemType;
    const [showFullText, setShowFullText] = React.useState(false);
    const iaqPreFilter = item?.productAttributes.iaqPreFilter;
    //Pre Filtration
    const iaqPrimaryFiltration = item?.productAttributes.iaqPrimaryFiltration;
    //Primary Filtration
    const iaqSecondaryFiltration = item?.productAttributes.iaqSecondaryFiltration;
    //Secondary Filtration
    const iaqUVLight = item?.productAttributes.uvLight;
    //Purifier
    const miniSplitTonnage = item?.productAttributes.miniSplitTonnage;
    const miniSplitMultiZoneIDUMatchup = item?.productAttributes.miniSplitMultiZoneIduMatchUp;
    const cssObject: SXCssObject = createCssObject(useTheme(), props);
    const theme = useTheme();
    const isExactlySm = useMediaQuery(theme.breakpoints.only('sm'));
    const isMdOnly = useMediaQuery(theme.breakpoints.only('md'));
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSmOrAbove = useMediaQuery(theme.breakpoints.up('sm'));
    let featureCount = 0;
    let featureSecondCount = 0;

    const componentId = productId ? productId : `product-detail-${itemLevel}`;
    const handleChange = () => {
        if (!!props.onSelect) {
            props.onSelect();
        }
    };
    const level = <LevelCart level={itemLevel} systemType={systemType} />;
    const descriptionLabel = (
        <Grid>
            <Paragraph size={'small'} semibold>
                <span style={{ color: theme.palette.action.active }}>Product Details</span>
            </Paragraph>
        </Grid>
    );
    const description = (trimLength: number) => (
        <Grid>
            {itemDescription && (
                <Paragraph fontSizeVerySmall={true} size={'small'} id={`description-level-${itemLevel}`}>
                    {trimLength ? striptags(itemDescription).slice(0, trimLength) + '  ' : striptags(itemDescription)}
                    {((isMdOnly && itemDescription.length > 150) || itemDescription.length > 318) &&
                        !!striptags(itemDescription) && (
                            <Box component={'span'} onClick={toggleFullText} sx={cssObject.readMoreText}>
                                {showFullText ? 'Read less' : 'Read more'}
                            </Box>
                        )}
                </Paragraph>
            )}
        </Grid>
    );

    const feature = (text: string) => {
        if (featureCount < 4) {
            featureCount++;
            return (
                <Grid container>
                    {/* <Grid item xs={1} justify={'flex-end'} sx={cssObject.checkBox}> */}
                    <Grid item xs={1} style={{ justifyContent: 'flex-end' }} sx={cssObject.checkBox}>
                        <Icon
                            style={{
                                color: theme.palette.success.main,
                                fontSize: '15px',
                                fontWeight: 900,
                                paddingTop: '1px',
                            }}
                        >
                            check_icon
                        </Icon>
                    </Grid>
                    <Grid item xs={11}>
                        <Paragraph fontSizeVerySmall={true} size={'small'} id={`description-level-${itemLevel}`}>
                            {text}
                        </Paragraph>
                    </Grid>
                </Grid>
            );
        } else return null;
    };
    const featureSecond = (text: string) => {
        if (featureSecondCount < 4) {
            featureSecondCount++;
            return (
                <Grid container>
                    {/* <Grid item xs={1} justify={'flex-end'} sx={cssObject.checkBox}> */}
                    <Grid item xs={1} style={{ justifyContent: 'flex-end' }} sx={cssObject.checkBox}>
                        <Icon
                            style={{
                                color: theme.palette.success.main,
                                fontSize: '15px',
                                fontWeight: 900,
                                paddingTop: '1px',
                            }}
                        >
                            check_icon
                        </Icon>
                    </Grid>
                    <Grid item xs={11}>
                        <Paragraph fontSizeVerySmall={true} size={'small'} id={`description-level-${itemLevel}`}>
                            {text}
                        </Paragraph>
                    </Grid>
                </Grid>
            );
        } else return null;
    };
    const price = itemPrice ? itemPrice : 0;

    const systemPrice = (
        <Paragraph semibold responsiveFontSize20>
            <Box component={'span'} sx={cssObject.price}>
                {currencyFormat.format(price)}
            </Box>
        </Paragraph>
    );
    const systemPriceLabel = (
        <Box display={'flex'} alignItems={'center'}>
            <Paragraph fontSizeVerySmall={true}>System Price </Paragraph>
        </Box>
    );

    const image = <CardMedia image={itemImage} component={'img'} sx={cssObject.img} />;
    const selectButton = (
        <Button
            id={`${componentId}-select-btn`}
            color="primary"
            isContrast={true}
            smallerPadding={isExactlySm}
            onClick={handleChange}
        >
            CHANGE
        </Button>
    );

    const toggleFullText = () => {
        setShowFullText(!showFullText);
    };
    const getTextBasedOnSize = () => {
        if (isXs) {
            return description(75);
        } else if (isMdOnly) {
            return description(125);
        } else {
            return description(0);
        }
    };
    const isDuctless = () => {
        if (item && item.itemType === ItemType.MainSystem && systemType) {
            return systemType.toLowerCase().indexOf('ductless') !== -1;
        }
        return false;
    };
    return (
      <ContentContainer disableGrid sx={cssObject.fullWidth}>
        <Container
          maxWidth={false}
          sx={cssObject.root}
          id={`card-level-${itemLevel}`}
        >
          <ContentContainer disableGrid background={"bg3"}>
            <Box sx={cssObject.headerContainer}>
              <Grid sx={cssObject.header} container>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={8}
                  container
                  sx={cssObject.levelInfo}
                >
                  {level}
                </Grid>

                <Hidden only={["xs"]}>
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={props.showDelete ? 3 : 4}
                      justifyContent={"flex-end"}
                      sx={cssObject.buttonBlock}
                    >
                      {!isDuctless() && selectButton}
                    </Grid>

                    {props.showDelete && (
                      <Grid item xs={1} container justifyContent={"center"}>
                        {itemLevel !== "Not Today" ? (
                          <Box
                            onClick={props.onDelete}
                            sx={cssObject.deleteIconNew}
                          >
                            <DeleteIcon />
                          </Box>
                        ) : (
                          <Box
                            component={"img"}
                            src={DeleteIconDisabledSvg}
                            sx={cssObject.deleteLargeIconDiabled}
                            alt={"Delete"}
                          />
                        )}
                      </Grid>
                    )}
                  </>
                </Hidden>
              </Grid>
              <Hidden smUp>
                {itemLevel !== "Not Today" && (
                  <Grid container>
                    <Grid
                      container
                      xs={12}
                      sm={3}
                      md={3}
                      sx={cssObject.pricesXsdown}
                    >
                      <Grid
                        item
                        xs={6}
                        container
                        direction={"column"}
                        alignItems="flex-start"
                      >
                        {systemPriceLabel}
                        {systemPrice}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Hidden>
            </Box>
          </ContentContainer>
          {itemLevel !== "Not Today" && (
            <ContentContainer
              disableGrid
              background={"bg3"}
              sx={cssObject.productDetail}
            >
              <Grid container>
                {itemImage && (
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    sx={cssObject.imageBox}
                    justifyContent={"center"}
                  >
                    {image}
                  </Grid>
                )}
                {systemType !== "IAQ" ? (
                  <Grid container item xs={12} sm={7} sx={cssObject.features}>
                    <Grid item xs={6}>
                      {systemType.toLowerCase().indexOf("ductless") !== -1 &&
                        miniSplitTonnage &&
                        miniSplitTonnage !== "--.-" &&
                        feature(`${miniSplitTonnage} BTU`)}
                      {systemType.toLowerCase().indexOf("ductless") !== -1 &&
                        miniSplitMultiZoneIDUMatchup &&
                        miniSplitMultiZoneIDUMatchup !== "--.-" &&
                        feature(`${miniSplitMultiZoneIDUMatchup} Zones`)}
                      {itemSEER && feature(`${itemSEER} SEER2`)}
                      {itemTonnage && feature(`${itemTonnage} Tonnage`)}
                      {itemEfficiency &&
                        itemEfficiency !== "N/A" &&
                        feature(`${itemEfficiency}% Efficiency`)}
                      {hpStages &&
                        hpStages !== "--.-" &&
                        feature(`${hpStages} Heat Pump`)}
                      {furnaceStages &&
                        furnaceStages !== "--.-" &&
                        feature(`${furnaceStages} Furnace`)}
                      {systemType.toLowerCase().indexOf("ductless") === -1 &&
                        miniSplitTonnage &&
                        miniSplitTonnage !== "--.-" &&
                        feature(`${miniSplitTonnage} BTU`)}
                      {systemType.toLowerCase().indexOf("ductless") === -1 &&
                        miniSplitMultiZoneIDUMatchup &&
                        miniSplitMultiZoneIDUMatchup !== "--.-" &&
                        feature(`${miniSplitMultiZoneIDUMatchup} Zones`)}
                    </Grid>
                    <Grid item xs={6}>
                      {acStages &&
                        acStages !== "--.-" &&
                        featureSecond(`${acStages} AC Condenser`)}
                      {fanSpeeds &&
                        fanSpeeds !== "--.-" &&
                        featureSecond(`${fanSpeeds} Blower`)}
                      {motorType &&
                        motorType !== "--.-" &&
                        featureSecond(`${motorType} Motor`)}
                      {compressorWarranty &&
                        compressorWarranty !== "--.-" &&
                        featureSecond(
                          `${compressorWarranty} Compressor Warranty`
                        )}
                      {heatExchangerWarranty &&
                        heatExchangerWarranty !== "--.-" &&
                        featureSecond(
                          `${heatExchangerWarranty} Heat Exchanger Warranty`
                        )}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={7} sx={cssObject.iaqFeatues}>
                    <Grid container alignItems="flex-start">
                      {iaqPreFilter &&
                        iaqPreFilter !== "--.-" &&
                        feature(`${iaqPreFilter}`)}
                      {iaqPrimaryFiltration &&
                        iaqPrimaryFiltration !== "--.-" &&
                        feature(`${iaqPrimaryFiltration}`)}
                      {iaqSecondaryFiltration &&
                        iaqSecondaryFiltration !== "--.-" &&
                        feature(`${iaqSecondaryFiltration} `)}
                      {iaqUVLight &&
                        iaqUVLight !== "--.-" &&
                        feature(`${iaqUVLight}`)}
                    </Grid>
                  </Grid>
                )}
                <Hidden only={["xs"]}>
                  <Grid item xs={12} sm={2} md={2}>
                    <Grid container direction={"column"} alignItems="flex-end">
                      {systemPriceLabel}
                      {systemPrice}
                    </Grid>
                  </Grid>
                </Hidden>
              </Grid>

              {isSmOrAbove && 
                <Grid container>
                  <Grid item xs={12} sx={cssObject.productDetailSection}>
                    {itemDescription && descriptionLabel}
                    {isMdOnly || isXs ? (
                      <div>
                        {showFullText ? description(0) : getTextBasedOnSize()}
                      </div>
                    ) : (
                      <div>
                        {showFullText ? description(0) : description(318)}
                      </div>
                    )}
                  </Grid>
                </Grid>
                }
            </ContentContainer>
          )}
          {isXs &&
              <Grid container sx={cssObject.buttonSectionXsDown}>
                <Grid
                  item
                  xs={props.showDelete ? 10 : 12}
                  sx={[
                    itemLevel !== "Not Today"
                      ? cssObject.buttonBlock
                      : cssObject.buttonBlockNoIAQ,
                  ]}
                >
                  {!isDuctless() && selectButton}
                </Grid>
                {props.showDelete && (
                  <Grid item xs={2} container justifyContent={"center"}>
                    {itemLevel !== "Not Today" ? (
                      <Box onClick={props.onDelete} sx={cssObject.deleteIcon}>
                        <DeleteIcon />
                      </Box>
                    ) : (
                      <Box
                        component={"img"}
                        src={DeleteIconDisabledSvg}
                        sx={cssObject.deleteIconDisabled}
                        alt={"Delete"}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
          }{" "}
          <Box component={"hr"} sx={cssObject.dividerStyle} />
        </Container>
      </ContentContainer>
    );
}

export default CartItemDetail;
