import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const createCssObject = (theme: Theme) => ({
    checkbox: {
        '&.MuiCheckbox-root': {
            color: theme.palette.action.hover,
        },
        '&.Mui-checked': {
            color: theme.palette.action.hover,
        },
    },
});

interface Props {
    id: string;
    label: string | React.ReactNode;
    checked: boolean;
    onChange: () => void;
    error?: boolean;
    enableErrorDisplay?: boolean;
    helperText?: string;
    fullWidth?: boolean;
}
export function CheckBox(props: Props) {
    const cssObject = createCssObject(useTheme());
    const { id, label, checked, onChange } = props;
    return (
        <FormControlLabel
            id={`${id}-label`}
            control={
                <Checkbox
                    id={`${id}-check-box`}
                    color={'primary'}
                    checked={checked}
                    onChange={onChange}
                    sx={cssObject.checkbox}
                />
            }
            label={label}
            labelPlacement={'end'}
        />
    );
}
