import { Theme } from "@mui/material";
import { AxiosRequestConfig } from "axios";
import { showSnackbarError } from "./Snackbar/SnackbarHelper";
import { CartItem, ItemType, SystemGroup } from "../../models/cart";
import { Region } from "../../models/country";
import Cookies from "js-cookie";
import _, { isEmpty, pickBy } from "lodash";

export const IS_ALIVE = "is_alive";
const CART_VALIDITY = 30; //Number of days, the cart will be stored in cookie

export const setCookieToTrackCartValidity = () => {
  let expiryDate: Date = new Date(
    new Date().getTime() + CART_VALIDITY * 24 * 60 * 60 * 1000
  );
  // @ts-ignore
  Cookies.set(IS_ALIVE, true, {
    expires: expiryDate,
  });
};

export const buildSelectedPackage = (cart) => {
  const mainSystemItem = _.filter(
    cart,
    (item) =>
      item.systemGroup === SystemGroup.SystemGroup1 &&
      item.itemType === ItemType.MainSystem
  )[0];
  return (
    mainSystemItem &&
    `${mainSystemItem.name} (${mainSystemItem.productAttributes.level}) - $${mainSystemItem.price}`
  );
};

export const extractErrorFromResponseOrShowDefaultMsg = (
  err: any,
  defaultMsg: string
) => {
  let msg = defaultMsg;
  try {
    if (err?.response?.data?.error?.message) {
      msg = err.response.data.error.message;
    } else if (err.message) {
      msg = err.message;
    }
  } catch (err) {
    return msg;
  }
  showSnackbarError(msg);
  return msg;
};

export const axiosRequestConfigForPdfDownload: AxiosRequestConfig = {
  responseType: "arraybuffer",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/pdf",
  },
};

export const getSystemType = (cartItem: CartItem, index: Number) => {
  const systemType =
    cartItem &&
    cartItem.productAttributes &&
    cartItem.productAttributes.systemType;
  if (systemType) {
    if (systemType?.indexOf("IAQ") !== -1) {
      return "Indoor Air Quality Package";
    } else if (systemType?.indexOf("Ductless") !== -1) {
      return "Ductless System";
    } else if (
      systemType === "AC & Coil Only" ||
      systemType === "Furnace Upgrade Only" ||
      systemType === "Furnace Only"
    ) {
      return "Partial HVAC";
    } else return "Complete HVAC";
  }
  return `System ${index}`;
};

export const isFromRoute = (route: string) => {
  // if (window.location.pathname === route) {
  //     return true;
  // } else {
  return false;
  // }
};

export const getThemePrimaryMain = (theme: Theme) => {
  return theme.customProperties.svgPrimaryColor;
};

export const getThemePrimaryLight = (theme: Theme) => {
  return theme.customProperties.svgPrimaryLightColor;
};

export const getThemeSecondaryMain = (theme: Theme) => {
  return theme.palette.secondary.main;
};
export const getThemeSvgPrimaryMain = (theme: Theme) => {
  return theme.palette.primary.main;
};

export const formatHubspotFormData = (hubspotFormData: any) => {
  return {
    firstname: hubspotFormData.firstname,
    lastname: hubspotFormData.lastname,
    email: hubspotFormData.email,
    workPhone: hubspotFormData.work_phone,
    mobilePhone: hubspotFormData.mobilephone,
    phone: hubspotFormData.phone,
    homePhone: hubspotFormData.home_phone,

    address: hubspotFormData.address,
    city: hubspotFormData.city,
    state: hubspotFormData.state_region__dropdown_,
    zip: hubspotFormData.zip,

    jobAddress: hubspotFormData.job_address,
    jobCity: hubspotFormData.job_city,
    jobZip: hubspotFormData.job_zip,
    jobState: hubspotFormData.job_state__stressfree_,
    dealId: "",
  };
};

export const getRegion = (state: string, regions?: Region[]) =>
  regions && regions.length > 0
    ? regions?.find(
        (region) => region.region === state || region.display === state
      )
    : {
        id: 1,
        region: state,
        display: state,
      };

export const removeEmpty = (obj: any) => pickBy(obj, (val) => !isEmpty(val));

