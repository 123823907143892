import { http } from "../..";
import { axiosRequestConfigForPdfDownload } from "../../components/common/Utilities";
import { generateQuotePayload, SchedulePayload, SendQuoteApiPayload } from "../../models/cart";

export const sendQuoteApi = (submitQuotePayload: SendQuoteApiPayload) =>
    http().post<any>(`productSelection/sendQuote`, submitQuotePayload);

export const scheduleInspectionApi = (schedulePayload: SchedulePayload) =>
    http().post<any>(`productSelection/schedule`, schedulePayload);

export const generateQuoteApi = (data: generateQuotePayload) =>
    http().post(`/productSelection/fpl/generateQuote`, { ...data }, axiosRequestConfigForPdfDownload as any);
