import * as React from 'react';

function CloseBlack() {
    return (
        <svg
            width="20px"
            height="20px"
            viewBox="0 0 20 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="s-5" transform="translate(-275.000000, -30.000000)" fill="#333333">
                    <g id="Group-12" transform="translate(265.000000, 20.000000)">
                        <g id="Group-4" transform="translate(10.000000, 10.000000)">
                            <g id="NEW---Icon-/-Close" transform="translate(2.000000, 2.000000)">
                                <path
                                    d="M9.2352021,8 L15.74417,1.48986794 C16.0852767,1.14876123 16.0852767,0.596936734 15.74417,0.255830029 C15.4030633,-0.0852766762 14.8500746,-0.0852766762 14.5089679,0.255830029 L8,6.7647979 L1.49103212,0.255830029 C1.14992542,-0.0852766762 0.596936734,-0.0852766762 0.255830029,0.255830029 C-0.0852766762,0.596936734 -0.0852766762,1.14876123 0.255830029,1.48986794 L6.76596209,8 L0.255830029,14.5089679 C-0.0852766762,14.8500746 -0.0852766762,15.4018991 0.255830029,15.7430058 C0.426965475,15.9141412 0.649325136,15.9991269 0.872848983,15.9991269 C1.09753702,15.9991269 1.31989668,15.9141412 1.49103212,15.7430058 L8,9.2352021 L14.5089679,15.7430058 C14.6801033,15.9141412 14.902463,15.9991269 15.127151,15.9991269 C15.3506749,15.9991269 15.5730345,15.9141412 15.74417,15.7430058 C16.0852767,15.4018991 16.0852767,14.8500746 15.74417,14.5089679 L9.2352021,8 Z"
                                    id="Fill-1"
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default CloseBlack;
