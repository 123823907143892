import { TaxDetailsResponse } from "../../models/userDetails";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clearData } from "./clearData";

interface State extends TaxDetailsResponse {
  loading: boolean;
}
const initialState: State = {
  loading: false,
};

const taxRateSlice = createSlice({
  name: "taxRateSlice",
  initialState: initialState,
  reducers: {
    getTaxRate: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    updateTaxData: (state, action: PayloadAction<TaxDetailsResponse>) => {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    },
    getTaxRateError: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    resetTaxRate: (state) => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearData, (state, action) => {
      return initialState;
    });
  },
});

export default taxRateSlice.reducer;
export const { getTaxRate, updateTaxData, getTaxRateError, resetTaxRate } =
  taxRateSlice.actions;
