import { Box, Dialog, DialogContent, Theme, useTheme } from "@mui/material";
import React, {  useEffect } from "react";
import { showSnackbarSuccess } from "../../components/common/Snackbar/SnackbarHelper";
import LoadingOverlay from "../../components/common/LoadingOverlay";
import { useDispatch } from "react-redux";
import { updateLeadInTakeFormStatus, updateProfileData } from "../../store/slice/userDetailsSlice";
import MuiDialogTitle from '@mui/material/DialogTitle';
import { SXCssObject } from "../../models/ThemeImages";
import ContentContainer from "../../components/ContentContainer";
import { Heading } from "../../components/Heading";
import { formatHubspotFormData, setCookieToTrackCartValidity } from "../../components/common/Utilities";
import { getTaxRate } from "../../store/slice/taxRateSlice";

const createCssObject = (theme: Theme): SXCssObject => ({
    modalContent: {
        display: 'flex',
        justifyContent: 'center',
        margin: '0px 0px 30px',
        height: 'auto',
        padding: {
            xs: '8px 24px',
            sm: '0px 24px 20px',
        },
    },

    modalPaper: {
        '& .MuiPaper-root': {
            background: theme.palette.common.white,
            borderRadius: '4px',
            margin: '24px',
            width: {
                xs: '80%',
                sm: '904px',
            },
            maxWidth: '904px',
            minWidth: {
                xs: '290px',
                sm: 'inherit',
            },
            minHeight: '300px'
        },
    },
    fullWidth: {
        width: '100%',
    },
    FormContainer: {
        padding: '16px',
        background: 'transparent linear-gradient(180deg, #F9F9F9 0%, #F7F7F7 100%) 0% 0% no-repeat padding-box'
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        mb: 2.5
    },
   
});

export const LeadIntakeForm = () => {
    const cssObject = createCssObject(useTheme())
    const [loading, setLoading] = React.useState(true);
    const portalId = process.env.REACT_APP_HUBSPOT_PORTAL_ID
    const formId = process.env.REACT_APP_HUBSPOT_LEAD_INTAKE_FORM; 
    const dispatch = useDispatch();
    const interceptHubSpotFormData = (event: any) => {
      if (
        event.data.type === "hsFormCallback" &&
        event.data.eventName === "onFormSubmit"
      ) {
        console.log("event.data", event.data);
        let  profileDetails = {};
        const data = event.data.data;
         data.forEach(field => profileDetails[field.name] = field.value);
         const profileDetailsToStoreInRedux = formatHubspotFormData(profileDetails);
         const jobZip = profileDetailsToStoreInRedux.jobZip;
         dispatch(getTaxRate({postalCode: jobZip}));
         dispatch(updateProfileData(profileDetailsToStoreInRedux));
      }
    };

    React.useEffect(() => {
      window.addEventListener("message", interceptHubSpotFormData);
      return () => {
        console.log("cleaning the event listener for form submission");
        window.removeEventListener("message", interceptHubSpotFormData);
      };
    }, []);

    const onFormReady = () => {
      setLoading(false);
    };
    const onFormSubmitted = () => {
      setLoading(false);
      showSnackbarSuccess("Lead intake is successful");
      dispatch(updateLeadInTakeFormStatus(false));
      setCookieToTrackCartValidity();
    };
    const onBeforeFormSubmit = () => {
      setLoading(true);
    };
   
    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(script);

     
        script.addEventListener('load', () => {
            // @ts-ignore
            if (window.hbspt) {
                // @ts-ignore
                window.hbspt.forms.create({
                    portalId: portalId,
                    formId: formId,
                    target: '#hubspotLeadIntakeFormElement',
                    onFormReady: function () {
                        onFormReady();
                    },
                    onBeforeFormSubmit: function () {
                        onBeforeFormSubmit();
                    },
                    onFormSubmitted: function () {
                        onFormSubmitted();
                    },
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Dialog
        aria-describedby="alert-dialog-slide-description"
        open={true}
        disableEscapeKeyDown={true}
        sx={cssObject.modalPaper}
        PaperProps={{
          borderRadius: "4px",
        }}
      >
        <MuiDialogTitle
          sx={{
            p: "24px",
            position: "relative",
          }}
        >
          <Heading label='Please fill the details' isPopUp/>
        </MuiDialogTitle>
        <DialogContent sx={cssObject.modalContent}>
          <ContentContainer disableGrid sx={cssObject.fullWidth}>
            <Box sx={cssObject.FormContainer}>
              <LoadingOverlay open={loading} />
              <div id="hubspotLeadIntakeFormElement"></div>
            </Box>
          </ContentContainer>
        </DialogContent>
      </Dialog>
    );
}