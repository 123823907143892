import * as React from "react"
import { SVGProps } from "react"
const SnackbarSuccess = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    data-name="Component 45 \u2013 1"
    {...props}
  >
    <circle cx={12} cy={12} r={12} fill="#c5ffda" data-name="Ellipse 9" />
    <path
      fill="none"
      stroke="#20a182"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.50035}
      d="m7.706 11.607 3.162 3.08 5.708-5.371"
      data-name="Path 19"
    />
  </svg>
)
export default SnackbarSuccess
