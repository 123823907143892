import * as Yup from 'yup';
import { StringSchema } from 'yup';
export enum EmailError {
    Invalid = 'Invalid email address',
    Required = 'Please enter your email',
    Domain = 'Please enter correct Domain email',
}

const postcodeRegex = /^[0-9]{5}$/;

export const EditContactValidationSchema = Yup.object().shape({
    firstname: Yup.string().required('Required').trim(),
    lastname: Yup.string().required('Required').trim(),
    email: Yup.string().email().required('Required').trim(),
    mobilePhone: Yup.string().required('Required').trim(),
    sameAddress: Yup.boolean(),

    address: Yup.string().when(['sameAddress'], {
        is: (sameAddress: boolean, schema: StringSchema) => {
            if (!sameAddress && schema) {
                return schema.required('select address');
            }
        },
        then: (schema: StringSchema) => schema.notRequired(),
    }),
    city: Yup.string().when(['sameAddress'], {
        is: (sameAddress: boolean, schema: StringSchema) => {
            if (!sameAddress && schema) {
                return schema.required('select city');
            }
        },
        then: (schema: StringSchema) => schema.notRequired(),
    }),
    zip: Yup.string().when(['sameAddress'], {
        is: (sameAddress: boolean, schema: StringSchema) => {
            if (!sameAddress && schema) {
                return schema
                    .length(5, 'Only 5 Characters')
                    .matches(postcodeRegex, 'Invalid Zip Code Characters')
                    .required('Required')
                    .trim();
            }
        },
        then: (schema: StringSchema) => schema.notRequired(),
    }),
    jobAddress: Yup.string().required('Required'),
    jobCity: Yup.string().required('Required'),
    jobZip: Yup.string()
        .length(5, 'Only 5 Characters')
        .matches(postcodeRegex, 'Invalid Zip Code Characters')
        .required('Required')
        .trim(),
});
