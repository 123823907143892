import React, { MouseEventHandler, PropsWithChildren } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';
import { SXCssObject, SXCssValueType } from '../models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        height: '40px',
        padding: theme.spacing(1, 2),
        minWidth: theme.spacing(10),
        width: {
            xs: '100%',
            sm: 'inherit',
        },
        whiteSpace: 'nowrap',
    },
    isSmallerFontForSmallerDevice: {
        fontSize: {
            xs: '12px',
            sm: 'inherit',
        },
    },
    isSmallerFont: {
        fontSize: '12px',
    },
    isSmallerButton: {
        height: '24px !important',
        fontSize: '12px',
    },
    fullWidth: {
        height: '40px',
        width: '100%',
        padding: theme.spacing(1, 2),
        whiteSpace: 'nowrap',
    },
    primary: {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.success.main,
        '&.Mui-disabled': {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.success.main,
            opacity: '0.4',
        },
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
        },
    },
    transparent: {
        backgroundColor: 'transparent !important',
        color: 'white',
        border: '1px solid white',
    },
    contrast: {
        color: theme.typography.h1.color,
        backgroundColor: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.success.main}`,
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        },
        '&.Mui-disabled': {
            color: theme.palette.secondary.light,
            backgroundColor: theme.palette.secondary.main,
            border: `1px solid ${theme.palette.success.main}`,
            opacity: '0.4'
        },
    },
    lightGrey: {
        color: '#4A4A4A',
        backgroundColor: '#EFEFEF',
        borderRadius: 4,
        fontSize: 14,
        textTransform: 'inherit',
        fontFamily: 'Roboto',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    primaryWithBorder: {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: `${theme.palette.common.white} !important`,
        border: `1px solid ${theme.palette.primary.main} !important`,
    },
    selectedSecondary: {
        color: `${theme.palette.common.white} !important`,
        backgroundColor: `${theme.palette.action.active} !important`,
    },
    smallerPadding: {
        padding: theme.spacing(1),
    },
    extraPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    outlinedWithBorder: {
        border: `1px solid ${theme.palette.success.main}`,
        background: theme.palette.common.white,
        color: theme.palette.text.primary,
        '&:hover': {
            backgroundColor: theme.palette.success.light,
        },
        '&.Mui-disabled': {
            color: theme.palette.success.light,
            backgroundColor: theme.palette.action.disabled,
        },
    },
    filledButton:{
        background:theme.palette.primary.main,
        color:theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            border: 'none'
        },
    }
});

interface Props {
    id?: string;
    color?: 'primary';
    onClick?: MouseEventHandler;
    disabled?: any;
    isFullWidth?: any;
    isSmallerButton?: any;
    isTransparent?: any;
    isContrast?: any;
    isLightGrey?: any;
    isPrimaryWithBorder?: any;
    isSmallerFontForSmallerDevice?: any;
    isSmallerFont?: any;
    selectedSecondary?: any;
    smallerPadding?: any;
    homePageLargeButton?: any;
    sx?: SXCssValueType;
    outlinedWithBorder?: any;
    largeButton?: any;
    filledButton?:any;
}

export function Button(props: PropsWithChildren<Props>) {
    const cssObject = createCssObject(useTheme());
    const {
        children,
        color,
        isFullWidth,
        isTransparent,
        isSmallerFontForSmallerDevice,
        isSmallerFont,
        isSmallerButton,
        isPrimaryWithBorder,
        isContrast,
        isLightGrey,
        selectedSecondary,
        smallerPadding,
        homePageLargeButton,
        outlinedWithBorder,
        largeButton,
        filledButton,
        sx,
        ...other
    } = props;
    const rootStyle = isFullWidth ? cssObject.fullWidth : cssObject.root;
    return (
        <MuiButton
            disableElevation
            sx={[
                rootStyle,
                color === 'primary' && cssObject.primary,
                isSmallerFontForSmallerDevice === true && cssObject.isSmallerFontForSmallerDevice,
                isSmallerFont === true && cssObject.isSmallerFont,
                isSmallerButton === true && cssObject.isSmallerButton,
                isTransparent === true && cssObject.transparent,
                isContrast === true && cssObject.contrast,
                isLightGrey === true && cssObject.lightGrey,
                isPrimaryWithBorder === true && cssObject.primaryWithBorder,
                selectedSecondary === true && cssObject.selectedSecondary,
                smallerPadding && cssObject.smallerPadding,
                homePageLargeButton && cssObject.extraPadding,
                largeButton && cssObject.extraPadding,
                outlinedWithBorder === true && cssObject.outlinedWithBorder,
                filledButton === true && cssObject.filledButton,
                sx !== undefined && sx,
            ]}
            variant={'contained'}
            color={color}
            {...other}
        >
            {children}
        </MuiButton>
    );
}
