import React from 'react';
import { useField } from 'formik';
import SelectInput from './SelectInput';

interface Props {
    id: any;
    name: string;
    options: any;
    label: string;
    enableErrorDisplay?: boolean;
    fullWidth?: boolean;
    helperText?: string;
    errorMsg?: string;
    errorIcon?: JSX.Element;
    onValueChange?: (item: any)=> void;
    zIndex?:number;
}

export default function FormikSelectField(props: Props) {
    const [, meta, { setValue }] = useField(props);
    return (
        <SelectInput
            onSelect={(v) => {
                props.onValueChange && props.onValueChange(v);
                setValue(v)}}
            value={meta.value}
            {...props}
        />
    );
}
