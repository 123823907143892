import React, { PropsWithChildren } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SXCssObject, SXCssValueType } from '../models/ThemeImages';

interface Props {
    id?: string;
    color?: 'primary' | 'secondary';
    size?: 'normal' | 'small';
    italic?: boolean;
    bold?: boolean;
    semibold?: boolean;
    fontColor?: boolean;
    fontSizeMediumSmall?: boolean;
    responsiveFontSize16?: boolean;
    responsiveFontSize18?: boolean;
    responsiveFontSize22?: boolean;
    responsiveFontSize20?: boolean;
    fontSizeVerySmall?: boolean;
    semiLargeFont?: boolean;
    largeFont?: boolean;
    headingColor?: boolean;
    headingStyle?: boolean;
    component?: 'p' | 'div' | 'span';
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    noWrap?: boolean;
    sx?: SXCssValueType;
}

const createCssObject = (theme: Theme): SXCssObject => ({
    italic: {
        fontStyle: 'italic',
    },
    semibold: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    bold: {
        fontWeight: 700,
    },
    color: {
        color: '#848484',
    },
    fontSizeVerySmall: {
        fontSize: '0.75rem',
        lineHeight: '1.1875rem',
    },
    fontSizeMediumSmall: {
        fontSize: '0.875rem',
        lineHeight: '26px',
    },
    responsiveFontSize16: {
        fontSize: {
            xs: '0.875rem',
            sm: '16px',
        },
        lineHeight: {
            xs: '22px',
            sm: '26px',
        },
    },
    responsiveFontSize18: {
        fontSize: {
            xs: '0.875rem',
            sm: '1rem',
            md: '1.125rem',
        },
        lineHeight: {
            xs: '1.375rem',
            sm: '1.625rem',
            md: '1.625rem',
        },
    },
    responsiveFontSize20: {
        fontSize: {
            xs: '1rem',
            sm: '1.125rem',
            md: '1.25rem',
        },
        lineHeight: {
            xs: '1.375rem',
            sm: '1.625rem',
            md: '1.75rem',
        },
    },
    responsiveFontSize22: {
        fontSize: {
            xs: '1.125rem',
            sm: '1.25rem',
            md: '1.375rem',
        },
        lineHeight: {
            xs: '1.375rem',
            sm: '1.625rem',
            md: '2rem',
        },
    },
    semiLargeFont: {
        fontSize: '1rem',
        lineHeight: '1.25rem',
    },
    largeFont: {
        fontSize: '1.125rem',
        lineHeight: '1.375rem',
    },
    headingStyle: {
        fontFamily: theme.typography.h1.fontFamily,
        color: theme.typography.h1.color,
    },
    headingColor: {
        color: theme.typography.h1.color,
    },
    fontColor: {
        color: (props: any) => props.fontColor,
    },
});
export function Paragraph(props: PropsWithChildren<Props>) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const {
        italic = false,
        size = 'small',
        fontSizeVerySmall,
        fontSizeMediumSmall,
        responsiveFontSize16,
        responsiveFontSize18,
        responsiveFontSize20,
        responsiveFontSize22,
        color = 'primary',
        children,
        bold,
        semibold,
        fontColor,
        largeFont,
        semiLargeFont,
        headingStyle,
        headingColor,
        component = 'p',
        noWrap,
        sx,
        ...other
    } = props;
    return (
        <Typography
            variant={size === 'normal' ? 'body1' : 'body2'}
            color={color === 'primary' ? 'textPrimary' : 'textSecondary'}
            component={component}
            noWrap={noWrap}
            sx={[
                italic === true && cssObject.italic,
                bold === true && cssObject.bold,
                semibold === true && cssObject.semibold,
                fontColor === true && cssObject.color,
                fontSizeMediumSmall === true && cssObject.fontSizeMediumSmall,
                responsiveFontSize18 === true && cssObject.responsiveFontSize18,
                responsiveFontSize16 === true && cssObject.responsiveFontSize16,
                responsiveFontSize20 === true && cssObject.responsiveFontSize20,
                responsiveFontSize22 === true && cssObject.responsiveFontSize22,
                fontSizeVerySmall === true && cssObject.fontSizeVerySmall,
                largeFont === true && cssObject.largeFont,
                headingStyle === true && cssObject.headingStyle,
                headingColor === true && cssObject.headingColor,
                semiLargeFont === true && cssObject.semiLargeFont,
                sx !== undefined && sx,
            ]}
            {...other}
        >
            {children}
        </Typography>
    );
}
