import * as React from "react"
const Restart = (props:any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={27} {...props}>
    <title>{"Group"}</title>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#333"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path d="M18.215 8.792a7.687 7.687 0 1 0 2.14 6.742" />
      <path d="M19.01 5.908v3.68h-3.68" />
    </g>
  </svg>
)
export default Restart
