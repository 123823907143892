import { Box, Container, Grid } from "@mui/material";
import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import ContentContainer from "../../components/ContentContainer";
import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
import ArrowRightAltIcon from "../../assets/icons/icon_right.svg";
import { SXCssObject } from "../../models/ThemeImages";
import {
  selectPreCartMain,
  selectScheduleList,
} from "../../store/selectors/selection";
import { Button } from "../../components/Button";
import WizardHeader from "../../components/WizardHeader";
import { Heading } from "../../components/Heading";
import ProductsSection from "./ProductSection";
import ScheduleSection from "./ScheduleSection";
import ProfileDetails from "./ProfileDetails";
import Footer from "../../components/common/Footer";
import AddressComponent from "../../components/AddressComponent";
import {
  getDealByEmail,
  getDealService,
} from "../../store/services/contactServices";
import { selectUserProfileDealId, selectUserProfileEmail } from "../../store/selectors/userDetails";
import { resetUserDetails, updateProfileData } from "../../store/slice/userDetailsSlice";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "../../models/route";
import { placeOrder, resetCart } from "../../store/slice/cartSlice";
import LoadingOverlay from "../../components/common/LoadingOverlay";
import { selectCartLoading } from "../../store/selectors/cartSelector";
import { EDIT_LABEL, FPL_STRESS_FREE_ROUTE } from "../../components/common/constants";
import { buildSelectedPackage } from "../../components/common/Utilities";
import { CartItem } from "../../models/cart";
import { showSnackbarSuccess } from "../../components/common/Snackbar/SnackbarHelper";
import { resetTaxRate } from "../../store/slice/taxRateSlice";
import { resetSelection } from "../../store/slice/selectionSlice";
import { resetMainProductSearch } from "../../store/slice/productSearchSlice";
import { clearWizarQuestionData } from "../../store/slice/productCriteriaSlice";

const createCssObject = (theme: Theme): SXCssObject => ({
  headerAndBody: {
    pointerEvents: "auto",
    opacity: 1,
  },
  contentStyle: {
    minHeight: `calc(100vh - ${theme.spacing(8.3)})`,
  },
  arrowRightAltIcon: {
    width: "20px",
    height: "20px",
    margin: "0px -5px 0px 8px",
    position: "relative",
  },
  buttonLabel: {
    display: "flex",
    justifyContent: {
      xs: "center",
      sm: "space-between",
    },
    width: "100%",
    alignItems: "center",
  },
});

function ReviewSelection() {
  const theme = useTheme();
  const cssObject: SXCssObject = createCssObject(useTheme());
  const scheduleList = useSelector(selectScheduleList);
  const preCartItemsMain = useSelector(selectPreCartMain);
  const profileEmail = useSelector(selectUserProfileEmail);
  const loading = useSelector(selectCartLoading);
  const userProfileDealId = useSelector(selectUserProfileDealId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getDeal = async () => {
    const result = await getDealByEmail(profileEmail);
    if (result?.data) {
      const getDealId = await getDealService(result?.data?.lastDealId);
      if (getDealId) {
        dispatch(updateProfileData(getDealId.data));
      }
    }
  };

  React.useEffect(() => {
    getDeal();
  }, []);

  const handlePlaceOrder = () => {
    dispatch(placeOrder(scheduleList));
    // setTimeout(() => {
    //   dispatch(resetUserDetails())
    //   dispatch(resetTaxRate())
    //   // @ts-ignore
    //   dispatch(resetSelection())
    //   dispatch(resetMainProductSearch())
    //   // @ts-ignore
    //   dispatch(clearWizarQuestionData())
    //   dispatch(resetCart())
    //   showSnackbarSuccess("Your order has been submitted");
    //   setTimeout(() => {
    //     window.location.replace(FPL_STRESS_FREE_ROUTE.HOME);
    //   },1000);
    // }, 1000);
  };

  async function updateDeal(preCartMain: CartItem[], userProfileDealId: number) {
    const selectedPackage = buildSelectedPackage(preCartMain);
    // @ts-ignore
    return await updateDealProduct(profileDealId, selectedPackage);
  }
  return (
    <ContentContainer disableGrid background={"bg2"} sx={cssObject.root}>
      <LoadingOverlay open={loading || false} />
      <Box sx={[cssObject.contentContainer, cssObject.contentRoot]}>
        <WizardHeader shadow={false} showRestartButton={true} isFixed={true} />
        <ContentContainer disableGrid>
          <Container
            maxWidth={"lg"}
            sx={{
              pb: "40px",
              pt: {
                xs: "100px",
                md: "140px",
              },
            }}
          >
            <Grid container spacing={0}>
              <Grid container spacing={0} alignItems={"center"}>
                <Grid item xs={12} sm={12}>
                  <Heading label="Review Details" />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box sx={{ py: 3 }}>
                    <AddressComponent
                      onClickHome={() =>
                        navigate(AppRoute.EditContact, {
                          state: {
                            fromPage: AppRoute.ReviewSelection,
                            section: EDIT_LABEL.HOME_ADDRESS,
                          },
                        })
                      }
                      onClickBilling={() =>
                        navigate(AppRoute.EditContact, {
                          state: {
                            fromPage: AppRoute.ReviewSelection,
                            section: EDIT_LABEL.BILLING_ADDRESS,
                          },
                        })
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <ProfileDetails />
            {preCartItemsMain && preCartItemsMain.length > 0 && (
              <ProductsSection />
            )}
            {/* section4 */}
            {scheduleList && scheduleList.length > 0 && <ScheduleSection />}
          </Container>
        </ContentContainer>
      </Box>

      <Box
        style={{
          width: "100%",
          background: theme.palette.secondary.dark,
        }}
      >
        <ContentContainer
          disableGrid
          sx={{
            width: "100%",
          }}
        >
          <Container maxWidth={"lg"}>
            <Box
              sx={{
                py: 1.5,
              }}
              display={"flex"}
              justifyContent="end"
            >
              <Button
                id={"confirmation-btn"}
                color={"primary"}
                // disabled={isDisable}
                onClick={handlePlaceOrder}
                largeButton
              >
                <Box component={"span"} sx={cssObject.buttonLabel}>
                  {"Submit"}
                  <Box
                    component={"img"}
                    src={ArrowRightAltIcon}
                    alt="right arrow"
                    sx={cssObject.arrowRightAltIcon}
                  />
                </Box>
              </Button>
            </Box>
          </Container>
        </ContentContainer>
      </Box>
      <Footer />
    </ContentContainer>
  );
}

export default ReviewSelection;
