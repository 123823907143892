import React from 'react';
import { Theme } from '@mui/material/styles';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { RECOMMENDATION_FEATURES_ABOVE_TAB, RECOMMENDATION_FEATURES_MOBILE } from './Utilities';
import LimitedEquipmentWarranty from '../../components/icons/LimitedEquipmentWarranty';
import FullInstallation from '../../components/icons/FullInstallation';
import Efficiency from '../../components/icons/Efficiency';
import TonCapacity from '../../components/icons/TonCapacity';
import { Button } from '../../components/Button';
import { Paragraph } from '../../components/Paragraph';
import { Product } from '../../models/product';
import { SXCssObject } from '../../models/ThemeImages';
const createCssObject = (theme: Theme): SXCssObject => ({
    featuresBox: {
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: {
            xs: '120px',
            sm: 'inherit',
        },
        padding: {
            xs: '0px 5px',
            sm: 'inherit',
        },
    },
    featuresArrayBox: {
        borderRadius: '4px',
        display: 'flex',
        padding: {
            xs: '5px',
            sm: 'inherit',
        },
    },
    featuresContainer: {
        paddingBottom: '15px',
    },
    featuresBoxTextContainer: {
        padding: '12px',
        width: '100%',
        borderRadius: '4px',
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        height: {
            xs: '140px',
            sm: '90px',
        },
    },
    featuresBoxTextContainerHeader: {
        width: '100%',
        borderRadius: '4px',
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        height: {
            xs: '140px',
            sm: '90px',
        },
        padding: {
            xs: '12px 0px',
            sm: '10px',
        },
    },
    largeGrid: {
        height: '290px !important',
        margin: '1px',
    },
    featuresBoxText: {
        backgroundColor: theme.palette.primary.light,
        padding: '8px 16px',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        lineHeight: '25px !important',
        height: {
            xs: '70px',
            md: '100%',
        },
        textAlign: {
            xs: 'center',
            sm: 'inherit',
        },
        justifyContent: {
            xs: 'left',
            sm: 'inherit',
        },
        '& p': {
            lineHeight: '25px',
        },
    },
    hideLayout: {
        display: 'none',
    },
});
interface Props {
    products: Product[];
}
export default function EquipmentFeature(props: Props) {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const { products } = props;
    const cssObject: SXCssObject = createCssObject(useTheme());
    const [isExpanded, setIsExpanded] = React.useState(false);
    const listToShowAboveTab = [...RECOMMENDATION_FEATURES_ABOVE_TAB];
    let listToShowMobile = [...RECOMMENDATION_FEATURES_MOBILE];
    const product = products[0];
    const efficiencyValues: number[] = [];
    let efficiency = '';
    if (
        product.productAttributes &&
        product.productAttributes.efficiency &&
        product.productAttributes.efficiency.toString() !== 'N/A'
    ) {
        products.map((product) => efficiencyValues.push(parseInt(product.productAttributes.efficiency)));
        if (
            efficiencyValues &&
            efficiencyValues.length > 0 &&
            efficiencyValues.sort()[0] === efficiencyValues.sort()[efficiencyValues.length - 1]
        ) {
            efficiency = efficiencyValues.sort()[0].toString();
        } else {
            efficiency = efficiencyValues.sort()[0].toString() + '+';
        }
    }

    const mobileLayout = () => {
        if (product.productAttributes) {
            if (product.productAttributes.tonnage) {
                const obj = {
                    heading: `${product.productAttributes.tonnage} Ton`,
                    paragraph: 'Capacity',
                    icon: TonCapacity,
                };
                listToShowMobile = [obj, ...listToShowMobile];
            }
            if (efficiency) {
                const obj = {
                    heading: `${efficiency} %`,
                    paragraph: 'Efficient Furnace',
                    icon: Efficiency,
                };
                listToShowMobile = [obj, ...listToShowMobile];
            }
        }
        const efficiencyIcon = listToShowMobile[0];
        const tonCapacityIcon = listToShowMobile[1];
        const extendedIcon = listToShowMobile[2];
        const nohiddenIcon = listToShowMobile[3];
        return (
            <Grid container style={{ minWidth: '101%' }}>
                <Grid item xs={12} sx={cssObject.featuresBox}>
                    <Box
                        sx={cssObject.featuresBoxTextContainerHeader}
                        style={{ backgroundColor: theme.customProperties.transparent }}
                    >
                        <Box sx={cssObject.featuresBoxText}>
                            <Paragraph semibold responsiveFontSize22 align={'left'}>
                                All of These Systems Include
                            </Paragraph>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6} sx={cssObject.featuresArrayBox}>
                    <Grid
                        container
                        direction={'column'}
                        alignContent={'center'}
                        sx={cssObject.featuresBoxTextContainer}
                    >
                        <Box>
                            {/* <FullWarranty /> */}
                        </Box>
                        <Box>
                            <Paragraph semiLargeFont semibold headingColor align={'center'}>
                                1-Year
                            </Paragraph>
                            <Paragraph align={'center'} fontSizeVerySmall>
                                Full Labor Warranty
                            </Paragraph>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={6} sx={cssObject.featuresArrayBox}>
                    <Grid
                        container
                        direction={'column'}
                        alignContent={'center'}
                        sx={cssObject.featuresBoxTextContainer}
                    >
                        <Box>
                            <LimitedEquipmentWarranty />
                        </Box>
                        <Box>
                            <Paragraph semiLargeFont semibold headingColor align={'center'}>
                                10-Year
                            </Paragraph>
                            <Paragraph align={'center'} fontSizeVerySmall>
                                Limited Equipment Warranty
                            </Paragraph>
                        </Box>
                    </Grid>
                </Grid>

                <Grid
                    item
                    sx={[
                        !isExpanded && cssObject.hideLayout,
                        {
                            width: '100%',
                        },
                    ]}
                >
                    <Grid container>
                        <Grid item xs={6} container sx={cssObject.featuresArrayBox}>
                            <Grid
                                container
                                direction={'column'}
                                sx={cssObject.featuresBoxTextContainer}
                                style={{ marginBottom: '5px' }}
                            >
                                <Box>
                                    <efficiencyIcon.icon />
                                </Box>
                                <Box>
                                    <Paragraph semiLargeFont semibold headingColor align={'center'}>
                                        {listToShowMobile[0].heading}
                                    </Paragraph>
                                    <Paragraph align={'center'} fontSizeVerySmall>
                                        {listToShowMobile[0].paragraph}
                                    </Paragraph>
                                </Box>
                            </Grid>
                            <Grid
                                direction={'column'}
                                sx={cssObject.featuresBoxTextContainer}
                                container
                                style={{ marginTop: '5px' }}
                            >
                                <Box>
                                    <tonCapacityIcon.icon />
                                </Box>
                                <Box>
                                    <Paragraph semiLargeFont semibold headingColor align={'center'}>
                                        {listToShowMobile[1].heading}
                                    </Paragraph>
                                    <Paragraph align={'center'} fontSizeVerySmall>
                                        {listToShowMobile[1].paragraph}
                                    </Paragraph>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item sx={cssObject.featuresArrayBox} xs={6}>
                            <Grid
                                container
                                direction={'column'}
                                sx={[cssObject.featuresBoxTextContainer, cssObject.largeGrid]}
                            >
                                <Box>
                                    <FullInstallation />
                                </Box>
                                <Box>
                                    <Paragraph semiLargeFont semibold headingColor align={'center'}>
                                        Full Installation
                                    </Paragraph>
                                    <Paragraph fontSizeVerySmall align={'center'}>
                                        Including equipment, necessary parts, labor and disposal
                                    </Paragraph>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    {listToShowMobile.length >= 3 && (
                        <Grid container>
                            <Grid item xs={6} sx={cssObject.featuresArrayBox}>
                                <Grid
                                    container
                                    direction={'column'}
                                    alignContent={'center'}
                                    sx={cssObject.featuresBoxTextContainer}
                                >
                                    <Box>
                                        <extendedIcon.icon />
                                    </Box>
                                    <Box>
                                        <Paragraph semiLargeFont semibold headingColor align={'center'}>
                                            {listToShowMobile[2].heading}
                                        </Paragraph>
                                        <Paragraph align={'center'} fontSizeVerySmall>
                                            {listToShowMobile[2].paragraph}
                                        </Paragraph>
                                    </Box>
                                </Grid>
                            </Grid>

                            {listToShowMobile.length >= 4 && (
                                <Grid item xs={6} sx={cssObject.featuresArrayBox}>
                                    <Grid
                                        container
                                        direction={'column'}
                                        alignContent={'center'}
                                        sx={cssObject.featuresBoxTextContainer}
                                    >
                                        <Box>
                                            <nohiddenIcon.icon />
                                        </Box>
                                        <Box>
                                            <Paragraph semiLargeFont semibold headingColor align={'center'}>
                                                {listToShowMobile[3].heading}
                                            </Paragraph>
                                            <Paragraph align={'center'} fontSizeVerySmall>
                                                {listToShowMobile[3].paragraph}
                                            </Paragraph>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Grid>

                <Grid item xs={12} style={{ paddingTop: '16px' }}>
                    <Button
                        isFullWidth={true}
                        isContrast={true}
                        onClick={() => {
                            setIsExpanded(!isExpanded);
                        }}
                    >
                        {isExpanded ? 'SHOW LESS' : 'SHOW MORE'}
                    </Button>
                </Grid>
            </Grid>
        );
    };

    if (isXs) {
        return mobileLayout();
    } else {
        if (product.productAttributes) {
            if (efficiency) {
                const obj = {
                    heading: `${efficiency} %`,
                    paragraph: 'Efficient Furnace',
                    icon: Efficiency,
                };
                listToShowAboveTab.splice(2, 0, obj);
            }
            if (product.productAttributes.tonnage) {
                const obj = {
                    heading: `${product.productAttributes.tonnage} Ton`,
                    paragraph: 'Capacity',
                    icon: TonCapacity,
                };
                listToShowAboveTab.splice(0, 0, obj);
            }
        }
        return (
            <Grid container spacing={1} style={{ marginTop: '16px', minWidth: isSm ? '101.5%' : '101%' }}>
                <Grid item sm={6} md={4} lg={3} sx={cssObject.featuresBox}>
                    <Grid sx={cssObject.featuresBoxTextContainerHeader}>
                        <Box sx={cssObject.featuresBoxText}>
                            <Paragraph semibold responsiveFontSize22 headingColor>
                                All of these systems include
                            </Paragraph>
                        </Box>
                    </Grid>
                </Grid>
                {listToShowAboveTab.map((item: any, i) => (
                    <Grid key={i} item sm={6} md={4} lg={3} sx={cssObject.featuresArrayBox}>
                        <Grid
                            container
                            justifyContent={'space-evenly'}
                            alignContent={'center'}
                            sx={cssObject.featuresBoxTextContainer}
                        >
                            <Grid item>
                                <item.icon />
                            </Grid>
                            <Grid item xs={9}>
                                <Paragraph semibold responsiveFontSize22 headingColor>
                                    {item.heading}
                                </Paragraph>
                                <Paragraph fontSizeVerySmall>{item.paragraph}</Paragraph>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        );
    }
}
