import React from "react";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import {
  BackButton,
  CloseButton,
  RestartButton,
  HelpButton,
} from "./WizardButton";
import ContentContainer from "./ContentContainer";
import { Logo, WizardLogoNewWithLink } from "./Logo";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import { Button } from "./Button";
import { Paragraph } from "./Paragraph";
import { Box, Link, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Heading } from "./Heading";
import { SXCssObject } from "../models/ThemeImages";
import { useNavigate } from "react-router-dom";
import { resetSelection } from "../store/slice/selectionSlice";
import { resetProductCriteria } from "../store/slice/productCriteriaSlice";
import { AppRoute } from "../models/route";
import { resetProductSearch } from "../store/slice/productSearchSlice";
import TopHeader from "./TopHeader";
import MenuDrawer from "./MenuDrawer";
import { FPL_STRESS_FREE_ROUTE } from "./common/constants";

const useStyles = (theme: Theme, props: Props): SXCssObject => ({
  root: {
    width: "100%",
    height: "80px",
    padding: {
      xs: "20px 15px 20px 15px",
      sm: "20px 35px 20px 35px",
      md: "20px 60px 20px 60px",
    },
    position: props.isFixed ? "fixed" : "unset",
    boxShadow: props.shadow ? "0px 40px 40px 0px #F5F5F5" : "0px",
    zIndex: 5,
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
  },
  dialog: {
    height: "100%",
  },
  dialogContainer: {
    minHeight: "100vh",
    width: "100%",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  header: {
    height: "80px",
  },
  link: {
    color: "common.black",
  },
});

interface Props {
  color?: "primary" | "secondary";
  showBackButton?: true | false;
  showRestartButton?: true | false;
  disableGrid?: true | false;
  isFixed?: true | false;
  shadow?: true | false;
  onHelp?: () => void;
  onBack?: () => void;
  isForSelectionPage?: boolean;
  productCriteria?: () => void;
  showExitButton?: true | false;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function WizardHeader(props: Props) {
  const classes: SXCssObject = useStyles(useTheme(), props);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    showBackButton = false,
    showRestartButton = true,
    showExitButton = true,
  } = props;
  const componentId = "wizard-header";
  const [confirm, setConfirm] = React.useState(false);
  const [action, setAction] = React.useState("");
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down("md"));
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const isOnlySm = useMediaQuery(theme.breakpoints.only("sm"));

  const [confirmMsg, setConfirmMsg] = React.useState("");
  const exitPopUp = () => {
    setAction("close");
    setConfirmMsg("Are you sure you want to exit?");
    setConfirm(true);
  };
  const handleContactUsWindow = () => {
    // ToBeCheck
    // window.open('/contact-us', '_blank');
  };
  return (
    <>
      <nav
        style={{
          width: "100%",
          position: props.isFixed ? "fixed" : "unset",
          boxShadow: props.shadow ? "0px 40px 40px 0px #F5F5F5" : "0px",
          zIndex: 5,
          display: "flex",
          flexDirection: "column",
          backgroundColor: theme.palette.common.white,
        }}
      >
        {isUpMd && <TopHeader />}
        <Box
          sx={{
            padding: isUpMd
              ? "10px 60px 10px 60px"
              : isOnlySm
              ? "10px 35px 10px 35px"
              : "10px 15px 10px 15px",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Grid container spacing={2} alignItems={"center"} sx={classes.header}>
            <Grid item xs={1} sm={2} lg={4} sx={{ display: "flex" }}>
              <a rel="noopener noreferrer" href={FPL_STRESS_FREE_ROUTE.HOME}>
                {isDownSm ? (
                  <Logo id={"nav-logo"} />
                ) : (
                  <WizardLogoNewWithLink id={"nav-home"} />
                )}
              </a>
            </Grid>
            <Grid item container xs={11} sm={10} lg={8}>
              <Grid
                item
                container
                justifyContent={"flex-end"}
                alignItems={"center"}
                spacing={2}
              >
                {isUpMd && (
                  <Grid item>
                    <Stack direction="row" alignItems={"center"} spacing={2}>
                      <Link
                        href={FPL_STRESS_FREE_ROUTE.HOME}
                        underline="hover"
                        sx={classes.link}
                      >
                        Home
                      </Link>
                      <Link
                        href={FPL_STRESS_FREE_ROUTE.ABOUT}
                        underline="hover"
                        sx={classes.link}
                      >
                        About
                      </Link>
                      <Link
                        href={FPL_STRESS_FREE_ROUTE.BENEFITS}
                        underline="hover"
                        sx={classes.link}
                      >
                        Benefits
                      </Link>
                      <Link
                        href={FPL_STRESS_FREE_ROUTE.BLOGS}
                        underline="hover"
                        sx={classes.link}
                      >
                        Blogs
                      </Link>
                      <Link
                        href={FPL_STRESS_FREE_ROUTE.FAQS}
                        underline="hover"
                        sx={classes.link}
                      >
                        FAQs
                      </Link>
                      <Link
                        href={FPL_STRESS_FREE_ROUTE.PROMOS}
                        underline="hover"
                        sx={classes.link}
                      >
                        Promos
                      </Link>
                    </Stack>
                  </Grid>
                )}
                {!!props.onHelp && (
                  <Grid item>
                    <HelpButton
                      id={`${componentId}-back-btn`}
                      onClick={handleContactUsWindow}
                    />
                  </Grid>
                )}
                {showBackButton && !!props.onBack && (
                  <Grid item>
                    <BackButton
                      id={`${componentId}-back-btn`}
                      onClick={props.onBack}
                    />
                  </Grid>
                )}
                {showRestartButton && (
                  <Grid item>
                    <RestartButton
                      id={`${componentId}-restart-btn`}
                      onClick={() => {
                        setAction("restart");
                        setConfirmMsg("Are you sure you want to restart?");
                        setConfirm(true);
                      }}
                    />
                  </Grid>
                )}
                {showExitButton && (
                  <Grid item>
                    <CloseButton
                      id={`${componentId}-close-btn`}
                      onClick={exitPopUp}
                    />
                  </Grid>
                )}
                {isDownSm && (
                  <Grid item>
                    <MenuDrawer />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Dialog
          fullScreen
          open={confirm}
          onClose={() => {
            setConfirm(false);
          }}
          TransitionComponent={Transition}
        >
          <ContentContainer disableGrid background={"bg2"} sx={classes.dialog}>
            <Grid
              container
              direction={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              spacing={2}
              sx={classes.dialogContainer}
            >
              <Grid item xs={12}>
                <Heading variant={"h4"} align={"center"} label={confirmMsg} />
                <br />
                <Paragraph align={"center"}>
                  Your progress will be lost.
                </Paragraph>
              </Grid>
              <Grid
                item
                xs={12}
                container
                spacing={2}
                justifyContent={"center"}
              >
                <Grid item>
                  <Button
                    id={"confirm-yes-btn"}
                    isContrast
                    onClick={() => {
                      if (action === "restart") {
                        // @ts-ignore
                        dispatch(resetSelection());
                        dispatch(resetProductCriteria());
                        //dispatch(resetOrder());
                        if (props.productCriteria) {
                          props.productCriteria();
                        }
                        setConfirm(false);
                        navigate(AppRoute.AboutYourHome);
                      } else if (action === "close") {
                        // @ts-ignore
                        dispatch(resetSelection());
                        dispatch(resetProductCriteria());
                        dispatch(resetProductSearch());
                        //dispatch(resetOrder());
                        // setConfirm(false);
                        // dispatch(clearData());
                        // navigate(AppRoute.AboutYourHome);
                        window.location.replace(FPL_STRESS_FREE_ROUTE.HOME)
                      }
                    }}
                  >
                    Yes
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    id={"confirm-no-btn"}
                    color={"primary"}
                    onClick={() => {
                      setConfirm(false);
                    }}
                  >
                    No
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </ContentContainer>
        </Dialog>
      </nav>
    </>
  );
}
