import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from '../common/Utilities';

export default function DeleteIcon(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg
            id="Component_44_192"
            data-name="Component 44 – 192"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g id="Rectangle_627" data-name="Rectangle 627" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="24" height="24" stroke="none" />
                <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
            </g>
            <g id="Group_482" data-name="Group 482" transform="translate(2.728 2.298)">
                <g id="Group_481" data-name="Group 481" transform="translate(0 0)">
                    <path
                        id="Union_22"
                        data-name="Union 22"
                        d="M5.766,19.285a3.6,3.6,0,0,1-3.624-3.674c-.01-2.839,0-5.678,0-8.516V6.415c-.291,0-.563,0-.84,0A1.246,1.246,0,0,1,0,5.187,1.221,1.221,0,0,1,1.3,3.966l3.647,0c0-.421,0-.84,0-1.262,0-.46,0-.922,0-1.379A1.209,1.209,0,0,1,4.991.985,1.238,1.238,0,0,1,6.277,0q3.137-.01,6.281,0a1.232,1.232,0,0,1,1.287,1.008,1.24,1.24,0,0,1,.04.309c.006,1.433.006,1.712,0,2.636h3.669A1.225,1.225,0,0,1,18.829,5.17a1.241,1.241,0,0,1-1.283,1.245H16.7c0,2.32.007,7.117,0,9.212a3.47,3.47,0,0,1-2.071,3.312,4.223,4.223,0,0,1-1.569.346q-1.822.015-3.644.015T5.766,19.285ZM10.64,16.84l2.276,0A1.192,1.192,0,0,0,14.24,15.5c0-2.051,0-6.8,0-9.085H10.638C10.643,8.159,10.644,14.108,10.64,16.84ZM4.967,6.419c-.112,0-.225.01-.378.017v5.911c0,1.048,0,2.1,0,3.146a1.194,1.194,0,0,0,1.35,1.344l2.249,0c0-2.113,0-6.777,0-7.651,0-.925,0-1.85,0-2.77l-2.147,0C5.683,6.417,5.325,6.417,4.967,6.419Zm6.469-2.457c0-.422,0-.84,0-1.262V2.457l-.45,0c-.527,0-1.048,0-1.569,0s-1.048,0-1.569,0l-.448,0c0,.566,0,.894,0,1.505h4.041Z"
                        transform="translate(0 0)"
                        fill={themePrimaryMain}
                        stroke="rgba(0,0,0,0)"
                        strokeWidth="1"
                    />
                </g>
            </g>
        </svg>
    );
}
