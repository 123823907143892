import { ProfileDetails } from "../../models/userDetails";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clearData } from "./clearData";

const initialState: {profile: ProfileDetails, shouldShowLeadIntakeForm: boolean} = {
  profile: {},
  shouldShowLeadIntakeForm: true
};

const userDetailsSlice = createSlice({
  name: "userDetailsSlice",
  initialState: initialState,
  reducers: {
    updateLeadInTakeFormStatus: (state,action: PayloadAction<boolean> ) => {
      return {
        ...state,
        shouldShowLeadIntakeForm: action.payload
      }
    },
    updateProfileData: (state, action: PayloadAction<ProfileDetails>) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        }
      };
    },
    resetUserDetails: (state) => {
      return {
        ...initialState
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearData, (state, action) => {
      return initialState;
    })
  },
});

export const { updateProfileData,updateLeadInTakeFormStatus, resetUserDetails } = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
