import { combineEpics, Epic } from "redux-observable";
import { Action } from "ts-action";
import { ofType } from "ts-action-operators";
import { Observable, of } from "rxjs";
import {
  switchMap,
  map,
  catchError,
  withLatestFrom,
  ignoreElements,
  tap,
} from "rxjs/operators";

import { Dependencies, RootState } from "../index";
import { isEmpty } from "lodash";
import {
  generateQuote,
  generateQuoteError,
  moveToHome,
  placeOrder,
  placeOrderError,
  placeOrderSuccess,
  proceedToDownloadQuote,
  sendQuotations,
  submitQuoteError,
  submitQuoteSuccess,
} from "../slice/cartSlice";
import {
  selectUserProfile,
  selectUserProfileDealId,
} from "../selectors/userDetails";
import {
  selectFirstSystemCriteria,
  selectProductCriteriaState,
} from "../selectors/productCriteria";
import { selectTaxRate } from "../selectors/taxRate";
import { NumberOfHVAC, ProductCriteria } from "../../models/productCriteria";
import { Contact } from "../../models/contact";
import { taxCalculation } from "../../components/common/NumberUtil";
import {
  buildSelectedPackage,
  formatHubspotFormData,
  removeEmpty,
} from "../../components/common/Utilities";
import {
  generateQuoteApi,
  scheduleInspectionApi,
  sendQuoteApi,
} from "../services/cartServices";
import {
  selectCombinedListWithSubtotal,
  selectPreCartAddon,
  selectPreCartIAQ,
  selectPreCartMain,
  selectSubTotal,
} from "../selectors/selection";
import {
  CartItem,
  Schedule,
  ScheduleResp,
  SendQuoteApiPayload,
} from "../../models/cart";
import {
  showSnackbarError,
  showSnackbarSuccess,
} from "../../components/common/Snackbar/SnackbarHelper";
import { updateDealProduct } from "../services/contactServices";
import { AxiosResponse } from "axios";
import { FPL_STRESS_FREE_ROUTE } from "../../components/common/constants";
import { clearData } from "../slice/clearData";

export const scheduleEpic$: Epic = (
  action$: Observable<Action>,
  state$: Observable<RootState>
) =>
  action$.pipe(
    ofType(placeOrder),
    withLatestFrom(
      state$.pipe(map(selectUserProfile)),
      state$.pipe(map(selectCombinedListWithSubtotal)),
      state$.pipe(map(selectFirstSystemCriteria)),
      state$.pipe(map(selectProductCriteriaState)),
      state$.pipe(map(selectTaxRate)),
      state$.pipe(map(selectPreCartMain)),
      state$.pipe(map(selectUserProfileDealId))
    ),
    switchMap(
      ([
        action,
        profileDetails,
        selectedItems,
        fistSystemCriteria,
        productCriteria,
        taxRate,
        preCartMain,
        userProfileDealId,
      ]) =>
        invokePlaceOrder(
          profileDetails,
          selectedItems,
          action.payload,
          fistSystemCriteria,
          productCriteria,
          taxRate,
          preCartMain,
          userProfileDealId
        ).pipe(
          map((resp) => {
            return resp.data && placeOrderSuccess(resp.data)
          }),
          catchError((error) => {
            if (error) {
              // showSnackbarError("Unable to Schedule Inspection");
            }
            return of(placeOrderError(error));
          })
        )
    )
  );

export const redirectToHomeandReset$ = (
  action$: Observable<Action>,
  state$: Observable<RootState>,
  { history }: Dependencies
) =>
  action$.pipe(
    ofType(placeOrderSuccess),
    switchMap((action) => {
      showSnackbarSuccess('Your order has been submitted')
      return [clearData(), moveToHome()];
    }),
  );

export const moveToHome$: Epic = (
  action$: Observable<Action>,
  state$: Observable<RootState>,
  { history }: Dependencies
) =>
  action$.pipe(
    ofType(moveToHome),
    tap(() => {
      window.location.replace(FPL_STRESS_FREE_ROUTE.HOME);
    }),
    ignoreElements()
  );

export const submitQuoteEpic$: Epic = (
  action$: Observable<Action>,
  state$: Observable<RootState>,
  { history }: Dependencies
) =>
  action$.pipe(
    ofType(sendQuotations),
    withLatestFrom(
      state$.pipe(map(selectUserProfile)),
      state$.pipe(map(selectCombinedListWithSubtotal)),
      state$.pipe(map(selectFirstSystemCriteria)),
      state$.pipe(map(selectProductCriteriaState)),
      state$.pipe(map(selectTaxRate))
    ),
    switchMap(
      ([
        action,
        contactDetails,
        selectedItems,
        fistSystemCriteria,
        productCriteria,
        taxRate,
      ]) =>
        invokeSendQuote(
          contactDetails,
          selectedItems,
          fistSystemCriteria,
          productCriteria,
          taxRate
        ).pipe(
          switchMap((resp) => {
            return [submitQuoteSuccess()];
          }),
          catchError((error) => {
            return of(submitQuoteError(error));
          })
        )
    )
  );

const generateQuoteEpic$: Epic = (
  action$: Observable<Action>,
  state$: Observable<RootState>
) =>
  action$.pipe(
    ofType(generateQuote),
    withLatestFrom(
      state$.pipe(map(selectUserProfile)),
      state$.pipe(map(selectPreCartMain)),
      state$.pipe(map(selectPreCartIAQ)),
      state$.pipe(map(selectPreCartAddon)),
      state$.pipe(map(selectFirstSystemCriteria)),
      state$.pipe(map(selectProductCriteriaState)),
      state$.pipe(map(selectSubTotal)),
      state$.pipe(map(selectTaxRate))
    ),
    switchMap(
      ([
        action,
        contactDetails,
        preCartMain,
        preCartIAQ,
        preCartAddon,
        fistSystemCriteria,
        productCriteria,
        subTotal,
        taxRate,
      ]) => {
        const generateQuoteData = {
          email: contactDetails.email,
          priorityId: "",
          searchCriteria: productCriteriaPayload(
            fistSystemCriteria,
            productCriteria
          ),
          ...(preCartMain && { items: preCartMain }),
          ...(preCartIAQ && { itemsIAQ: preCartIAQ }),
          ...(preCartAddon && { itemsAddOn: preCartAddon }),
          prices: getCartPrice(subTotal, taxRate),
          contactDetails: contactDetails,
        };
        return generateQuoteApi(generateQuoteData).pipe(
          map((response: AxiosResponse<SendQuoteApiPayload>) =>
            proceedToDownloadQuote(response.data)
          ),
          catchError((err) => {
            return of(generateQuoteError(err));
          })
        );
      }
    )
  );

const downloadQuotePdf$ = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(proceedToDownloadQuote),
    tap((action) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([action.payload]));
      link.setAttribute("download", `HVAC System Quote.pdf`);
      document.body.appendChild(link);
      link.click();
    }),
    ignoreElements()
  );

export default combineEpics(
  scheduleEpic$,
  submitQuoteEpic$,
  generateQuoteEpic$,
  downloadQuotePdf$,
  redirectToHomeandReset$,
  moveToHome$
);

function invokePlaceOrder(
  contactDetails: Contact,
  selectedItems: { items: any; subtotal: number },
  scheduleList: Schedule[],
  fistSystemCriteria: ProductCriteria,
  productCriteria: ProductCriteria,
  taxRate?: number,
  preCartMain?: CartItem[],
  profileDealId?: number
) {
  const schedulePayload = {
    email: contactDetails.email,
    imeWorkOrderId: "",
    dealId: contactDetails.dealId,
    searchCriteria: productCriteriaPayload(fistSystemCriteria, productCriteria),
    items: selectedItems.items,
    prices: getCartPrice(selectedItems.subtotal, taxRate),
    schedules: scheduleList,
    priorityId: "",
    addPurifier: "",
    notes: "",
    contactDetails: {
      firstname: contactDetails?.firstname,
      lastname: contactDetails?.lastname,
      email: contactDetails?.email,
      phone: contactDetails?.phone,
      homePhone: contactDetails?.homePhone,
      workPhone: contactDetails?.workPhone,
      mobilePhone: contactDetails?.mobilePhone,
      address: contactDetails?.address,
      city: contactDetails?.city,
      state: contactDetails?.state,
      zip: contactDetails?.zip,
      jobAddress: contactDetails?.jobAddress,
      jobCity: contactDetails?.jobCity,
      jobState: contactDetails?.jobState,
      jobZip: contactDetails?.jobZip,
    },
    taxPercentage: contactDetails?.taxRate?.taxRate,
    preferredContactMethod: "",
    wizardFormDetails: "",
  };
  updateDeal(preCartMain, profileDealId);
  return scheduleInspectionApi(schedulePayload);
}

async function updateDeal(preCartMain?: CartItem[], profileDealId?: number) {
  const selectedPackage = buildSelectedPackage(preCartMain);
  // @ts-ignore
  return await updateDealProduct(profileDealId, selectedPackage);
}

function getCartPrice(subtotal: number, taxRate?: number) {
  return {
    subtotalExcludingTax: subtotal,
    subtotalIncludingTax: subtotal + taxCalculation(subtotal, taxRate),
    subtotalDiscountExcludingTax: subtotal,
    totalTax: taxCalculation(subtotal, taxRate),
    grandTotal: subtotal + taxCalculation(subtotal, taxRate),
  };
}
function productCriteriaPayload(
  fistSystemCriteria: ProductCriteria,
  productCriteria: ProductCriteria
) {
  if (
    !isEmpty(fistSystemCriteria) &&
    productCriteria.numberOfHVAC === NumberOfHVAC.Double
  ) {
    delete productCriteria.doubleSystemFlag;
    return {
      firstReplacement: removeEmpty(fistSystemCriteria),
      secondReplacement: removeEmpty(productCriteria),
    };
  } else {
    return removeEmpty(productCriteria);
  }
}
function invokeSendQuote(
  contactDetails: Contact,
  selectedItems: { items: any; subtotal: number },
  fistSystemCriteria: ProductCriteria,
  productCriteria: ProductCriteria,
  taxRate?: number
) {
  const sendQuoteRequest = {
    email: contactDetails.email,
    searchCriteria: productCriteriaPayload(fistSystemCriteria, productCriteria),
    items: selectedItems.items,
    prices: getCartPrice(selectedItems.subtotal, taxRate),
    contactDetails: {
      firstname: contactDetails?.firstname,
      lastname: contactDetails?.lastname,
      email: contactDetails?.email,
      phone: contactDetails?.phone,
      homePhone: contactDetails?.homePhone,
      workPhone: contactDetails?.workPhone,
      mobilePhone: contactDetails?.mobilePhone,
      address: contactDetails?.address,
      city: contactDetails?.city,
      state: contactDetails?.state,
      zip: contactDetails?.zip,
      jobAddress: contactDetails?.jobAddress,
      jobCity: contactDetails?.jobCity,
      jobState: contactDetails?.jobState,
      jobZip: contactDetails?.jobZip,
    },
    taxPercentage: contactDetails?.taxRate?.taxRate,
  };
  return sendQuoteApi(sendQuoteRequest);
}
