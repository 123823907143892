import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Level as LevelType } from '../../models/product';
import { NotToday } from '../../models/product';
import { Box } from '@mui/material';
import { SXCssObject } from 'models/ThemeImages';
import { Paragraph } from 'components/Paragraph';
import Platinum from './Platinum';
import Gold from './Gold';
import Silver from './Silver';
import Bronze from './Bronze';
import Cancel from './Cancel';

interface Props {
    level: LevelType | NotToday;
    backgroundTileColor?: boolean;
    isSelected?: boolean;
    systemType?: string;
}

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        alignItems: 'center',
        maxWidth: {
            xs: '100%',
            sm: 'inherit',
        },
        height: {
            xs: '50px',
            sm: 'inherit',
        },
        pl: {
            sm: '0px',
        },
    },
    rootCart: {
        marginRight: '12px',
        maxWidth: {
            xs: '100%',
            sm: 'inherit',
        },
        height: {
            xs: 'auto',
            sm: 'inherit',
        },
        pl: {
            sm: '0px',
        },
        alignItems: 'center',
    },
    selectedPaymentIconWrapperStyle: {
        float: 'right',
        width: '0px',
        height: '25px',
    },
    selectedPaymentIconStyle: {
        height: '24px',
        width: '24px',
    },
    hollowRing: {
        pt: {
            sm: '7px !important',
        },
        display: 'flex',
        alignItems: 'center',
    },
    iconMargin: {
        marginLeft: '12px',
    },
    xsFont16: {
        fontSize: {
            xs: '1rem',
            sm: 'inherit',
        },
    },
});

export default function Level(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const Component = getComponent(props.level);
    const { backgroundTileColor = true } = props;
    if (!Component) return null;
    return (
        <Grid
            container
            sx={cssObject.root}
            style={{
                marginRight: '12px',
                backgroundColor: backgroundTileColor ? tileColor(props.level) : '',
            }}
            alignItems={'center'}
            id={`level-${props.level}`}
            xs={12}
        >
            <Grid item sx={cssObject.hollowRing}>
                <Component />
                <Box sx={cssObject.iconMargin}>
                    <Paragraph bold headingStyle responsiveFontSize22>
                        {props.level}
                    </Paragraph>
                </Box>
            </Grid>
        </Grid>
    );
}
export function LevelCart(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const Component = getComponent(props.level);
    if (!Component) return null;

    return (
        <Grid container sx={cssObject.rootCart} alignItems={'center'} id={`level-${props.level}`} xs={12}>
            <Grid item sx={cssObject.hollowRing}>
                <Component />
                <Box sx={cssObject.iconMargin}>
                    {props.level === 'Not Today' ? (
                        <Paragraph bold headingStyle responsiveFontSize18>
                            <Box component={'span'} sx={cssObject.xsFont16}>
                                IAQ - None selected
                            </Box>
                        </Paragraph>
                    ) : props.systemType !== 'IAQ' ? (
                        <Paragraph bold headingStyle responsiveFontSize18>
                            <Box component={'span'} sx={cssObject.xsFont16}>
                                {` HVAC ${props.systemType}`}{' '}
                                <Box component={'span'} style={{ whiteSpace: 'nowrap' }}>
                                    {' '}
                                    {`${props.level} Package`}
                                </Box>
                            </Box>
                        </Paragraph>
                    ) : (
                        <Paragraph bold headingStyle responsiveFontSize18>
                            <Box component={'span'} sx={cssObject.xsFont16}>
                                {`${props.systemType}`} {`${props.level} Package`}
                            </Box>
                        </Paragraph>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
}
export function tileColor(level: LevelType | NotToday) {
    switch (level) {
        case 'Platinum':
            return '#EAEAF0';
        case 'Gold':
            return '#F8F4E1';
        case 'Silver':
            return '#F5F5F5';
        case 'Bronze':
            return '#F8F0E8';
        case 'Not Today':
            return '#F5F5F5';
        default:
            return '';
    }
}
export function getComponent(level: LevelType | NotToday) {
    switch (level) {
        case 'Platinum':
            return Platinum;
        case 'Gold':
            return Gold;
        case 'Silver':
            return Silver;
        case 'Bronze':
            return Bronze;
        case 'Not Today':
            return Cancel;
        default:
            return null;
    }
}
