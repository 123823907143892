import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CartItem, Schedule, SystemGroup } from  "../../models/cart";
import { ProductCriteria } from  "../../models/productCriteria";
import { clearData } from "./clearData";

export interface PreCart  {
  items: [],
  itemsIAQ: CartItem[];
  itemsAddOn: CartItem[];
  firstSystemCriteria: ProductCriteria;
  schedule: Schedule[];
}
const initialState: PreCart = {
  items: [],
  itemsIAQ: [],
  itemsAddOn: [],
  firstSystemCriteria: {},
  schedule: []
};
const selectionSlice = createSlice({
  name: "selectionSlice",
  initialState: initialState,
  reducers: {
    // @ts-ignore
    setMainSelection: (state, action:PayloadAction<CartItem[]>) => {
      return {
        ...state,
        items: action.payload,
        loading: false,
      };
    },
    setIAQSelection: (state, action:PayloadAction<{ newIAQ: CartItem; systemGroupToSetOrChange: SystemGroup }>) => {
      const existingArr = state.itemsIAQ;
      let systemToRemove: CartItem[];
      if (
        action.payload.systemGroupToSetOrChange === SystemGroup.SystemGroup1
      ) {
        systemToRemove = existingArr.filter(
          (item) => item.systemGroup === SystemGroup.SystemGroup1
        );
      } else {
        systemToRemove = existingArr.filter(
          (item) => item.systemGroup === SystemGroup.SystemGroup2
        );
      }
      const removedIaqList = existingArr.filter(
        (item) => !systemToRemove.includes(item)
      );

      return {
        ...state,
        itemsIAQ: [...removedIaqList, action.payload.newIAQ],
        loading: false,
      };
    },
    setAddonSelection: (state, action:PayloadAction<{ addOnsList: CartItem[]; shouldReplace: boolean }>) => {
      if (action.payload.shouldReplace) {
        return {
          ...state,
          itemsAddOn: [...action.payload.addOnsList],
          loading: false,
        };
      }
      return {
        ...state,
        itemsAddOn: [...state.itemsAddOn, ...action.payload.addOnsList],
        loading: false,
      };
    },
    resetAddonSelection: (state, action) => {
      return {
        ...state,
        itemsAddOn: [],
        loading: false,
      };
    },
    resetIAQSelection: (state, action:PayloadAction<{ systemGroupToReset: SystemGroup }>) => {
      const existingArr = state.itemsIAQ;
      let systemToRemove: CartItem[];
      if (action.payload.systemGroupToReset === SystemGroup.SystemGroup1) {
        systemToRemove = existingArr.filter(
          (item) => item.systemGroup === SystemGroup.SystemGroup1
        );
      } else {
        systemToRemove = existingArr.filter(
          (item) => item.systemGroup === SystemGroup.SystemGroup2
        );
      }
      const removedIaqList = existingArr.filter(
        (item) => !systemToRemove.includes(item)
      );

      return {
        ...state,
        itemsIAQ: [...removedIaqList],
        loading: false,
      };
    },
    resetSelection: (state) => {
      return {
        ...initialState,
      };
    },
    setProductCriteria: (state, action:PayloadAction<ProductCriteria>) => {
      return {
        ...state,
        ...((!state.items || state.items.length === 0) && {
          firstSystemCriteria: action.payload,
        }),
      };
    },
    scheduleInspection: (state, action:PayloadAction<Schedule[]>) => {
      return {
        ...state,
        schedule: action.payload,
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearData, (state, action) => {
      return initialState;
    });
  },
});

// @ts-ignore
export default selectionSlice.reducer;
export const {
  resetSelection,
  setMainSelection,
  setIAQSelection,
  setAddonSelection,
  resetAddonSelection,
  resetIAQSelection,
  setProductCriteria,
  scheduleInspection
} = selectionSlice.actions;

