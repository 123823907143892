import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { getThemePrimaryLight, getThemePrimaryMain } from  "components/common/Utilities";

export default function TooltipIcon(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    const themePrimaryLight = getThemePrimaryLight(theme);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
            <g data-name="Group 3037" transform="translate(-574.5 -339.693)">
                <g data-name="Union 15" fill={`${themePrimaryLight}`} strokeLinecap="round" strokeLinejoin="round">
                    <path d="M591.75 368.194h-2.5a2.003 2.003 0 01-1.998-2.003V363.29l-.504-.289a7.484 7.484 0 01-2.717-2.66 7.327 7.327 0 01-1.031-3.749c0-4.08 3.365-7.4 7.5-7.4 4.137 0 7.502 3.32 7.502 7.4a7.327 7.327 0 01-1.031 3.749 7.507 7.507 0 01-2.719 2.66l-.504.288v2.902a2.003 2.003 0 01-1.998 2.003z" />
                    <path
                        d="M591.75 367.194c.55 0 .998-.45.998-1.003v-2.321a2 2 0 011.008-1.737c2.002-1.143 3.246-3.266 3.246-5.541 0-3.529-2.916-6.4-6.502-6.4-3.584 0-6.5 2.871-6.5 6.4 0 2.276 1.242 4.399 3.244 5.54a2 2 0 011.008 1.738v2.32c0 .554.447 1.004.998 1.004h2.5m0 2h-2.5a2.999 2.999 0 01-2.998-3.003v-2.321c-2.543-1.452-4.252-4.167-4.252-7.278 0-4.64 3.805-8.4 8.5-8.4s8.502 3.76 8.502 8.4c0 3.11-1.711 5.826-4.254 7.278v2.32a2.999 2.999 0 01-2.998 3.004z"
                        fill={`${themePrimaryMain}`}
                    />
                </g>
                <circle
                    data-name="Ellipse 189"
                    cx={1.5}
                    cy={1.5}
                    r={1.5}
                    transform="translate(589 342.192)"
                    fill={`${themePrimaryMain}`}
                />
                <circle
                    data-name="Ellipse 190"
                    cx={1.5}
                    cy={1.5}
                    r={1.5}
                    transform="translate(602 353.192)"
                    fill={`${themePrimaryMain}`}
                />
                <circle
                    data-name="Ellipse 193"
                    cx={1.5}
                    cy={1.5}
                    r={1.5}
                    transform="translate(576 353.192)"
                    fill={`${themePrimaryMain}`}
                />
                <circle
                    data-name="Ellipse 191"
                    cx={1.5}
                    cy={1.5}
                    r={1.5}
                    transform="translate(598 346.192)"
                    fill={`${themePrimaryMain}`}
                />
                <circle
                    data-name="Ellipse 192"
                    cx={1.5}
                    cy={1.5}
                    r={1.5}
                    transform="translate(580 345.192)"
                    fill={`${themePrimaryMain}`}
                />
            </g>
        </svg>
    );
}
