import * as React from 'react';
function FullWarranty(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
            <defs>
                <style>{'.prefix__b4{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'}</style>
            </defs>
            <g transform="translate(-433.698 -399.962)">
                <path
                    className="prefix__b4"
                    stroke={`#111111`}
                    d="M456.494 412.112a10.08 10.08 0 017.062 9.616v3.96h-20.149v-3.96a10.08 10.08 0 017.023-9.6"
                />
                <rect
                    className="prefix__b4"
                    width={25.567}
                    height={4.76}
                    rx={2.175}
                    stroke={`#111111`}
                    transform="translate(440.698 425.688)"
                />
                <path
                    className="prefix__b4"
                    stroke={`#111111`}
                    d="M447.829 413.389v3.061M459.291 413.682v2.767M450.87 418.671V410.8a1.84 1.84 0 011.839-1.839h1.545a1.84 1.84 0 011.839 1.839v7.87"
                />
            </g>
        </svg>
    );
}

export default FullWarranty;
