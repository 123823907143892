import { Observable, of } from "rxjs";
import { Action } from "ts-action";
import { ofType } from "ts-action-operators";
import {
  catchError,
  ignoreElements,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { changeProduct as changeProductService } from "../services/productSevices";
import { combineEpics, Epic } from "redux-observable";
import { orderBy } from "lodash";

import { AxiosResponse } from "axios";
import { changeProduct, changeProductError, changeProductSuccess } from "../slice/productSearchSlice";
import { Product } from "../../models/product";
import { showSnackbarError } from "../../components/common/Snackbar/SnackbarHelper";
import { Dependencies, RootState } from "..";
import { AppRoute } from "../../models/route";
import { SystemGroup } from "../../models/cart";

const order = ["Platinum", "Gold", "Silver", "Bronze"];

export const changeProduct$: Epic = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(changeProduct),
    switchMap((action) =>
      changeProductService(action.payload).pipe(
        map((resp: AxiosResponse<Product[]>) => resp.data || []),
        map((products:any) => {
          const orderedData = orderBy(products
              .map((p:any) => ({ ...p, id: p.id ? p.id : +p.extId }))
              .sort((a:any, b:any) => {
                return (
                  (order.findIndex((o) => o === a.productAttributes.level) -
                    order.findIndex((o) => o === b.productAttributes.level)) *
                  -1
                );
              }),
            ["price"],
            ["desc"]
          );
          let obj: any = {
            sku: action.payload.sku,
            data: {},
            isByClickingChangeinSelection:
              action.payload.isByClickingChangeinSelection,
            changingSystemGroup: action.payload.changingSystemGroup,
            isByClickingEditInWizard: action.payload.isByClickingEditInWizard,
          };
          if (action.payload.isChangeForSecondSystem) {
            obj.data.productsSystemTwo = orderedData;
          } else {
            obj.data.products = orderedData;
          }
          return changeProductSuccess(obj);
        }),
        catchError((error) => {
          showSnackbarError("Unable to load products");
          return of(changeProductError(error));
        })
      )
    )
  );

  export const navigateToEquipmentOptions$: Epic = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { history }: Dependencies
) =>
    action$.pipe(
        ofType(changeProductSuccess),
        tap((action) => {
            if (action.payload.isByClickingEditInWizard) {
                history.push(AppRoute.EquipmentOptions, {
                    fromEditInWizard: action.payload.sku,
                });
            } else if (!action.payload.isByClickingChangeinSelection) {
                history.push(AppRoute.EquipmentOptions, {
                    changingSystemGroup: action.payload.data.products
                        ? SystemGroup.SystemGroup1
                        : SystemGroup.SystemGroup2,
                    isByClickingChange: action.payload.sku,
                });
            } else {
                history.push(AppRoute.EquipmentOptions, {
                    isSecondSystem: action.payload.changingSystemGroup === SystemGroup.SystemGroup2 ? true : false,
                    changingSystemGroup: action.payload.changingSystemGroup,
                    isByClickingChangeinSelection: action.payload.isByClickingChangeinSelection,
                });
            }
        }),
        ignoreElements()
    );

export default combineEpics(changeProduct$, navigateToEquipmentOptions$);
