import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContactInformation, Schedule, ScheduleResp } from "../../models/cart";
import { clearData } from "./clearData";

export const initialState: any = {
  loading: false,
  scheduleAPIError: {},
  isGenerateQuoteAPILoading: false,
};
const cartSlice = createSlice({
  name: "cartSlice",
  initialState: initialState,
  reducers: {
    resetCart: () => {
      return {
        ...initialState,
      };
    },
    createContact: (state, action: PayloadAction<ContactInformation>) => {
      return {
        ...state,
        contactInformation: {
          ...state.contactInformation,
          ...action.payload,
        },
      };
    },
    sendQuotations: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    submitQuoteSuccess: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    submitQuoteError: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    generateQuote: (state) => {
      return {
        ...state,
        isGenerateQuoteAPILoading: true,
      };
    },
    generateQuoteSuccess: (state) => {
      return {
        ...state,
        isGenerateQuoteAPILoading: false,
      };
    },
    generateQuoteError: (state) => {
      return {
        ...state,
        isGenerateQuoteAPILoading: false,
      };
    },
    proceedToDownloadQuote: (state, action:PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload,
        isGenerateQuoteAPILoading: false,
      };
    },
    placeOrder: (state, action: PayloadAction<Schedule[]>) => {
      return {
        loading: true,
        scheduleAPIError: {},
      };
    },
    placeOrderSuccess: (state, action: PayloadAction<ScheduleResp>) => {
      return {
        ...state,
        scheduleResp: {
          ...action.payload,
          ticketId: action.payload.ticketId,
          motiliWorkOrderNumber: action.payload.motiliWorkOrderNumber,
        },
        scheduleAPIError: {},
        loading: false,
      };
    },
    placeOrderError: (state, action: PayloadAction<Error>) => {
      return {
        loading: false,
        scheduleAPIError: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearData, (state, action) => {
      return initialState;
    });
  },
});

export const createContactSuccess = createAction<{ secondSystem: boolean }>(
  "createContactSuccess"
);
export const contactInprogress = createAction("contactInprogress");
export const contactInprogressSuccess = createAction<ContactInformation>(
  "contactInprogressSuccess"
);
export const moveToHome = createAction("moveToHome");
export default cartSlice.reducer;
export const {
  resetCart,
  createContact,
  sendQuotations,
  submitQuoteSuccess,
  submitQuoteError,
  generateQuote,
  generateQuoteSuccess,
  generateQuoteError,
  placeOrder,
  placeOrderError,
  placeOrderSuccess,
  proceedToDownloadQuote,
} = cartSlice.actions;
