import { Observable, of } from 'rxjs';
import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { catchError, ignoreElements, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getProductBySkuService, searchProduct as searchProductService } from '../services/productSevices';
import { RootState } from "../index";
import { selectProductCriteriaState } from '../selectors/productCriteria';
import { Dependencies } from '../index';
import { DuctlessNumberOfRooms, FurnaceEfficiency, NumberOfHVAC, PackageReplacementType } from '../../models/productCriteria';
import { selectProductAttributeEfficiency } from '../selectors/productAttributes';
import { selectProducts, selectProductSearchState } from '../selectors/productSearch';
import { combineEpics, Epic } from 'redux-observable';
import { orderBy } from 'lodash';
import { getProductBySkuAction, getProductBySkuError, getProductBySkuSuccess, searchProduct, searchProductError, searchProductIAQ, searchProductIAQError, searchProductIAQSuccess, searchProductSuccess, searchSecondProduct, searchSecondProductError, searchSecondProductSuccess, showProduct, showProductSuccess } from '../slice/productSearchSlice';
import { clearWizarQuestionData, storeFirstProductCriteria } from '../slice/productCriteriaSlice';
import { showSnackbarError } from '../../components/common/Snackbar/SnackbarHelper';
import { AppRoute } from '../../models/route';
import { SystemGroup } from '../../models/cart';

const order = ['Platinum', 'Gold', 'Silver', 'Bronze'];

export const productSearch$: Epic = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
) =>
    action$.pipe(
        ofType(searchProduct),
        withLatestFrom(
            state$.pipe(map(selectProductCriteriaState)),
            state$.pipe(map(selectProductAttributeEfficiency)),
        ),
        map(([action, criteria, efficiencyAttr]) => {
            const { efficiency, ...other } = criteria;
            let alterEfficiency = efficiency;
            if (efficiency && efficiencyAttr?.options.length) {
                if (efficiency === FurnaceEfficiency.Percent80) {
                    alterEfficiency = efficiencyAttr.options
                        .filter((o:any) => +o.label <= 80)
                        .map((o:any) => o.label)
                        .join();
                }
                if (efficiency === FurnaceEfficiency.Percent90) {
                    alterEfficiency = efficiencyAttr.options
                        .filter((o:any) => +o.label >= 90)
                        .map((o:any) => o.label)
                        .join();
                }
            }
            return {
                shouldRedirect: action.payload.shouldRedirect,
                // contactDetails: contactDetails,
                criteriaMain: {
                    ...other,
                    efficiency: alterEfficiency,
                },
            };
        }),
        switchMap((payload) =>
            {
                return searchProductService(payload.criteriaMain).pipe(
                map((resp:any) => resp.data || []),
                map((products:any) =>
                    searchProductSuccess({
                        products: orderBy(
                            products
                                .map((p: any) => ({ ...p, id: p.id ? p.id : +p.extId }))
                                .sort((a: any, b: any) => {
                                    return (
                                        (order.findIndex((o) => o === a.productAttributes.level) -
                                            order.findIndex((o) => o === b.productAttributes.level)) *
                                        -1
                                    );
                                }),
                            ['price'],
                            ['desc']
                        ),
                        shouldRedirect: payload.shouldRedirect,
                    })
                ),
                catchError((error) => {
                    showSnackbarError('Unable to load product base on you criteria');
                    return of(searchProductError(error));
                })
            )
        }
        )
    );
export const productSearchDouble$: Epic = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    // { alert, history }: Dependencies
) =>
    action$.pipe(
        ofType(searchSecondProduct),
        withLatestFrom(
            state$.pipe(map(selectProductCriteriaState)),
            state$.pipe(map(selectProductAttributeEfficiency)),
            // state$.pipe(map(selectContactState))
        ),
        map(([action, criteria, efficiencyAttr]) => {
            const { efficiency, ...other } = criteria;
            let alterEfficiency = efficiency;
            if (efficiency && efficiencyAttr?.options.length) {
                if (efficiency === FurnaceEfficiency.Percent80) {
                    alterEfficiency = efficiencyAttr.options
                        .filter((o:any) => +o.label <= 80)
                        .map((o:any) => o.label)
                        .join();
                }
                if (efficiency === FurnaceEfficiency.Percent90) {
                    alterEfficiency = efficiencyAttr.options
                        .filter((o:any) => +o.label >= 90)
                        .map((o:any) => o.label)
                        .join();
                }
            }
            return {
                shouldRedirect: action.payload.shouldRedirect,
                // contactDetails: contactDetails,
                criteriaMain: {
                    ...other,
                    efficiency: alterEfficiency,
                },
            };
        }),
        switchMap((payload) =>
            {
                const { criteriaMain, shouldRedirect} = payload;
                return searchProductService(criteriaMain).pipe(
                    map((resp:any) => resp.data || []),
                    map((products:any) =>
                        searchSecondProductSuccess({
                            productsSystemTwo: orderBy(
                                products
                                    .map((p: any) => ({ ...p, id: p.id ? p.id : +p.extId }))
                                    .sort((a: any, b: any) => {
                                        return (
                                            (order.findIndex((o) => o === a.productAttributes.level) -
                                                order.findIndex((o) => o === b.productAttributes.level)) *
                                            -1
                                        );
                                    }),
                                ['price'],
                                ['desc']
                            ),
                            shouldRedirect: shouldRedirect,
                        })
                    ),
                    catchError((error) => {
                        showSnackbarError('Unable to load product base on you criteria');
                        return of(searchSecondProductError(error));
                    })
                );
            }
        )
    );

export const getProductBySku$: Epic = (action$: Observable<Action>, state$: Observable<RootState>, 
    // { alert }: Dependencies
) =>
    action$.pipe(
        ofType(getProductBySkuAction),
        withLatestFrom(
            state$.pipe(map(selectProducts)),
            // state$.pipe(map(selectContactState))
        ),
        switchMap(([action, productList]) => {
            return getProductBySkuService(action.payload.sku).pipe(
                map((resp:any) => {
                    let updatedProducts;
                    if (productList && productList.length > 0) {
                        updatedProducts = [...productList, resp.data];
                    } else {
                        updatedProducts = [resp.data];
                    }
                    return getProductBySkuSuccess(updatedProducts);
                }),
                catchError((err) => {
                    return of(getProductBySkuError(err));
                })
            );
        })
    );

export const productIAQSearch$: Epic = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    // { alert }: Dependencies
) =>
    action$.pipe(
        ofType(searchProductIAQ),
        // withLatestFrom(state$.pipe(map(selectContactState))),
        switchMap((action) => {
            return searchProductService(action.payload).pipe(
                map((resp:any) => resp.data || []),
                map((productsIAQ:any) =>
                    searchProductIAQSuccess({
                        productsIAQ: orderBy(
                            productsIAQ
                                .map((p: any) => ({ ...p, id: p.id ? p.id : +p.extId }))
                                .sort((a: any, b: any) => {
                                    return (
                                        (order.findIndex((o) => o === a.productAttributes.level) -
                                            order.findIndex((o) => o === b.productAttributes.level)) *
                                        -1
                                    );
                                }),
                            ['price'],
                            ['desc']
                        ),
                    })
                ),
                catchError((error) => {
                    showSnackbarError('Unable to load product base on you criteria');
                    return of(searchProductIAQError(error));
                })
            )
        }
            
        )
    );

export const processContact$: Epic = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(showProduct),
        withLatestFrom(state$.pipe(map(selectProductCriteriaState)), state$.pipe(map(selectProductSearchState))),
        map(([action, criteria, productSearch]:any) => {
            if (
                criteria.numberOfHVAC === NumberOfHVAC.Multiple ||
                criteria.packageReplacementType === PackageReplacementType.ElectricalHeatAC ||
                criteria.ductlessNumberOfRooms === DuctlessNumberOfRooms.Multiple ||
                (criteria.doubleSystemFlag !== 'Second' &&
                    (!productSearch.products || productSearch.products.length < 1)) ||
                (criteria.doubleSystemFlag === 'Second' &&
                    (!productSearch.productsSystemTwo || productSearch.productsSystemTwo.length < 1))
            ) {
                //toberemoved
                // return navigateThankyouPage({ isNoProduct: true });
                return searchProductIAQSuccess({});
            } else if (action.payload.isSecondSystem) {
                return showProductSuccess({
                    isSecondSystem: true,
                });
            } else {
                return showProductSuccess({
                    isSecondSystem: false,
                });
            }
        })
    );

export const redirectToEquipmentOptions$: Epic = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    // { history }: Dependencies
) =>
    action$.pipe(
        ofType(searchProductSuccess, searchSecondProductSuccess),
        withLatestFrom(
        state$.pipe(map(selectProductCriteriaState))),
        switchMap(([action]) => {
            if (action.type === searchProductSuccess.type && action.payload.shouldRedirect) {
                return [
                    showProduct({
                        isSecondSystem: false,
                    }),
                ];
            } else if (action.type === searchSecondProductSuccess.type) {
                return [showProduct({ isSecondSystem: true })];
            } else {
                return [];
            }
        })
    );


    export const navigateToEquipmentOptions$: Epic = (
        action$: Observable<Action>,
        state$: Observable<RootState>,
        {  history }: Dependencies
    ) =>
        action$.pipe(
           ofType(showProductSuccess),
            tap((action) => action.payload.isSecondSystem
                    ? history.push(AppRoute.EquipmentOptions, { isSecondSystem: true })
                    : history.push(AppRoute.EquipmentOptions, { isSecondSystem: false })
            ),
            ignoreElements()
        );



export const navigateToIAQ$: Epic = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    {  history }: Dependencies
) =>
    action$.pipe(
        ofType(searchProductIAQSuccess),
        tap((action) => {
            if (action.payload.redirectToIAQPage) {
                const sysGroup = action.payload.redirectToIAQPage;
                history.push(AppRoute.IAQOptions, {
                    systemGroup: sysGroup,
                    isSecondSystem: sysGroup === SystemGroup.SystemGroup2 ? true : false,
                    isByClickingChangeInCartPage: true,
                });
            }
        }),
        ignoreElements()
    );

   
const storeFirstSysProductCriteria$: Epic = (
    action$: Observable<Action>,
    state: Observable<RootState>,
    // { history }: Dependencies
) =>
    action$.pipe(
        ofType(storeFirstProductCriteria),
        map(() => {
            return clearWizarQuestionData({ numberOfHVAC: NumberOfHVAC.Double });
        })
    );

export default combineEpics(
    productSearch$,
    productIAQSearch$,
    processContact$,
    redirectToEquipmentOptions$,
    navigateToEquipmentOptions$,
    navigateToIAQ$,
    getProductBySku$,
    productSearchDouble$,
    storeFirstSysProductCriteria$,
);
