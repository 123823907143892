import { DoubleSystemWizardState, NumberOfHVAC, ProductCriteria } from  "../../models/productCriteria";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clearData } from "./clearData";

const initialState: ProductCriteria = {};

const productAttributesSlice = createSlice({
  name: "productAttributesSlice",
  initialState: initialState,
  reducers: {
    setProductCriteria: (state, action:PayloadAction<ProductCriteria>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetProductCriteria: (state) => {
      return {
        ...initialState,
      };
    },
    setDoubleSystemFlag: (state, action:PayloadAction<{ doubleSystemFlag?: DoubleSystemWizardState }>) => {
        return {
            ...state,
            ...action.payload,
        };
      },
      clearWizarQuestionData: (state, action:PayloadAction<{ numberOfHVAC?: NumberOfHVAC }>) => {
        return {
            ...initialState,
            ...action.payload,
        };
      },
  },
  extraReducers: (builder) => {
    builder.addCase(clearData, (state, action) => {
      return initialState;
    });
  },
});

export const storeFirstProductCriteria = createAction<ProductCriteria>('storeFirstProductCriteria')

export default productAttributesSlice.reducer;
export const {
    resetProductCriteria,
    setProductCriteria,
    setDoubleSystemFlag,
    clearWizarQuestionData,
} = productAttributesSlice.actions
