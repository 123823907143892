import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductSearch } from  "../../models/productSearch";
import { Product } from  "../../models/product";
import { SystemGroup } from  "../../models/cart";
import { ProductCriteria } from  "../../models/productCriteria";
import { clearData } from "./clearData";

export interface ProductSearchState extends ProductSearch {
  loading: boolean;
  isIAQSearchLoading: boolean; //Maintaining two loaders, since both product search & IAQ Search will be triggered at same time.
  isNoProduct: boolean;
  error: Error | null;
}

const initialState: ProductSearchState = {
  loading: false,
  isIAQSearchLoading: false,
  isNoProduct: false,
  error: null,
};

const productSearchSlice = createSlice({
  name: "productSearchSlice",
  initialState: initialState,
  reducers: {
    searchProduct: (state, action) => {
      return {
        ...state,
        error: null,
        products: [],
        loading: true,
      };
    },
    searchProductSuccess: (state, action:PayloadAction<{ products: Product[]; shouldRedirect?: boolean }>) => {
      return {
        ...state,
        products: [...action.payload.products],
        loading: false,
      };
    },
    searchProductError: (state, action:PayloadAction<Error>) => {
      return {
        ...initialState,
        loading: false,
        error: action.payload,
      };
    },
    searchSecondProduct: (state, action) => {
      return {
        ...state,
        error: null,
        productsSystemTwo: [],
        loading: true,
      };
    },
    searchSecondProductSuccess: (state, action:PayloadAction<{ productsSystemTwo: Product[]; shouldRedirect?: boolean }>) => {
      return {
        ...state,
        productsSystemTwo: [...action.payload.productsSystemTwo],
        loading: false,
      };
    },
    searchSecondProductError: (state, action:PayloadAction<Error>) => {
      return {
        ...initialState,
        loading: false,
        error: action.payload,
      };
    },
    getProductBySkuAction: (state, action:PayloadAction<{ sku: string }>) => {
        return {
            ...state,
            loading: true,
        };
      },
      getProductBySkuSuccess: (state, action:PayloadAction<Product[]>) => {
        return {
            ...state,
            loading: false,
            products: action.payload,
        };
      },
      getProductBySkuError: (state) => {
        return {
            ...state,
            loading: false,
        };
      },
      searchProductIAQ: (state, action:PayloadAction<ProductCriteria>) => {
        return {
            ...state,
            isIAQSearchLoading: true,
            error: null,
        };
      },
      changeProductIAQ: (state, action:PayloadAction<{ productCriteria: ProductCriteria; systemGroup: SystemGroup }>) => {
        return {
            ...state,
            error: null,
            loading: true,
        };
      },
      changeProductIAQSuccess: (state, action:PayloadAction<ProductSearch>) => {
        return {
            ...state,
            productsIAQ: action.payload.productsIAQ,
            loading: false,
        };
      },
      changeProductIAQError: (state, action:PayloadAction<Error>) => {
        return {
            ...initialState,
            loading: false,
            error: action.payload,
        };
      },
      searchProductIAQSuccess: (state, action:PayloadAction<ProductSearch>) => {
        return {
            ...state,
            productsIAQ: action.payload.productsIAQ,
            isIAQSearchLoading: false,
        };
      },
      searchProductIAQError: (state, action:PayloadAction<Error>) => {
        return {
            ...initialState,
            isIAQSearchLoading: false,
            error: action.payload,
        };
      },
      resetProductSearch: (state) => {
        return {
          ...initialState,
        };
      },
      resetIAQProductSearch: (state, action) => {
        return {
            ...state,
            productsIAQ: [],
        };
      },
      resetMainProductSearch: (state) => {
        return {
            ...state,
            products: [],
        };
      },
      changeProduct: (state, action) => {
        return {
            ...state,
            error: null,
            loading: true,
        };
      },
      changeProductSuccess: (state, action:PayloadAction<{
        data: { products?: Product[]; productsSystemTwo?: Product[] };
        sku?: string;
        isByClickingChangeinSelection?: string;
        changingSystemGroup?: SystemGroup;
        isByClickingEditInWizard?: string;
    }>) => {
        return {
            ...state,
            ...action.payload.data,
            loading: false,
        };
      },
      changeProductError: (state, action:PayloadAction<Error>) => {
        return {
            ...initialState,
            loading: false,
            error: action.payload,
        };
      }
  },
  extraReducers: (builder) => {
    builder.addCase(clearData, (state, action) => {
      return initialState;
    });
  },
});

export const showProductSuccess = createAction<{ isSecondSystem?: boolean; addSecondSystemFromCart?: boolean }>('showProductSuccess')
export const showProduct = createAction<{ isSecondSystem?: boolean; addSecondSystemFromCart?: boolean }>('showProduct')

export default productSearchSlice.reducer;
export const {
  resetProductSearch,
  searchProduct,
  searchProductError,
  searchProductSuccess,
  changeProduct,
  changeProductError,
  changeProductSuccess,
  searchProductIAQ,
  searchProductIAQSuccess,
  searchProductIAQError,
  resetIAQProductSearch,
  getProductBySkuAction,
  getProductBySkuSuccess,
  getProductBySkuError,
  resetMainProductSearch,
  searchSecondProduct,
  searchSecondProductError,
  searchSecondProductSuccess,
  changeProductIAQ,
} = productSearchSlice.actions;
