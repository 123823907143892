import React from 'react';
import { Theme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import CardMedia from '@mui/material/CardMedia';
import ContentContainer from './ContentContainer';
import { Button } from './Button';
import { Paragraph } from './Paragraph';
import striptags from 'striptags';
import { Box, ClickAwayListener, Zoom, Paper, Popper, useMediaQuery, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import Glass from './icons/Glass';
import { taxCalculation, currencyFormat } from './common/NumberUtil';
import CheckIcon from '@mui/icons-material/Check';
import { SXCssObject } from '../models/ThemeImages';
import Level, { tileColor } from './icons/Level';
import { Product } from '../models/product';
const createCssObject = (theme: Theme, props: Props): SXCssObject => ({
    root: {
        padding: '0px !important',
    },
    borderSelected: {
        border: `1px solid ${theme.palette.action.hover}`,
    },
    headerContainer: {
        padding: {
            xs: theme.spacing(0.8),
            sm: theme.spacing(2.4, 2.4, 2.4, 2.4),
        },
    },
    levelInfo: {
        alignItems: 'center',
        display: 'block',
        textAlign: 'left',
    },
    systemPrice: {
        textAlign: 'left',
        borderRight: '20px',
        paddingLeft: {
            lg: theme.spacing(8),
        },
        verticalAlign: {
            xs: 'middle',
            sm: 'inherit',
        },
        paddingBottom: {
            xs: theme.spacing(1),
            sm: 'inherit',
        },
    },
    systemPriceSelected: {
        textAlign: 'left',
        paddingLeft: {
            sm: theme.spacing(1),
            lg: theme.spacing(0),
        },
        paddingTop: {
            xs: theme.spacing(0),
            sm: 'inherit',
        },
        paddingBottom: {
            xs: theme.spacing(1),
            sm: 'inherit',
        },
    },
    buttonBlock: {
        textAlign: {
            xs: 'center',
            sm: 'right',
        },
        paddingTop: {
            xs: '20px',
            sm: 'inherit',
        },
    },
    productDetail: {
        padding: {
            xs: `8px`,
            sm: theme.spacing(0, 2.4, 2.4, 2.4),
        },
    },
    imageBox: {
        cursor: 'zoom-in',
        paddingBottom: {
            xs: '8px',
            sm: 'inherit',
        },
    },
    img: {
        height: '96px',
        objectFit: 'contain',
        border: '1px solid #D4D4D4',
        borderRadius: '4px',
    },
    panel: {
        border: `1px solid ${theme.palette.divider}`,
    },
    panelSummary: {
        padding: theme.spacing(0, 1),
        '&.MuiExpansionPanelSummary-root': {
            height: theme.spacing(4),
            minHeight: theme.spacing(4),
        },
    },
    panelDetail: {
        borderTop: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(2),
    },
    seer: {
        paddingTop: {
            xs: theme.spacing(1),
            sm: 'inherit',
        },
        paddingLeft: {
            xs: theme.spacing(0),
            sm: theme.spacing(1),
            lg: 'inherit',
        },
    },
    productDetailLabel: {
        paddingLeft: {
            md: theme.spacing(1),
        },
    },
    productFeature: {
        paddingLeft: {
            sm: theme.spacing(2.4),
        },
    },
    readMoreText: {
        color: theme.palette.success.main,
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        fontWeight: theme.typography.fontWeightMedium,
        paddingLeft: theme.spacing(0.3),
        '&:hover': {
            color: `${theme.palette.success.main} !important`,
        },
    },
    productDetailSection: {
        paddingTop: {
            xs: '12px',
            lg: 'inherit',
        },
    },
    fullWidth: {
        width: '100%',
    },
    paperStyle: {
        backgroundColor: 'white',
        margin: '0px',
        '&.MuiPaper-rounded': {
            borderRadius: '4px',
        },
        display: 'flex',
        justifyContent: 'center',
        boxShadow: '0px 5px 31px #0000004F',
        border: `2px solid ${theme.palette.primary.main}`,
        width: {
            xs: '100%',
            sm: 'inherit',
        },
        height: {
            xs: '80%',
            sm: 'inherit',
        },
    },
    zoomedImage: {
        objectFit: 'contain',
        width: {
            xs: '100%',
            sm: '433px',
        },
        height: {
            xs: '80%',
            sm: '390px',
        },
    },
    glass: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '10px',
        paddingRight: '10px',
    },
    header: {
        p: {
            xs: '8px',
            sm: '8px 12px'
        },
        alignItems: 'center',
        backgroundColor: tileColor(props.item.productAttributes.level),
        borderRadius: '4px',
        height: {
            lg: '91px',
        },
    },
});

interface Props {
    item: Product;
    onSelect?: (buttonSelected: boolean, sku: string) => void;
    width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    selectButton?: boolean;
    shouldHideActionButton?: boolean;
    selectedButtonSku?: string;
    isIAQ?: boolean;
}

function ProductDetail(props: Props) {
    const [showFullText, setShowFullText] = React.useState(false);
    const [isButtonSelected, setIsButtonSelected] = React.useState(false);
    const [zoomOpen, setZoomOpen] = React.useState(false);
    const cssObject: SXCssObject = createCssObject(useTheme(), props);
    const theme = useTheme();
    const isExactlySm = useMediaQuery(theme.breakpoints.only('sm'));
    const isMdOnly = useMediaQuery(theme.breakpoints.only('md'));
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const { item, isIAQ } = props;
    const productDescription = item?.description;
    const productImage = item?.image;
    const sku = item?.sku;
    const systemType = item && item.productAttributes && item.productAttributes.systemType;
    const productSeer = item?.productAttributes?.seer2;
    const hpStages = item?.productAttributes.hpStages;
    const furnaceStages = item?.productAttributes.furnaceStages;
    const acStages = item?.productAttributes.acStages;
    const fanSpeeds = item?.productAttributes.fanSpeeds;
    const motorType = item?.productAttributes.motorType;
    const compressorWarranty = item?.productAttributes.compressorWarranty;
    const heatExchangerWarranty = item?.productAttributes.heatExchangerWarranty;
    const iaqPreFilter = item?.productAttributes.iaqPreFilter;
    //Pre Filtration
    const iaqPrimaryFiltration = item?.productAttributes.iaqPrimaryFiltration;
    //Primary Filtration
    const iaqSecondaryFiltration = item?.productAttributes.iaqSecondaryFiltration;
    //Secondary Filtration
    const iaqUVLight = item?.productAttributes.uvLight;
    //Purifier

    const miniSplitTonnage = item?.productAttributes.miniSplitTonnage;
    const miniSplitMultiZoneIDUMatchup = item?.productAttributes.miniSplitMultiZoneIduMatchUp;
    const itemLevel = item ? item.productAttributes.level : 'Not Today';
    const itemPrice =
        item && item?.price;
    const componentId = item.id ? item?.id : `product-detail-${itemLevel}`;

    React.useEffect(() => {
        props.selectedButtonSku && props.selectedButtonSku === sku
            ? setIsButtonSelected(true)
            : setIsButtonSelected(false);
    }, [props.selectedButtonSku, sku]);
    let featureCount = 0;
    const handleButtonClick = () => {
        if (!!props.onSelect) {
            props.onSelect(!isButtonSelected, sku || '');
        }
        setIsButtonSelected((prevState) => !prevState);
    };
    const level = <Level level={itemLevel} />;
    const descriptionLabel = (
        <Grid sx={cssObject.productDetailLabel}>
            <Paragraph size={'small'} semibold>
                Product Details
            </Paragraph>
        </Grid>
    );
    const description = (trimLength: number) => (
        <Grid sx={cssObject.productDetailLabel}>
            <Paragraph fontSizeVerySmall={true} size={'small'} id={`description-level-${itemLevel}`}>
                {trimLength ? striptags(productDescription).slice(0, trimLength) + '  ' : striptags(productDescription)}
                {(isXs || isMdOnly) && !!striptags(productDescription) && (
                    <Box component={'span'} onClick={toggleFullText} sx={cssObject.readMoreText}>
                        {showFullText ? 'Read less' : 'Read more'}
                    </Box>
                )}
            </Paragraph>
        </Grid>
    );

    const feature = (text: string) => {
        if (featureCount < 4) {
            featureCount++;
            return (
                <Grid container spacing={1} sx={cssObject.productFeature}>
                    <Grid item>
                        <CheckIcon sx={{
                                color: `${theme.palette.success.main}`,
                                fontSize: '15px',
                                fontWeight: 900,
                                paddingTop: '1px',
                            }} />
                    </Grid>
                    <Grid item xs={9} style={{ marginLeft: '-5px' }}>
                        <Paragraph fontSizeVerySmall={true} size={'small'} id={`description-level-${itemLevel}`}>
                            {text}
                        </Paragraph>
                    </Grid>
                </Grid>
            );
        } else return null;
    };
    //toberemoved
    const price = itemPrice && (itemPrice) + taxCalculation(itemPrice);

    const systemPrice = (
        <Paragraph semibold responsiveFontSize22 headingColor>
            {price && currencyFormat.format(price) }
        </Paragraph>
    );
    const systemPriceLabel = (
        <Box display={'flex'} alignItems={'center'}>
            <Paragraph fontSizeVerySmall={true}>
               System Price
            </Paragraph>{' '}
        </Box>
    );

    const image = <CardMedia image={productImage} component={'img'} sx={cssObject.img} />;
    const zoomIcon = (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-18px', marginRight: '3px' }}>
            <Glass />
        </div>
    );
    const selectButton = (
        <Button
            id={`${componentId}-select-btn`}
            color="primary"
            smallerPadding={isExactlySm}
            selectedSecondary={isButtonSelected}
            onClick={handleButtonClick}
        >
            {props.selectButton
                ? isButtonSelected
                    ? 'Selected'
                    : 'Select'
                : isButtonSelected
                ? 'Selected'
                : 'Select'}
        </Button>
    );

    const toggleFullText = () => {
        setShowFullText(!showFullText);
    };
    const getTextBasedOnSize = () => {
        if (isXs) {
            return description(75);
        } else if (isMdOnly) {
            return description(125);
        } else {
            return description(0);
        }
    };
    const popperStyle: any = {
        position: 'absolute',
        top: 'auto',
        left: isXs ? 'auto' : '26%',
        zIndex: 2,
    };
    return (
        <ContentContainer disableGrid sx={cssObject.fullWidth}>
            <Popper open={zoomOpen} style={{ ...popperStyle }} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Zoom {...TransitionProps} style={{ transformOrigin: isXs ? 'center' : 'center left' }}>
                        <Paper sx={cssObject.paperStyle}>
                            <ClickAwayListener
                                onClickAway={() => {
                                    setZoomOpen(false);
                                }}
                            >
                                <div>
                                    <Box sx={cssObject.glass}>
                                        <Close onClick={() => setZoomOpen(false)} />
                                    </Box>
                                    <CardMedia image={productImage} component={'img'} sx={cssObject.zoomedImage} />
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Zoom>
                )}
            </Popper>
            <Container
                maxWidth={false}
                sx={[cssObject.root, isButtonSelected && cssObject.borderSelected]}
                id={`card-level-${itemLevel}`}
            >
                <ContentContainer disableGrid background={'bg3'}>
                    <Box sx={cssObject.headerContainer}>
                        <Grid sx={cssObject.header} container>
                            <Grid item xs={6} sm={3} md={3} container sx={cssObject.levelInfo}>
                                {level}
                            </Grid>
                            {itemLevel !== 'Not Today' && (
                                <Grid item xs={6} sm={5} md={5} container>
                                    <Grid
                                        item
                                        container
                                        direction={'column'}
                                        sx={cssObject.systemPrice}
                                        id={`system-price-level-${itemLevel}`}
                                        style={{ paddingLeft: '6px' }}
                                    >
                                        {systemPriceLabel}
                                        {systemPrice}
                                    </Grid>
                                </Grid>
                            )}
                            <Hidden only={['xs']}>
                                {!props.shouldHideActionButton && (
                                    <Grid item xs={12} sm={3} md={4} sx={cssObject.buttonBlock}>
                                        {selectButton}
                                    </Grid>
                                )}
                            </Hidden>
                        </Grid>
                    </Box>
                </ContentContainer>
                {itemLevel !== 'Not Today' && (
                    <ContentContainer disableGrid background={'bg3'} sx={cssObject.productDetail}>
                        {/* <Hidden xlUp> */}
                        <Grid container>
                            {productImage && (
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={3}
                                    sx={cssObject.imageBox}
                                    justifyContent={'center'}
                                    onClick={() => {
                                        setZoomOpen(!zoomOpen);
                                    }}
                                >
                                    {image}
                                    {zoomIcon}
                                </Grid>
                            )}
                            {!isIAQ ? (
                                <Grid item xs={12} sm={8} md={3} sx={cssObject.benefits}>
                                    <Grid container style={{ paddingTop: '5px' }}>
                                        {systemType.toLowerCase().indexOf('ductless') !== -1 &&
                                            miniSplitTonnage &&
                                            miniSplitTonnage !== '--.-' &&
                                            feature(`${miniSplitTonnage} BTU`)}
                                        {systemType.toLowerCase().indexOf('ductless') !== -1 &&
                                            miniSplitMultiZoneIDUMatchup &&
                                            miniSplitMultiZoneIDUMatchup !== '--.-' &&
                                            feature(`${miniSplitMultiZoneIDUMatchup} Zones`)}

                                        {productSeer && feature(`${productSeer} SEER2`)}
                                        {hpStages && hpStages !== '--.-' && feature(`${hpStages} Heat Pump`)}
                                        {furnaceStages &&
                                            furnaceStages !== '--.-' &&
                                            feature(`${furnaceStages} Furnace`)}
                                        {acStages && acStages !== '--.-' && feature(`${acStages} AC Condenser`)}
                                        {fanSpeeds && fanSpeeds !== '--.-' && feature(`${fanSpeeds} Blower`)}
                                        {motorType && motorType !== '--.-' && feature(`${motorType} Motor`)}
                                        {compressorWarranty &&
                                            compressorWarranty !== '--.-' &&
                                            feature(`${compressorWarranty} Compressor Warranty`)}
                                        {heatExchangerWarranty &&
                                            heatExchangerWarranty !== '--.-' &&
                                            feature(`${heatExchangerWarranty} Heat Exchanger Warranty`)}
                                        {systemType.toLowerCase().indexOf('ductless') === -1 &&
                                            miniSplitTonnage &&
                                            miniSplitTonnage !== '--.-' &&
                                            feature(`${miniSplitTonnage} BTU`)}
                                        {systemType.toLowerCase().indexOf('ductless') === -1 &&
                                            miniSplitMultiZoneIDUMatchup &&
                                            miniSplitMultiZoneIDUMatchup !== '--.-' &&
                                            feature(`${miniSplitMultiZoneIDUMatchup} Zones`)}
                                        {iaqPreFilter && iaqPreFilter !== '--.-' && feature(`${iaqPreFilter}`)}
                                        {iaqPrimaryFiltration &&
                                            iaqPrimaryFiltration !== '--.-' &&
                                            feature(`${iaqPrimaryFiltration}`)}
                                        {iaqSecondaryFiltration &&
                                            iaqSecondaryFiltration !== '--.-' &&
                                            feature(`${iaqSecondaryFiltration}`)}
                                        {iaqUVLight && iaqUVLight !== '--.-' && feature(`${iaqUVLight}`)}
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid item xs={12} sm={8} md={3} sx={cssObject.benefits}>
                                    <Grid container style={{ paddingTop: '5px' }}>
                                        {iaqPreFilter && iaqPreFilter !== '--.-' && feature(`${iaqPreFilter} `)}
                                        {iaqPrimaryFiltration &&
                                            iaqPrimaryFiltration !== '--.-' &&
                                            feature(`${iaqPrimaryFiltration}`)}
                                        {iaqSecondaryFiltration &&
                                            iaqSecondaryFiltration !== '--.-' &&
                                            feature(`${iaqSecondaryFiltration} `)}
                                        {iaqUVLight && iaqUVLight !== '--.-' && feature(`${iaqUVLight}`)}
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={12} md={6} sx={cssObject.productDetailSection}>
                                {productDescription && descriptionLabel}
                                {isMdOnly || isXs ? (
                                    <div>{showFullText ? description(0) : getTextBasedOnSize()}</div>
                                ) : (
                                    <Grid>{description(0)}</Grid>
                                )}
                            </Grid>
                            <Hidden smUp>
                                {!props.shouldHideActionButton && (
                                    <Grid item xs={12} sm={3} md={2} sx={cssObject.buttonBlock}>
                                        {selectButton}
                                    </Grid>
                                )}
                            </Hidden>{' '}
                        </Grid>
                        {/* </Hidden> */}
                    </ContentContainer>
                )}
            </Container>
        </ContentContainer>
    );
}

export default ProductDetail;
