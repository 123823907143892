import { createSelector } from 'reselect';
import { RootState } from '../index';
import { Product } from '../../models/product';

export const selectProductSearchState = (state: RootState) => state.productSearch;
export const selectProducts = createSelector(selectProductSearchState, (state) => state?.products);
export const selectProductsIAQ = createSelector(selectProductSearchState, (state) => state.productsIAQ);
export const selectProductLoading = createSelector(selectProductSearchState, (state) => state.loading);
export const selectIAQLoading = createSelector(selectProductSearchState, (state) => state.isIAQSearchLoading);
export const selectSecondSysProducts = createSelector(selectProductSearchState, (state) => state.productsSystemTwo);
export const selectCombinedProducts = createSelector(selectProductSearchState, (state) => {
    let combinedProducts: Product[] = [];
    if (state.products && state.products.length > 0) {
        combinedProducts = [...state.products];
    }
    if (state.productsSystemTwo && state.productsSystemTwo.length > 0) {
        combinedProducts = [...combinedProducts, ...state.productsSystemTwo];
    }
    return combinedProducts;
});

export const selectNoProduct = createSelector(selectProductSearchState, (state) => state.isNoProduct);
