import { Box, Divider, Grid } from "@mui/material";
import { Paragraph } from "../../components/Paragraph";
import React from "react";
import { Button } from "../../components/Button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUserProfile } from "../../store/selectors/userDetails";
import { AppRoute } from "../../models/route";
import { useTheme } from "@mui/material/styles";
import { Edit } from "@mui/icons-material";
import { EDIT_LABEL } from "../../components/common/constants";

function ProfileDetails() {
  const userDetails = useSelector(selectUserProfile);
  const {
    email,
    phone,
    mobilePhone,
    workPhone,
    homePhone,
    firstname,
    lastname,
  } = userDetails;
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Box sx={{ p: "20px 18px", background: "white", borderRadius: "4px" }}>
      <Grid container spacing={0} alignItems={"center"}>
        <Grid item xs={8} sm={8}>
          <Paragraph headingStyle largeFont bold>
            {`Personal Details`}
          </Paragraph>
        </Grid>
        <Grid item xs={4} sm={4} display={"flex"} justifyContent={"flex-end"}>
          <Button
            onClick={() =>
              navigate(AppRoute.EditContact, {
                state: {
                  fromPage: AppRoute.ReviewSelection,
                  section: EDIT_LABEL.PROFILE,
                },
              })
            }
            outlinedWithBorder
          >
            <Edit sx={{ fontSize: "18px", mr: 0.5 }} /> EDIT
          </Button>
        </Grid>
      </Grid>
      <Divider
        sx={{
          borderColor: theme.palette.divider,
          borderBottomWidth: "2px",
          mt: "15px",
          mb: "12px",
        }}
      />
      <Grid container spacing={0} alignItems={"center"}>
        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6}>
            <Paragraph semiLargeFont sx={{ mb: 0.5 }}>
              Customer First name
            </Paragraph>
            <Paragraph
              responsiveFontSize16
              headingColor
              bold
              sx={{ mb: "6px" }}
            >
              {firstname}
            </Paragraph>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paragraph semiLargeFont sx={{ mb: 0.5 }}>
              Customer Last name
            </Paragraph>
            <Paragraph
              responsiveFontSize16
              headingColor
              bold
              sx={{ mb: "6px" }}
            >
              {lastname}
            </Paragraph>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ pt: 2 }}>
          <Grid item xs={12} sm={6}>
            <Paragraph semiLargeFont sx={{ mb: 0.5 }}>
              Customer Contact Numbers
            </Paragraph>
            {phone && (
              <Paragraph
                responsiveFontSize16
                headingColor
                bold
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Paragraph
                  responsiveFontSize16
                  headingColor
                  bold
                  sx={{ width: "60px" }}
                >
                  Phone:
                </Paragraph>
                {phone}
              </Paragraph>
            )}
            {mobilePhone && (
              <Paragraph
                responsiveFontSize16
                headingColor
                bold
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Paragraph
                  responsiveFontSize16
                  headingColor
                  bold
                  sx={{ width: "60px" }}
                >
                  Cell:
                </Paragraph>
                {mobilePhone}
              </Paragraph>
            )}
            {homePhone && (
              <Paragraph
                responsiveFontSize16
                headingColor
                bold
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Paragraph
                  responsiveFontSize16
                  headingColor
                  bold
                  sx={{ width: "60px" }}
                >
                  Home:
                </Paragraph>
                {homePhone}
              </Paragraph>
            )}
            {workPhone && (
              <Paragraph
                responsiveFontSize16
                headingColor
                bold
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Paragraph
                  responsiveFontSize16
                  headingColor
                  bold
                  sx={{ width: "60px" }}
                >
                  Work:
                </Paragraph>
                {workPhone}
              </Paragraph>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paragraph semiLargeFont sx={{ mb: 0.5 }}>
              Customer Email
            </Paragraph>
            <Paragraph
              responsiveFontSize16
              headingColor
              bold
              sx={{ mb: "6px" }}
            >
              {email}
            </Paragraph>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProfileDetails;
