import { FeaturesList } from  "../../models/ThemeImages";
import config from  "../../components/common/config";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Store } from  "../../models/storeConfig";

export interface StoreState {
  storeCode?: string;
  config?: any;
  features?: FeaturesList;
  error?: Error | null;
  loading: boolean;
  jwtTokenExpireInMins?: number;
  salesEmail?: string;
  salesPhone?: string;
}

const initialState: StoreState = {
  storeCode: config.storeCode,
  loading: false,
};

const storeSlice = createSlice({
  name: "storeSlice",
  initialState: initialState,
  reducers: {
    getStore: (state) => {
      return {
        ...state,
        loading: true,
        error: null,
      };
    },
    getStoreSuccess: (state, action:PayloadAction<Store>) => {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    },
    getStoreError: (state, action:PayloadAction<Error>) => {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    },
  },
});

export default storeSlice.reducer;
export const {
  getStore,
  getStoreSuccess,
  getStoreError
} = storeSlice.actions
