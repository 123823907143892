import React from 'react';
import { Box, Dialog, DialogContent, Grid, Tab, Tabs, useMediaQuery } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { Heading } from '../../components/Heading';
import Close from '../../components/icons/Close';
import ContentContainer from '../../components/ContentContainer';
import { Paragraph } from '../../components/Paragraph';
import { Button } from '../../components/Button';
import DoneIcon from '@mui/icons-material/Done';
import SelectedAddress from '../../components/icons/SelectedAddress';
import OtpErrorIcon from '../../components/icons/OtpErrorIcon';
import { SXCssObject } from '../../models/ThemeImages';
import { AddressValidationFields, AddressValidationModalData, AddressValidationStatus } from '../../models/userProfile';

 interface Address {
    city: string;
    postcode: string;
    region: string;
    addressLine?: string;
}

 enum ContactAddressType {
    Home = 'Job',
    Billing = 'Billing',
}

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        p: {
            xs: '10px 0px',
            sm: '18px 0px',
        },
        background: theme.palette.background.paper,
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: {
            md: theme.spacing(0),
        },
    },
    modalContent: {
        height: 'auto',
        padding: {
            xs: '0px 16px 8px',
            sm: '8px 24px',
        },
    },
    modalTitle: {
        paddingRight: {
            xs: '40px',
            sm: '60px',
        },
    },
    modalTitleContainer: {
        padding: {
            xs: '10px 16px',
            sm: '10px 24px',
        },
        position: 'relative',
        marign: '0px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1.6),
        top: theme.spacing(0.5),
        color: theme.palette.grey[500],
        cursor: 'pointer',
    },
    modalPaper: {
        '& .MuiPaper-root': {
            borderRadius: '4px',
            margin: '24px',
            width: {
                xs: '80%',
                sm: '504px',
            },
            minWidth: {
                xs: '290px',
                sm: 'inherit',
            },
        },
    },
    modalSubTitle: {
        lineHeight: { xs: '16px' },
        fontSize: { xs: '12px', sm: '14px' },
    },
    toggleButtonStyle: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '4px',
        position: 'relative',
        padding: 2,
        margin: theme.spacing(1, 0),
        cursor: 'pointer',
    },
    toggleButtonStyleNew: {
        position: 'relative',
        padding: '0px 5px 10px 5px',
        margin: theme.spacing(0, 0, 1.5, 0),
    },
    selectedToggleStyle: {
        border: `1px solid ${theme.palette.action.hover}`,
    },
    unableTxt: {
        mt: '12px',
        mb: '12px',
    },
    addressEditIcon: {
        position: 'absolute',
        top: '10px',
        right: '10px',
    },
});
interface Props {
    handleClose: () => void;
    handleSubmit: () => void;
    open: boolean;
    addressData: AddressValidationModalData[];
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: '30px 0' }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function AddressValidationModal(props: Props) {
    const theme = useTheme();
    const cssObject = createCssObject(theme);
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const { open, handleClose, handleSubmit, addressData } = props;
    const onlySingleAddressNotValid = addressData.length === 1 && addressData[0].flow;
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const disableProceed = addressData.findIndex(
        (addressItem) => addressItem.status === AddressValidationStatus.Invalid
    );
    const title =
        onlySingleAddressNotValid === ContactAddressType.Home
            ? 'To ensure the accurate delivery we suggest a valid Job address'
            : onlySingleAddressNotValid === ContactAddressType.Billing
            ? 'Please validate Billing address'
            : 'Please validate both Job address and Billing address';
    const DialogTitle = () => {
        return (
            <MuiDialogTitle sx={cssObject.modalTitleContainer}>
                <Box sx={cssObject.modalTitle}>
                    <Heading isPopUp label={`Confirm Your Address`} />
                </Box>
                <Box aria-label="close" sx={cssObject.closeButton} onClick={handleClose}>
                    <Close />
                </Box>
                <Paragraph sx={cssObject.modalSubTitle}>{title}</Paragraph>
            </MuiDialogTitle>
        );
    };
    interface CustomTabPanelProps {
        enteredAddress: Address;
        suggestedAddress?: AddressValidationFields;
        reason?: string;
    }
    const CustomTabPanel = (props: CustomTabPanelProps) => {
        const { enteredAddress, suggestedAddress, reason } = props;
        return (
            <>
                {' '}
                <Box sx={[cssObject.toggleButtonStyleNew]}>
                    <Paragraph component={'span'} responsiveFontSize20 semibold headingColor>
                        Address Entered
                    </Paragraph>
                    <Paragraph semibold>
                        <Box component={'span'}>{enteredAddress.addressLine}</Box>
                    </Paragraph>
                    <Paragraph semibold>
                        {enteredAddress.city + ', ' + enteredAddress.postcode + ', ' + enteredAddress.region}
                    </Paragraph>
                </Box>
                <Box
                    sx={[
                        cssObject.toggleButtonStyle,
                        suggestedAddress ? cssObject.selectedToggleStyle : { cursor: 'default' },
                    ]}
                >
                    {suggestedAddress && (
                        <Box component={'span'} sx={cssObject.addressEditIcon}>
                            <SelectedAddress />
                        </Box>
                    )}
                    {suggestedAddress && (
                        <Paragraph component={'span'} responsiveFontSize20 semibold headingColor>
                            Suggested Valid Address
                        </Paragraph>
                    )}
                    {suggestedAddress ? (
                        <>
                            <Paragraph semibold>
                                <Box component={'span'}>{enteredAddress.addressLine}</Box>
                            </Paragraph>
                            <Paragraph semibold>
                                {suggestedAddress.city +
                                    ', ' +
                                    suggestedAddress.postcode +
                                    ', ' +
                                    suggestedAddress.region}
                            </Paragraph>
                        </>
                    ) : (
                        <Box display={'flex'} sx={cssObject.unableTxt} alignItems={'center'}>
                            <OtpErrorIcon /> &nbsp; &nbsp;{' '}
                            <Paragraph semibold>{reason || 'System unable to suggest any valid address'}</Paragraph>
                        </Box>
                    )}
                </Box>
            </>
        );
    };

    return (
        <Dialog
            onClose={() => {}}
            open={open}
            maxWidth={'sm'}
            disableEscapeKeyDown={true}
            aria-describedby="alert-dialog-slide-description"
            sx={cssObject.modalPaper}
        >
            <Box sx={cssObject.root}>
                <Box sx={cssObject.main}>
                    <DialogTitle></DialogTitle>
                    <DialogContent sx={cssObject.modalContent}>
                        <ContentContainer disableGrid>
                            {onlySingleAddressNotValid ? (
                                <Box sx={{ mt: 2, mb: 4 }}>
                                    <CustomTabPanel
                                        enteredAddress={addressData[0].addressEntered}
                                        suggestedAddress={addressData[0].suggestedAddress}
                                        reason={addressData[0].reason || ''}
                                    />
                                </Box>
                            ) : (
                                <Box>
                                    <Box sx={{ width: '100%', mt: '30px' }}>
                                        <Box
                                            sx={{
                                                filter: 'drop-shadow(0px 3px 13px #e2e0e0)',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    background: 'white',
                                                    width: '400px',
                                                    clipPath: 'polygon(0% 0%, 95% 0, 100% 50%, 95% 100%, 0% 100%)',
                                                    height: '45px',
                                                }}
                                            >
                                                <Tabs
                                                    value={value}
                                                    sx={{
                                                        '& .MuiTabs-indicator': {
                                                            backgroundColor: 'inherit',
                                                        },
                                                        '& .MuiTab-root': {
                                                            color: '#2C3236',
                                                            height: '45px',
                                                        },
                                                        '& .MuiTab-root.Mui-selected': {
                                                            backgroundColor: '#2C3236',
                                                            color: 'common.white',
                                                        },
                                                    }}
                                                    onChange={handleChange}
                                                    aria-label="modal tabs"
                                                >
                                                    <Tab
                                                        sx={{
                                                            width: '200px',
                                                            clipPath:
                                                                'polygon(0% 0%, 93% 0, 100% 50%, 93% 100%, 0% 100%)',
                                                            minHeight: 'inherit',
                                                        }}
                                                        icon={
                                                            addressData[0]?.status !==
                                                            AddressValidationStatus.Invalid ? (
                                                                <DoneIcon fontSize="small" />
                                                            ) : (
                                                                ''
                                                            )
                                                        }
                                                        iconPosition="end"
                                                        label="Job Address"
                                                        {...a11yProps(0)}
                                                    />
                                                    <Tab
                                                        sx={{
                                                            width: '200px',
                                                            clipPath:
                                                                'polygon(0% 0%, 93% 0, 100% 50%, 93% 100%, 0% 100%)',
                                                            minHeight: 'inherit',
                                                        }}
                                                        icon={
                                                            addressData[1]?.status !==
                                                            AddressValidationStatus.Invalid ? (
                                                                <DoneIcon fontSize="small" />
                                                            ) : (
                                                                ''
                                                            )
                                                        }
                                                        iconPosition="end"
                                                        label="Billing Address"
                                                        {...a11yProps(1)}
                                                    />
                                                </Tabs>
                                            </Box>
                                        </Box>
                                        <TabPanel value={value} index={0}>
                                            <CustomTabPanel
                                                enteredAddress={addressData[0].addressEntered}
                                                suggestedAddress={addressData[0].suggestedAddress}
                                                reason={addressData[0].reason || ''}
                                            />
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                            <CustomTabPanel
                                                enteredAddress={addressData[1].addressEntered}
                                                suggestedAddress={addressData[1].suggestedAddress}
                                                reason={addressData[1].reason || ''}
                                            />
                                        </TabPanel>
                                    </Box>
                                </Box>
                            )}
                        </ContentContainer>
                        <Grid
                            container
                            spacing={1}
                            flexDirection={isXs ? 'column-reverse' : 'row'}
                            justifyContent={'space-between'}
                        >
                            <Grid item xs={12} sm={6}>
                                <Button
                                    id={'modal-edit-address'}
                                    outlinedWithBorder
                                    color={'primary'}
                                    isFullWidth={true}
                                    onClick={handleClose}
                                >
                                    EDIT ADDRESS
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    id={'modal-update-address'}
                                    color={'primary'}
                                    isFullWidth={true}
                                    onClick={handleSubmit}
                                    disabled={disableProceed !== -1}
                                >
                                    PROCEED
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Box>
            </Box>
        </Dialog>
    );
}
