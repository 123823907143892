import { Address, AddressType } from './cart';

export interface UserAddressDetails {
    createdAt: string;
    phone: string;
    email: string;
    firstname: string;
    lastname: string;
    addresses: Address[];
}
export interface AddressInput {
    city: string;
    postcode: string;
    region: string;
    addressLine1?: string;
    addressLine2?: string;
}

export interface AddressValidationFields {
    country?: string;
    city?: string;
    region?: string;
    postcode?: string;
}

export interface ValidateAddressResponse {
    status: AddressValidationStatus;
    reason?: string;
    suggestedAddress?: AddressValidationFields;
}
export enum AddressValidationStatus {
    Suggested = 'Suggested',
    Invalid = 'Invalid',
    Valid = 'Valid',
}

export enum AddressOptionsInValidation {
    ENTERED_ADDRESS = 'Address Entered',
    SUGGESTED_ADDRESS = 'Suggested Valid Address',
}
export interface AddressValidationModalData extends ValidateAddressResponse {
    flow: AddressType;
    addressEntered: Address;
    useSame?: boolean;
}

export enum ContactAddressType {
    Home = 'Job',
    Billing = 'Billing',
}