import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Country } from  "../../models/country";

const initialState: Country = {};

const countrySlice = createSlice({
  name: "countrySlice",
  initialState: initialState,
  reducers: {
    getCountryById: (state, action:PayloadAction<{ id: string }>) => {
      return {
        ...state,
        loading: true,
      };
    },
    getCountryByIdSuccess: (state, action:PayloadAction<Country>) => {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    },
    getCountryByIdError: (state, action:PayloadAction<Error>) => {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    },
  },
});

export default countrySlice.reducer;
export const { getCountryById, getCountryByIdSuccess, getCountryByIdError } =
  countrySlice.actions;
