import React from 'react';
import Divider from '@mui/material/Divider';
import { Box, Theme, useTheme } from '@mui/material';
import { CartItem, SystemGroup } from '../models/cart';
import ContentContainer from './ContentContainer';
import Grid from '@mui/material/Grid';
import CurrencyIconNew from './icons/CurrencyIconNew';
import { SXCssObject } from '../models/ThemeImages';
import { currencyFormat, taxCalculation } from './common/NumberUtil';
import { useSelector } from 'react-redux';
import { selectTaxRate } from '../store/selectors/taxRate';

interface Props {
    itemsMain: CartItem[];
    itemsIAQ: CartItem[];
    addOns: CartItem[];
}

const createCssObject = (theme: Theme): SXCssObject => ({
    summaryHeaderSection: {
        display: 'inline-flex',
        backgroundColor: theme.palette.primary.light,
        alignItems: 'center',
        padding: '16px 22px',
    },
    financeSection: {
        display: 'inline-flex',
        backgroundColor: theme.palette.primary.light,
        alignItems: 'center',
        padding: '16px 22px',
        border: `1px solid ${theme.palette.primary.dark}`,
        marginBottom: '10px',
        borderRadius: '4px',
    },
    currencyIconStyle: {
        display: 'flex',
        justifyContent: 'center',
        marginRight: '10px',
        color: 'white',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '4px',
        background: theme.palette.common.white,
    },
    headerText: {
        color: 'white',
    },
    headingPriceDesc: {
        color: theme.palette.text.primary,
        marginBottom: '4px',
        fontSize: '0.875rem',
    },
    headingPriceText: {
        fontSize: '1.25rem',
        color: theme.palette.action.active,
        fontWeight: theme.typography.fontWeightMedium,
    },
    summaryItems: {
        backgroundColor: theme.palette.primary.light,
        padding: '10px 22px',
    },
    summarySubHeaderStyle: {
        color: theme.palette.action.active,
        fontSize: '0.875rem',
        fontWeight: theme.typography.fontWeightMedium,
        padding: '10px 0px',
    },
    summarySubHeaderStyle2: {
        lineHeight: '15px',
        color: theme.palette.text.primary,
        fontSize: '0.75rem',
        fontWeight: 'normal',
    },
    summarySubHeaderStyle3: {
        color: theme.palette.action.active,
        fontSize: '0.875rem',
        padding: '15px 0px',
        fontWeight: theme.typography.fontWeightMedium,
    },
    summaryContent: {
        color: theme.palette.action.active,
        padding: '10px 0px',
        fontWeight: theme.typography.fontWeightMedium,
    },
    summaryContent2: {
        color: theme.palette.action.active,
        fontSize: '0.75rem',
        fontWeight: theme.typography.fontWeightMedium,
    },
    summaryContent3: {
        color: theme.palette.action.active,
        padding: '15px 0px',
        fontSize: '1.25rem',
        fontWeight: theme.typography.fontWeightMedium,
    },
    dividerStyle: {
        backgroundColor: theme.palette.primary.dark,
        height: '1px',
        margin: '0px -22px 0px -22px',
    },
    dividerStyle2: {
        backgroundColor: theme.palette.primary.dark,
        height: '1px',
        margin: '0px -22px 15px -22px',
    },
    dividerStyle3: {
        backgroundColor: theme.palette.primary.dark,
        height: '1px',
        margin: '-18px -22px 10px -22px',
    },
    cartSummary: {
        border: `1px solid ${theme.palette.primary.main}`,
        marginBottom: '0px',
    },
    minMaxIcon: { display: 'flex', justifyContent: 'end' },
});
export default function CartSummary(props: Props) {
    const { itemsMain, itemsIAQ, addOns } = props;

    const cssObject: SXCssObject = createCssObject(useTheme());
    const taxRate = useSelector(selectTaxRate);

    //revamped MAIN Products
    const firstSystemMain = itemsMain && itemsMain.find((item) => item.systemGroup === SystemGroup.SystemGroup1);

    const secondSystemMain = itemsMain && itemsMain.find((item) => item.systemGroup === SystemGroup.SystemGroup2);

    const firstMainSystemLevel =
        firstSystemMain && firstSystemMain.productAttributes && firstSystemMain.productAttributes.level;

    const secondMainSystemLevel =
        secondSystemMain && secondSystemMain.productAttributes && secondSystemMain.productAttributes.level;

    const firstMainSystemType =
        firstSystemMain && firstSystemMain.productAttributes && firstSystemMain.productAttributes.systemType;

    const secondMainSystemType =
        secondSystemMain && secondSystemMain.productAttributes && secondSystemMain.productAttributes.systemType;

    const firstMainSystemPrice = firstSystemMain && firstSystemMain.price;

    const secondMainSystemPrice = secondSystemMain && secondSystemMain.price;

    //revamped IAQ Products
    const firstSystemIAQ = itemsIAQ && itemsIAQ.find((item) => item.systemGroup === SystemGroup.SystemGroup1);

    const secondSystemIAQ = itemsIAQ && itemsIAQ.find((item) => item.systemGroup === SystemGroup.SystemGroup2);

    const firstIAQSystemLevel =
        firstSystemIAQ && firstSystemIAQ.productAttributes && firstSystemIAQ.productAttributes.level;

    const secondIAQSystemLevel =
        secondSystemIAQ && secondSystemIAQ.productAttributes && secondSystemIAQ.productAttributes.level;

    const firstIAQSystemPrice = firstSystemIAQ && firstSystemIAQ.price;
    const secondIAQSystemPrice = secondSystemIAQ && secondSystemIAQ.price;

    let addOnSubTotal = 0;
    const addonProducts: any[] = [];
    itemsIAQ &&
        addOns.length > 0 &&
        addOns.map((i: CartItem) => {
            const quantity: number = i.quantity;
            for (let j = 0; j < quantity; j++) {
                addOnSubTotal += i.price;
                addonProducts.push(i);
            }
            return i;
        });

    const subTotal =
        (firstMainSystemPrice || 0) +
        (firstIAQSystemPrice || 0) +
        (addOnSubTotal || 0) +
        (secondMainSystemPrice || 0) +
        (secondIAQSystemPrice || 0);

    const taxes = taxCalculation(subTotal, taxRate);
    const total = subTotal + taxes;

    return (
        <ContentContainer disableGrid background={'bg2'}>
            {/* <Grid container item xs={12} sx={cssObject.financeSection}>
                <Box sx={cssObject.currencyIconStyle}>
                    <FinanceIconNew />
                </Box>
                <Box>
                    <Box sx={cssObject.headingPriceDesc}>{'Instant Online Financing'}</Box>
                    <Box sx={cssObject.headingPriceText}>
                        {currencyFormatNoDigit.format(financialPriceCalc(total)) + '/mo'}
                    </Box>
                </Box>
            </Grid>
            <br /> */}
            <Grid container direction={'column'} sx={cssObject.cartSummary}>
                <Grid container item xs={12} sx={cssObject.summaryHeaderSection}>
                    <Box sx={cssObject.currencyIconStyle}>
                        <CurrencyIconNew />
                    </Box>
                    <Box>
                        <Box sx={cssObject.headingPriceDesc}>{'Overall Itemized Cost'}</Box>
                        <Box sx={cssObject.headingPriceText}>{currencyFormat.format(total)}</Box>
                    </Box>
                </Grid>

                <React.Fragment>
                    <Grid item xs={12} container sx={cssObject.summaryItems}>
                        <Grid item xs={8}>
                            <Box sx={cssObject.summarySubHeaderStyle2}>{'First System'}</Box>
                            <ContentContainer disableGrid sx={cssObject.summarySubHeaderStyle}>
                                <Box> {firstMainSystemLevel + ' HVAC'}</Box>
                                <Box sx={cssObject.summarySubHeaderStyle2}>{firstMainSystemType}</Box>
                            </ContentContainer>
                        </Grid>
                        <Grid item xs={4} container justifyContent={'flex-end'} id={'summary-subtotal'}>
                            <Box sx={cssObject.summarySubHeaderStyle2}></Box>
                            <ContentContainer disableGrid sx={cssObject.summaryContent}>
                                {' '}
                                <br />
                                {currencyFormat.format(firstMainSystemPrice || 0)}
                            </ContentContainer>
                        </Grid>

                        {/* IAQ */}
                        {firstSystemIAQ && (
                            <Grid item xs={12} container>
                                <Grid item xs={8}>
                                    <ContentContainer disableGrid sx={cssObject.summarySubHeaderStyle}>
                                        {firstIAQSystemLevel + ' IAQ HVAC'}
                                    </ContentContainer>
                                </Grid>
                                <Grid item xs={4} container justifyContent={'flex-end'} id={'summary-subtotal'}>
                                    <ContentContainer disableGrid sx={cssObject.summaryContent}>
                                        {' '}
                                        {currencyFormat.format(firstIAQSystemPrice || 0)}
                                    </ContentContainer>
                                </Grid>
                            </Grid>
                        )}
                        {secondSystemMain && (
                            <Grid container>
                                <Grid item xs={12}>
                                    <Divider sx={cssObject.dividerStyle}></Divider>
                                </Grid>
                                <Grid item xs={8}>
                                    <br />
                                    <Box sx={cssObject.summarySubHeaderStyle2}>{'Second System'}</Box>
                                    <ContentContainer disableGrid sx={cssObject.summarySubHeaderStyle}>
                                        <Box> {secondMainSystemLevel + ' HVAC'}</Box>
                                        <Box sx={cssObject.summarySubHeaderStyle2}>{secondMainSystemType}</Box>
                                    </ContentContainer>
                                </Grid>
                                <Grid item xs={4} container justifyContent={'flex-end'} id={'summary-subtotal'}>
                                    <ContentContainer disableGrid sx={cssObject.summaryContent}>
                                        {' '}
                                        <br />
                                        <br />
                                        {currencyFormat.format(secondMainSystemPrice || 0)}
                                    </ContentContainer>
                                </Grid>
                            </Grid>
                        )}

                        {/* IAQ */}
                        {secondSystemIAQ && (
                            <Grid item xs={12} container>
                                <Grid item xs={8}>
                                    <ContentContainer disableGrid sx={cssObject.summarySubHeaderStyle}>
                                        {secondIAQSystemLevel + ' IAQ HVAC'}
                                    </ContentContainer>
                                </Grid>
                                <Grid item xs={4} container justifyContent={'flex-end'} id={'summary-subtotal'}>
                                    <ContentContainer disableGrid sx={cssObject.summaryContent}>
                                        {' '}
                                        {currencyFormat.format(secondIAQSystemPrice || 0)}
                                    </ContentContainer>
                                </Grid>
                            </Grid>
                        )}
                        {addonProducts && addonProducts?.length > 0 && (
                            <Grid item xs={12}>
                                <Divider sx={cssObject.dividerStyle2}></Divider>
                            </Grid>
                        )}
                        {addonProducts && addonProducts?.length > 0 && (
                            <Grid sx={cssObject.summarySubHeaderStyle2}>Other Products</Grid>
                        )}
                        {addonProducts?.map((addOnProduct: any, index: number) => (
                            <Grid key={index} item xs={12} container>
                                <Grid item xs={9}>
                                    <ContentContainer disableGrid sx={cssObject.summarySubHeaderStyle}>
                                        {addOnProduct.name.slice(0, 16)}...
                                    </ContentContainer>
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                    container
                                    style={{ justifyContent: 'flex-end' }}
                                    id={'summary-subtotal'}
                                >
                                    <ContentContainer disableGrid sx={cssObject.summaryContent}>
                                        {' '}
                                        {currencyFormat.format(addOnProduct.price || 0)}
                                    </ContentContainer>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    {/* Subtotal */}
                    <Grid item xs={12} container sx={cssObject.summaryHeaderSection}>
                        <Grid item xs={12}>
                            <Divider sx={cssObject.dividerStyle3}></Divider>
                        </Grid>
                        <Grid item xs={6}>
                            <ContentContainer disableGrid sx={cssObject.summarySubHeaderStyle2}>
                                Subtotal
                            </ContentContainer>
                        </Grid>
                        <Grid item xs={6} container justifyContent={'flex-end'} id={'summary-taxes'}>
                            <ContentContainer disableGrid sx={cssObject.summaryContent2}>
                                {currencyFormat.format(subTotal)}
                            </ContentContainer>
                        </Grid>
                    </Grid>

                    {/* Taxes */}
                    <Grid item xs={12} container sx={cssObject.summaryHeaderSection}>
                        <Grid item xs={12}>
                            <Divider sx={cssObject.dividerStyle3}></Divider>
                        </Grid>
                        <Grid item xs={6}>
                            <ContentContainer disableGrid sx={cssObject.summarySubHeaderStyle2}>
                                Taxes
                            </ContentContainer>
                        </Grid>

                        <Grid item xs={6} container justifyContent={'flex-end'} id={'summary-taxes'}>
                            <ContentContainer disableGrid sx={cssObject.summaryContent2}>
                                {' '}
                                {currencyFormat.format(taxes)}
                            </ContentContainer>
                        </Grid>
                    </Grid>
                    {/* Total */}
                    <Grid item xs={12} container sx={cssObject.summaryHeaderSection}>
                        <Grid item xs={12}>
                            <Divider sx={cssObject.dividerStyle3}></Divider>
                        </Grid>
                        <Grid item xs={6} container>
                            <ContentContainer disableGrid sx={cssObject.summarySubHeaderStyle3}>
                                Total
                            </ContentContainer>
                        </Grid>
                        <Grid item xs={6} container justifyContent={'flex-end'} id={'summary-total'}>
                            <ContentContainer disableGrid sx={cssObject.summaryContent3}>
                                {' '}
                                {currencyFormat.format(total)}
                            </ContentContainer>
                        </Grid>
                    </Grid>
                </React.Fragment>
            </Grid>
        </ContentContainer>
    );
}
