export enum AppRoute {
    Home = '/',
    AboutYourHome = '/',
    EquipmentOptions = '/equipment-options',
    IAQOptions = '/iaq-options',
    EquipmentSelection = '/equipment-selection',
    Schedule = '/schedule',
    ReviewSelection = '/review-selection',
    EditContact = '/edit-contact'
}
