import { ProductCriteria } from '../../models/productCriteria';
import { Product } from '../../models/product';
import { ProductAttribute, ProductAttributeId } from '../../models/productAttributes';
import { http } from '../..';

export const searchProduct = (criteria: ProductCriteria) =>
    http().get<Product[]>('/products/search', {
        params: { criteria },
    });
export const changeProduct = (sku: any) =>
    http().get<Product[]>('/Products/similarRecomendations', {
        params: { sku: sku.sku },
    });
export const getProductAttribute = (id: ProductAttributeId) =>
    http().get<ProductAttribute>('/products/customAttributes', {
        params: { id },
    });

export const getProductBySkuService = (sku: string) =>
    http().get<Product>('/products/getBySku', {
        params: { sku },
    });

