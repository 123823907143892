import * as React from "react"
import { SVGProps } from "react"
const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <defs>
      <style>
        {".b{fill:none;stroke:#fff;stroke-linecap:round;stroke-width:2.5px}"}
      </style>
    </defs>
    <g transform="translate(-2 -2)">
      <circle
        cx={10}
        cy={10}
        r={10}
        style={{
          fill: "#333",
        }}
        transform="rotate(180 11 11)"
      />
      <path d="M12 11v5M12 7h0" className="b" />
    </g>
  </svg>
)
export default InfoIcon
