import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from '../common/Utilities';
import * as React from 'react';

function EditIconSmall(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
            <style />
            <path
                id="prefix__Layer"
                d="M14.25 5.18l-6.84 8.63-2.85.66.08-2.83 6.84-8.62 2.77 2.16z"
                fill="none"
                stroke={`${themePrimaryMain}`}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.334}
            />
        </svg>
    );
}

export default EditIconSmall;
