import React from "react";
import Grid from "@mui/material/Grid";
import { Box, Hidden, useMediaQuery } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { Button } from "./Button";
import { Paragraph } from "./Paragraph";
import CollapseIcon from "./icons/CollapseIcon";
import ExpandIcon from "./icons/ExpandIcon";
import { SXCssObject } from "../models/ThemeImages";
import { ThemeWithAdditionalData } from "../storeDetails/theme";
import { useSelector } from "react-redux";
import { selectUserProfile } from "../store/selectors/userDetails";

const createCssObject = (theme: Theme): SXCssObject => ({
  root: {
    backgroundColor: theme.palette.common.white,
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: {
      sm: "4px",
    },
    minHeight: {
      sm: "150px",
    },
    padding: {
      xs: "18px 16px 18px",
      sm: "20px 18px",
    },
    border: {
      xs: "none",
      sm: "inherit",
    },
    borderBottom: {
      xs: "1px solid #E0E0E0",
      sm: "inherit",
    },
  },
  addressLayout: {
    transition: "200ms",
    paddingBottom: {
      xs: "16px",
      md: "inherit",
    },
    paddingTop: {
      xs: "8px",
      sm: "inherit",
    },
  },
  dividerStyle: {
    border: "none",
    borderTop: `2px solid ${theme.palette.divider}`,
    margin: "10px 0px 8px",
    display: {
      xs: "none",
      sm: "inherit",
    },
  },
  addBillingAddressSection: {
    backgroundColor: "white",
    padding: "12px",
    height: "100%",
    borderRadius: "4px",
  },
  addBillingAddressContainer: {
    height: "100%",
    border: `1px solid ${theme.palette.primary.contrastText}`,
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    minHeight: {
      xs: "50px",
      sm: "150px",
    },
  },
  addBillingAddressWrapper: {
    display: "flex",
    alignItems: "center",
  },
  reduceOpacity: {
    opacity: 0.4,
  },
});

interface Props {
  onClickHome: () => void;
  onClickBilling: () => void;
}

function AddressComponent(props:Props) {
  const cssObject: SXCssObject = createCssObject(useTheme());
  const [isExpandedHome, setExpandedHome] = React.useState(false);
  const [isExpandedBilling, setExpandedBilling] = React.useState(false);
  const theme = useTheme<ThemeWithAdditionalData>();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isDownSm = useMediaQuery(theme.breakpoints.down("md"));
  const userDetails = useSelector(selectUserProfile);
  const toggleExpandedHome = (currentState: boolean) => {
    setExpandedHome(!currentState);
  };
  const toggleExpandedBilling = (currentState: boolean) => {
    setExpandedBilling(!currentState);
  };
  return (
    <Grid container spacing={2}>
      <Grid item container xs={12} sm={6}>
        <Grid container sx={cssObject.root}>
          <Grid
            item
            container
            style={{ flex: "0 1 auto" }}
            justifyContent={"space-between"}
          >
            <Grid item>
              <Paragraph headingStyle largeFont bold>
                {`Job Address`}
              </Paragraph>
            </Grid>
            <Hidden smUp>
              <Grid
                item
                onClick={() => toggleExpandedHome(isExpandedHome)}
                style={{ display: "flex", alignItems: "center" }}
              >
                {isExpandedHome ? <CollapseIcon /> : <ExpandIcon />}
              </Grid>
            </Hidden>
          </Grid>
          <Grid item xs={12} style={{ flex: "0 1 auto" }}>
            <Box component={"hr"} sx={cssObject.dividerStyle} />
          </Grid>
          {(isExpandedHome || !isXs) && (
            <Grid
              item
              container
              style={{ flex: "0 1 auto" }}
              justifyContent={"space-between"}
            >
              <Grid item xs={12} md={7} sx={cssObject.addressLayout}>
              {/* <Paragraph headingColor semibold responsiveFontSize16 sx={{pb:1}}>My Home 01:</Paragraph> */}
              <Paragraph sx={{mt:2}} headingColor>{userDetails?.jobAddress}</Paragraph>
                <Paragraph>
                  {userDetails?.jobCity +
                    ", " +
                    userDetails?.jobState +
                    " " +
                    userDetails?.jobZip}
                </Paragraph>
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                container
                alignItems={"flex-end"}
                justifyContent={"flex-end"}
              >
                <Button
                  color={"primary"}
                  outlinedWithBorder
                  onClick={props.onClickHome}
                  // disabled={isBtnDisabled}
                  isFullWidth={isDownSm}
                >
                  Change Address
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={6}>
        <Grid container sx={cssObject.root}>
          <Grid
            item
            container
            style={{ flex: "0 1 auto" }}
            justifyContent={"space-between"}
          >
            <Grid item>
              <Paragraph headingStyle largeFont bold>
                {`Billing Address`}
              </Paragraph>
            </Grid>
            <Hidden smUp>
              <Grid
                item
                onClick={() => toggleExpandedBilling(isExpandedBilling)}
                style={{ display: "flex", alignItems: "center" }}
              >
                {isExpandedBilling ? <CollapseIcon /> : <ExpandIcon />}
              </Grid>
            </Hidden>
          </Grid>
          <Grid item xs={12} style={{ flex: "0 1 auto" }}>
            <Box component={"hr"} sx={cssObject.dividerStyle} />
          </Grid>
          {(isExpandedBilling || !isXs) && (
            <Grid
              item
              container
              style={{ flex: "0 1 auto" }}
              justifyContent={"space-between"}
            >
              <Grid item xs={12} md={7} sx={cssObject.addressLayout}>
                {/* <Paragraph headingColor semibold responsiveFontSize16 sx={{pb:1}} >My Home 01:</Paragraph> */}
                <Paragraph sx={{mt:2}} headingColor>{userDetails?.address}</Paragraph>
                <Paragraph>
                  {userDetails?.city +
                    ", " +
                    userDetails?.state +
                    " " +
                    userDetails?.zip}
                </Paragraph>
               
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                container
                alignItems={"flex-end"}
                justifyContent={"flex-end"}
              >
                <Button
                  color={"primary"}
                  outlinedWithBorder
                  onClick={props.onClickBilling}
                  // disabled={isBtnDisabled}
                  isFullWidth={isDownSm}
                >
                  Change Address
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
export default AddressComponent;
