import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import { useLocation } from "react-router-dom";
import ContentContainer from "./ContentContainer";
import { Link } from "./Link";
import useMediaQuery from "@mui/material/useMediaQuery";
import Hamburger from "./icons/Hamburger";
import { Box, Divider, Link as MuiLink, Stack } from "@mui/material";
import { SXCssObject } from "../models/ThemeImages";
import { NavigationButton } from "./NavigationButton";
import { CloseButtonNew } from "./WizardButton";
import { Logo } from "./Logo";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import { FPL_STRESS_FREE_ROUTE } from "./common/constants";

const createCssObject = (theme: Theme): SXCssObject => ({
  menuIcon: {
    border: `1px solid #CCCCCC`,
    width: "40px",
    height: "40px",
    "& svg": {
      marginTop: "-4px",
    },
  },
  primaryIcon: {
    color: theme.palette.text.primary,
  },
  menuItem: {
    justifyContent: "center",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  header: {},
  paper: {
    backgroundSize: "cover",
    backgroundColor: "#ffffff !important",
    backgroundPosition: {
      xs: "inherit",
      sm: "initial",
    },
  },
  dividerLineStyle: {
    backgroundColor: theme.palette.primary.main,
    width: "16px",
    height: "2px",
    display: "inline-flex",
    marginBottom: "10px",
  },
  socialLink: {
    display: "flex",
    alignItems: "center",
  },
});

interface Menu {
  text: string;
  route: string;
  isButton: boolean;
}

const menus: Menu[] = [
  {
    text: "Home",
    route: FPL_STRESS_FREE_ROUTE.HOME,
    isButton: false,
  },
  {
    text: "About",
    route: FPL_STRESS_FREE_ROUTE.ABOUT,
    isButton: false,
  },
  {
    text: "Benefits",
    route: FPL_STRESS_FREE_ROUTE.BENEFITS,
    isButton: false,
  },
  {
    text: "Blogs",
    route: FPL_STRESS_FREE_ROUTE.BLOGS,
    isButton: true,
  },
  {
    text: "FAQs",
    route: FPL_STRESS_FREE_ROUTE.FAQS,
    isButton: true,
  },
  {
    text: "Promos",
    route: FPL_STRESS_FREE_ROUTE.PROMOS,
    isButton: true,
  },
];
const topMenus: Menu[] = [
  {
    text: "Customer Login",
    route: FPL_STRESS_FREE_ROUTE.CUSTOMER_LOGIN,
    isButton: false,
  },
  {
    text: "Contractor Login",
    route: FPL_STRESS_FREE_ROUTE.CONTRACTOR_LOGIN,
    isButton: false,
  },
  {
    text: "1-833-970-3579",
    route: FPL_STRESS_FREE_ROUTE.PHONE,
    isButton: false,
  },
];
export default function MenuDrawer() {
  const classes: SXCssObject = createCssObject(useTheme());
  const [state, setState] = React.useState(false);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const location = useLocation();
  const path = location.pathname;
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState(open);
    };
  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <ContentContainer size={"small"}>
        <Grid sx={classes.header} item xs={12} container>
          {isXs && (
            <Grid item xs={12} container justifyContent={"flex-end"}>
              <CloseButtonNew
                id={"menu-close-ico"}
                onClick={toggleDrawer(false)}
                square
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={6}
            container
            justifyContent={isXs ? "center" : "flex-start"}
          >
            <Logo id="menu-logo" />
          </Grid>
          {!isXs && (
            <Grid
              item
              xs={6}
              container
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <CloseButtonNew
                id={"menu-close-ico"}
                onClick={toggleDrawer(false)}
                square
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 1.2 }} />
          <List>
            {menus.map((menu, index) => (
              <ListItem
                sx={classes.menuItem}
                component={"div"}
                button
                key={`menu-${index}`}
              >
                <ListItemText
                  sx={{
                    "& a": {
                      color: "#333333",
                      textTransform: "inherit",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    },
                  }}
                  primary={
                    <Link
                      label={menu.text}
                      color={"primary"}
                      href={menu.route}
                    />
                  }
                  secondary={
                    <Box
                      component={"span"}
                      sx={[
                        [menu.route].includes(path) && classes.dividerLineStyle,
                      ]}
                    />
                  }
                />
              </ListItem>
            ))}
          </List>
          <Divider sx={{ my: 1.2 }} />
          <List>
            {topMenus.map((menu, index) => (
              <ListItem
                sx={classes.menuItem}
                component={"div"}
                button
                key={`menu-${index}`}
              >
                <ListItemText
                  sx={{
                    "& a": {
                      textTransform: "inherit",
                      fontSize: "1rem",
                      fontWeight: "normal",
                    },
                  }}
                  primary={
                    <Link
                      label={menu.text}
                      color={"primary"}
                      href={menu.route}
                    />
                  }
                  secondary={
                    <Box
                      component={"span"}
                      sx={[
                        [menu.route].includes(path) && classes.dividerLineStyle,
                      ]}
                    />
                  }
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </ContentContainer>
    </div>
  );

  return (
    <div>
      <NavigationButton
        color={"primary"}
        onClick={toggleDrawer(true)}
        icon={<Hamburger />}
      />
      <Drawer
        PaperProps={{ sx: classes.paper }}
        anchor={"top"}
        open={state}
        onClose={toggleDrawer(false)}
      >
        {list()}
      </Drawer>
    </div>
  );
}
