import { SXCssObject } from "../../models/ThemeImages";
import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Paragraph } from "../../components/Paragraph";
import Divider from "@mui/material/Divider";
import HeadlineParagraph from "../../components/HeadlineParagraph";

const createCssObject = (theme: Theme): SXCssObject => ({
  footer: {
    position: "relative",
    bottom: 0,
    left: 0,
    width: "100%",
  },
  salesTaxDesc: {
    py: 3,
  },
  dividerBottom: {
    pb: 2,
  },
});
const DisclaimerText = () => {
  const cssObject: SXCssObject = createCssObject(useTheme());
  return (
    <>
      <Grid item xs={12} container spacing={1} sx={cssObject.salesTaxDesc}>
        <Grid item xs={12}>
          <HeadlineParagraph
            headlineSize={"h4"}
            label={"*System Pricing and Taxes"}
          />
        </Grid>
        <Grid item xs={12}>
          <Paragraph>
            The prices displayed are the fully installed prices. These prices
            include a national average 8.25% sales tax rate. Once you provide
            your complete address, your corrected local sales tax rate will be
            applied any changes will be reflected in the System Pricing or
            Finance Pricing before you proceed.
          </Paragraph>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={cssObject.dividerBottom}>
        <Divider />{" "}
      </Grid>
      <Grid container>
        <Grid item xs={12} sx={cssObject.footer}>
          <Paragraph italic id={"wizard-tips"}>
            Let’s not forget our 100% Homeowner Satisfaction Pledge. Our pledge
            to you is that you will be 100% satisfied with the installation and
            operation of your new installed HVAC system or we’ll make it right!
            We’re able to make that pledge because; We first take the guesswork
            and hassle out of selecting and scheduling your new installed HVAC
            system! Next, we back that up with some of the best warranties, HVAC
            contractors, and products in the industry! And finally, Homeowners
            really appreciate our simple online payment methods, knowing you pay
            only when the work is done! Now let’s get started selecting the
            right HVAC system for your home so you too can be our next 100%
            Satisfied Homeowner, it’s our Pledge to you.
          </Paragraph>
        </Grid>
      </Grid>{" "}
    </>
  );
};
export default DisclaimerText;
