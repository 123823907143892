import React, { MouseEventHandler } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';
import { SXCssObject } from '../models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        minWidth: theme.spacing(4),
        padding: 0,
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.divider}`,
        '&:hover': {
            backgroundColor: '#E1E1E1',
            border: `1px solid ${theme.palette.success.main}`,
        },
    },
    greyBackground: {
        backgroundColor: '#F5F5F5',
    },
});

interface Props {
    id?: string;
    onClick?: MouseEventHandler;
    color: 'primary' | 'secondary';
    icon: JSX.Element;
}

export function NavigationButton(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const { color, icon: Icon, ...other } = props;
    return (
        <MuiButton
            disableElevation
            sx={[cssObject.root, color === 'primary' && cssObject.greyBackground]}
            variant={'outlined'}
            {...other}
        >
            {Icon}
        </MuiButton>
    );
}
