import { Observable, of } from 'rxjs';
import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { catchError, map, switchMap } from 'rxjs/operators';

import { getProductAttribute as getAttributeService } from '../services/productSevices';
import { AxiosResponse } from 'axios';
import { getProductAttribute, getProductAttributeError, getProductAttributeSuccess } from '../slice/productAttributesSlice';
import { showSnackbarError } from '../../components/common/Snackbar/SnackbarHelper';
import { ProductAttribute } from '../../models/productAttributes';

export const getProductAttribute$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(getProductAttribute),
        switchMap((action) =>
            getAttributeService(action.payload.id).pipe(
                map((resp: AxiosResponse<ProductAttribute>) =>
                    getProductAttributeSuccess({ id: action.payload.id, value: resp.data })
                ),
                catchError((error) => {
                    showSnackbarError(`Unable to get product attribute: ${action.payload.id}`);
                    return of(getProductAttributeError(error));
                })
            )
        )
    );
