import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EquipmentOptions from './EquipmentOptions';
import { CartItem, ItemType, SystemGroup } from '../../models/cart';
import { selectProductLoading, selectProducts, selectSecondSysProducts } from '../../store/selectors/productSearch';
import { selectProductCriteriaState } from '../../store/selectors/productCriteria';

import { selectPreCartMain } from '../../store/selectors/selection';
import { Product, RelatedProduct } from '../../models/product';
import { resetIAQSelection, setAddonSelection, setMainSelection } from '../../store/slice/selectionSlice';
import { AppRoute } from '../../models/route';
import { useLocation, useNavigate } from 'react-router-dom';

function EquipmentOptionContainer() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const criteria = useSelector(selectProductCriteriaState);
    const products = useSelector(selectProducts);
    const productsSecondSystem = useSelector(selectSecondSysProducts);
    const preCartMain: CartItem[] = useSelector(selectPreCartMain);
    // const preCartAddOn: CartItem[] = useSelector(selectPreCartAddon);
    const isproductSearchLoading: boolean = useSelector(selectProductLoading);
    const location: any = useLocation();
    // if ((!!products || products.length <= 0) && (!!productsSecondSystem || productsSecondSystem.length <= 0)) {
    //     return <Navigate to={AppRoute.Home} />;
    // }

    const secondSystem = location.state && location.state.isSecondSystem;
    const changingSystemGroup = location.state && location.state.changingSystemGroup;
    const onBackSystemGroup = location.state && location.state.onBackSystemGroup;

    const isByClickingChangeinSelection = location.state && location.state.isByClickingChangeinSelection;
    const isByClickingEditInWizardPage = location.state && location.state && location.state.fromEditInWizard;

    const preCartItemMain = preCartMain?.find(
        (cartItem) => cartItem.itemType === ItemType.MainSystem && cartItem.systemGroup === onBackSystemGroup
    );
    const sku = preCartItemMain && preCartItemMain.sku;

    const replaceOrSetMain = (itemMain: CartItem) => {
        let precartMainSysList = preCartMain;
        let oldSys: CartItem[], filteredIAQItems: CartItem[];
        if (changingSystemGroup === SystemGroup.SystemGroup1 || !secondSystem) {
          oldSys = precartMainSysList.filter(
            (item) => item.systemGroup === SystemGroup.SystemGroup1
          );
        } else {
          oldSys = precartMainSysList.filter(
            (item) => item.systemGroup === SystemGroup.SystemGroup2
          );
        }
        filteredIAQItems = precartMainSysList.filter((item) => !oldSys.includes(item));
        let newMainSelection = [...filteredIAQItems, itemMain];
        dispatch(setMainSelection(newMainSelection));
        return newMainSelection;
    };
    const setMainItem = (product: Product) => {
        return {
            categories: product.categories || [],
            name: product.name,
            sku: product.sku,
            quantity: 1,
            id: product.id,
            description: product.description,
            image: product.image,
            price: product.price,
            thumbnail: product.thumbnail,
            totalPrice: product.price,
            productAttributes: product.productAttributes,
            relatedProducts: product.relatedProducts,
            systemGroup:
                changingSystemGroup === SystemGroup.SystemGroup2 || secondSystem
                    ? SystemGroup.SystemGroup2
                    : SystemGroup.SystemGroup1,
            itemType: ItemType.MainSystem,
        };
    };

    const getUniqueAddOnsFromProduct = (addOnRelatedProducts: RelatedProduct[]) => {
        const uniqueValuesSet = new Set();
        const uniqueAddons = addOnRelatedProducts?.filter((obj) => {
            const isPresentInSet = uniqueValuesSet.has(obj.sku);
            uniqueValuesSet.add(obj.sku);
            return !!isPresentInSet;
        });
        const nonIAQAddons =
        uniqueAddons &&
        uniqueAddons.filter((uniqueAddon) => {
            return uniqueAddon && uniqueAddon.sku && uniqueAddon.sku.indexOf('IAQ') === -1;
        });
        return nonIAQAddons;
    };
    const handleOnSelectProduct = (product: Product, isByClickingChangeinSelection: boolean) => {
        const selectedMainItem = setMainItem(product);
        const updatedPreCartMain = replaceOrSetMain(selectedMainItem);
        if (isByClickingChangeinSelection || secondSystem) {
            //change in selection & wizard second system flows.
            let combinedRelatedProducts: RelatedProduct[] = [];
            updatedPreCartMain.forEach((mainProduct) => {
                mainProduct.relatedProducts.forEach((item) => {
                    // ToBeCheck
                    // const isAddonInSelectionReducer = preCartAddOn.find((addOn) => addOn.sku === item.sku);
                    // item.quantity = (isAddonInSelectionReducer && isAddonInSelectionReducer.quantity) || 0;
                    combinedRelatedProducts.push(item);
                });
            });
            let nonIAQAddons = getUniqueAddOnsFromProduct(combinedRelatedProducts);
            dispatch(setAddonSelection({ addOnsList: [...nonIAQAddons], shouldReplace: true }));
        } else {
            //wizard flow
            const nonIaqAddOns = getUniqueAddOnsFromProduct(product.relatedProducts);
            nonIaqAddOns.map((item) => ({
                ...item,
                quantity:0
            }));
            dispatch(setAddonSelection({ addOnsList: [...nonIaqAddOns], shouldReplace: true }));
        }
    };
    const shouldShowSecondSysProducts =
        (changingSystemGroup && changingSystemGroup === SystemGroup.SystemGroup2) || secondSystem;
    return (
        <EquipmentOptions
            //based on the flag we will send the respective products as props
            products={shouldShowSecondSysProducts ? productsSecondSystem : products}
            isSecondSystemFlow={secondSystem}
            criteria={criteria}
            loading={isproductSearchLoading} //loading will be true when product search in progress
            selectionMainSku={isByClickingChangeinSelection}
            editMainProductSku={isByClickingEditInWizardPage}
            mainSku={sku}
            onSelectProduct={(product: Product) => {
                handleOnSelectProduct(product, isByClickingChangeinSelection);
                if (isByClickingEditInWizardPage) {
                    handleEditClick(product);
                    return;
                } else {
                    //Wizard flow
                    const sysGroupToChange = secondSystem ? SystemGroup.SystemGroup2 : SystemGroup.SystemGroup1;
                    dispatch(resetIAQSelection({ systemGroupToReset: sysGroupToChange }));
                }
            }}
            onContinue={() => {
                if (isByClickingChangeinSelection) {
                    navigate(AppRoute.EquipmentSelection, {
                        state: { isSecondSystem: secondSystem || changingSystemGroup === SystemGroup.SystemGroup2 },
                    });
                } else {
                    //Wizard flow
                    navigate(AppRoute.EquipmentSelection, { state: { isSecondSystem: secondSystem } });
                }
            }}
            onSelectIAQ={() => {
                if (isByClickingChangeinSelection) {
                    navigate(AppRoute.IAQOptions, {
                        state: {
                            systemGroup: secondSystem ? SystemGroup.SystemGroup2 : SystemGroup.SystemGroup1,
                            isSecondSystem: secondSystem,
                            isByClickingChangeinSelection: isByClickingChangeinSelection,
                        },
                    });
                } else {
                    //Wizard flow
                    navigate(AppRoute.IAQOptions, {
                        state: {
                            systemGroup: secondSystem ? SystemGroup.SystemGroup2 : SystemGroup.SystemGroup1,
                            isSecondSystem: secondSystem,
                        },
                    });
                }
            }}
        />
    );

    function handleEditClick(product: any) {
        const selectedMainItem = setMainItem(product);
        replaceOrSetMain(selectedMainItem);
    }
}

export default EquipmentOptionContainer;
