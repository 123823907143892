import * as React from "react"
import { SVGProps } from "react"
const OtpErrorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    data-name="Group 39"
    {...props}
  >
    <g data-name="Component 94 \u2013 1">
      <circle
        cx={12}
        cy={12}
        r={12}
        fill="#f97f7f"
        data-name="Ellipse 11"
        opacity={0.2}
      />
      <g
        fill="none"
        stroke="#f86767"
        strokeLinecap="round"
        strokeWidth={2.5}
        data-name="Group 38"
      >
        <path d="M12 13V8" data-name="Line 1" />
        <path d="M12 17h0" data-name="Line 2" />
      </g>
    </g>
  </svg>
)
export default OtpErrorIcon
