import { DuctlessFirstSquareFootage, DuctlessNumberOfRooms, DuctlessSecondSquareFootage, FurnaceEfficiency, HomeSystem, NumberOfHVAC, PackageReplacementType, PartialSystemType, SplitOrPackage, SplitReplacementType, SquareFootage } from  "models/productCriteria";
import { Options } from "../../components/WizardInputs";

export const tooltipParagraphText = {
    fullText:
        'Air and Heat Done Right offers turnkey HVAC replacement solutions for your home, whether your are replacing your entire system, part of your system, or adding a new system to your home.',
    HVACSystem:
        'Select <b style="font-weight:500">"Single"</b> to quote one HVAC system or partial system (like AC only or heat only) and choose <b style="font-weight:500">“Multiple“</b> if you would like us to quote more than one system',
    HVACProductTypes:
        'Select <b style="font-weight:500">"Complete"</b> to quote an entire heating/cooling system, <b style="font-weight:500">“Partial”</b> if you only require an AC, furnace or heat pump replacement and <b style="font-weight:500">Ductless"</b> for a ductless (Mini-Split) system',
    SplitOrPackage:
        'A <b style="font-weight:500">"Split-System"</b> has two primary components (the in-home unit and a separate outdoor unit) while a <b style="font-weight:500">“Packaged Outdoor Unit"</b> has a single large box placed outside or on the roof containing both heating and AC components',
    SplitReplacement: 'Select the heating and cooling options you would like for your home',
    PackageReplacement: 'Please select the type of packaged system that you will be replacing.',
    PartialSystem:
        'If you only need to replace your AC choose <b style="font-weight:500">"AC/Coil”</b>. Select <b style="font-weight:500">“Furnace”</b> for a replacement gas furnace and choose <b style="font-weight:500">“Upgrade Furnace Efficiency”</b> to upgrade your current ≤ 80% efficient furnace with a ≥ 90% efficient furnace',
    efficiency:
        'Select <b style="font-weight:500">"≤ 80% efficient (metal flue pipe)"</b> if your current system has a single metal exhaust flue pipe and <b style="font-weight:500">"≥ 90% efficient (PVC Flue Pipes)"</b> if your system has two PVC flue pipes (one for fresh air intake and one for exhaust)',
    ductless: 'Select the quantity of rooms you will install the indoor ductless air handler units into.',
    ductlessOneRoom:
        'Select the room size for each room that the indoor ductless air handlers will be heating and cooling',
    ductlessTwoRoom:
        'Select the room size for each room that the indoor ductless air handlers will be heating and cooling',
    squareFootage:
        'Select your home’s approximate square footage. If you have a basement, exclude that footage from your home’s total area.',
};

export const HvacSystemOptions: Options[] = [
    { id: 'hvac-system-opt1', value: NumberOfHVAC.Single, label: 'One' },
    { id: 'hvac-system-opt2', value: NumberOfHVAC.Double, label: 'Two' },
    { id: 'hvac-system-opt3', value: NumberOfHVAC.Multiple, label: 'Three +' },
];
export const DuctlessRoomsOptions: Options[] = [
    { id: 'ductless-system-opt-1', value: DuctlessNumberOfRooms.Single, label: 'One' },
    { id: 'ductless-system-opt-2', value: DuctlessNumberOfRooms.Double, label: 'Two' },
    { id: 'ductless-system-opt-3', value: DuctlessNumberOfRooms.Multiple, label: 'Three +' },
];
export const DuctlessFirstSquareFootageOptions: Options[] = [
    { id: 'ductless-area-system-opt1', value: DuctlessFirstSquareFootage.Square250, label: '< 250 sq ft' },
    { id: 'ductless-area-system-opt2', value: DuctlessFirstSquareFootage.Square400, label: '251-400 sq ft' },
    { id: 'ductless-area-system-opt3', value: DuctlessFirstSquareFootage.Square600, label: '401-600 sq ft' },
    { id: 'ductless-area-system-opt4', value: DuctlessFirstSquareFootage.Square850, label: '601-850 sq ft' },
];
export const DuctlessSecondSquareFootageOptions: Options[] = [
    { id: 'ductless-area-system-opt1', value: DuctlessSecondSquareFootage.Square250, label: '< 250 sq ft' },
    { id: 'ductless-area-system-opt2', value: DuctlessSecondSquareFootage.Square400, label: '251-400 sq ft' },
    { id: 'ductless-area-system-opt3', value: DuctlessSecondSquareFootage.Square600, label: '401-600 sq ft' },
    { id: 'ductless-area-system-opt4', value: DuctlessSecondSquareFootage.Square850, label: '601-850 sq ft' },
];
export const SquareFeetOptions: Options[] = [
    { id: 'square-feet-opt1', value: SquareFootage.Square750, label: '<750 sq ft' },
    { id: 'square-feet-opt2', value: SquareFootage.Square1000, label: '751-1000 sq ft' },
    { id: 'square-feet-opt3', value: SquareFootage.Square1250, label: '1001-1250 sq ft' },
    { id: 'square-feet-opt4', value: SquareFootage.Square1500, label: '1251-1500 sq ft' },
    { id: 'square-feet-opt5', value: SquareFootage.Square1750, label: '1501-1750 sq ft' },
    { id: 'square-feet-opt6', value: SquareFootage.Square2000, label: '1751 – 2000 sq ft' },
    { id: 'square-feet-opt7', value: SquareFootage.Square2500, label: '2001-2500 sq ft' },
    { id: 'square-feet-opt8', value: SquareFootage.Square2600, label: '2500+ sq ft' },
];
export const CompleteOrPartialOrDuctlessOptions: Options[] = [
    { id: 'Complete-system-opt1', value: HomeSystem.Complete, label: 'Complete (Heat & AC)' },
    { id: 'Partial-system-opt2', value: HomeSystem.Partial, label: 'Partial (Heat or AC)' },
    { id: 'Ductless-system-opt3', value: HomeSystem.Ductless, label: 'Ductless' },
    { id: 'IAQ-system-opt4', value: HomeSystem.IAQ, label: 'Indoor Air Quality Package' },
];
export const SplitTypeReplacementOptions: Options[] = [
    { id: 'split-system-opt1', value: SplitReplacementType.HeatPump, label: 'Split Heat Pump' },
    { id: 'split-system-opt2', value: SplitReplacementType.FurnaceAC, label: 'AC & Gas Furnace' },
    {
        id: 'split-system-opt3',
        value: SplitReplacementType.ElectricalFurnaceAC,
        label: 'AC & Electric Furnace',
    },
    {
        id: 'split-system-opt4',
        value: SplitReplacementType.HighEfficiencyFurnaceAC,
        label: 'Replace AC & Upgrade Furnace Efficiency',
    },
];
export const PackagedTypeReplacementOptions: Options[] = [
    { id: 'packaged-system-opt1', value: PackageReplacementType.HeatPumpPack, label: 'Heat Pump' },
    { id: 'packaged-system-opt2', value: PackageReplacementType.ElectricalHeatAC, label: 'AC/Electrical Heat' },
    { id: 'packaged-system-opt3', value: PackageReplacementType.GasHeatAC, label: 'AC/Gas Heat' },
];

export const PartialTypeReplacementOptions: Options[] = [
    { id: 'partial-replacement-opt1', value: PartialSystemType.CoilAC, label: 'AC/Coil' },
    { id: 'partial-replacement-opt2', value: PartialSystemType.Furnace, label: 'Furnace' },
    { id: 'partial-replacement-opt3', value: PartialSystemType.FurnaceEfficiency, label: 'Upgrade Furnace Efficiency' },
];
export const SplitOrPackageOptions: Options[] = [
    { id: 'home-system-opt1', value: SplitOrPackage.Split, label: 'Split System' },
    { id: 'home-system-opt2', value: SplitOrPackage.Package, label: 'Packaged Outdoor Unit' },
];
export const EfficiencyOptions: Options[] = [
    { id: 'efficiency-opt1', value: FurnaceEfficiency.Percent80, label: '≤ 80% Efficient, metal pipe' },
    { id: 'efficiency-opt2', value: FurnaceEfficiency.Percent90, label: '≥ 90% Efficient, plastic pipe(s)' },
];
