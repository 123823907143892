import React from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { WizardLogoNewWithLink } from "../../components/Logo";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import { SXCssObject } from "../../models/ThemeImages";
import { Paragraph } from "../Paragraph";
import { FPL_STRESS_FREE_ROUTE } from "./constants";

const createCssObject = (theme: Theme): SXCssObject => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  main: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
    paddingTop: 14,
  },
  footerActions: {
    width: {
      sm: "100%",
    },
    padding: {
      xs: 1,
      sm: 2,
    },
    py: {
      xs: 2,
      sm: 3,
    },
    height: {
      xs: "auto",
    },
    backgroundColor: theme.customProperties.headerFooter,
  },
  tooltipIcon: {
    width: "29px",
    height: "27px",
    marginRight: "5px",
    marginBottom: "-3px",
  },
  link: {
    color: "common.white",
    fontSize: {
      xs:'14px',
      md:"12px"
    },
    lineHeight: "0.8rem",
    textTransform:{
      xs:'uppercase',
      md:'inherit'
    }
  },
  footerText: {
    mt: {
      xs: 2,
      md: 3,
    },
    color: "white",
  },
});

export default function Footer() {
  const cssObject: SXCssObject = createCssObject(useTheme());
  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const isDownSm = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={[cssObject.footerActions, { display: "flex", alignItems: "center" }]}
    >
      <Container disableGutters maxWidth="xl">
        <Container maxWidth={"lg"}>
          <Grid container spacing={2}>
            <Grid
              container
              alignItems={"start"}
              justifyContent={"center"}
              item
              xs={12}
              sm={12}
              md={4}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{
                  borderBottom: {
                    xs: "1px solid #CCCCCC",
                    md: "none",
                  },
                  width: "100%",
                  pb: {
                    xs: 2,
                    md: 0,
                  },
                }}
              >
                {isUpMd && (
                  <Stack sx={{ mb: 1 }}>
                    <Paragraph fontSizeVerySmall sx={{ color: "white" }}>
                      ©2009-2024, Nextera Energy. All rights reserved.
                    </Paragraph>
                  </Stack>
                )}
                <Stack
                  direction={isDownSm ? "column" : "row"}
                  sx={{ width: "100%" }}
                  alignItems={"center"}
                  spacing={isDownSm ? 1.5 : 1}
                >
                  <Link
                    href={FPL_STRESS_FREE_ROUTE.TERMS}
                    underline={isDownSm ? "none" : "always"}
                    target="_blank"
                    rel="noopener"
                    sx={cssObject.link}
                  >
                    Terms
                  </Link>
                  {isUpMd && (
                    <Divider orientation="vertical" variant="middle" flexItem />
                  )}
                  <Link
                    href={FPL_STRESS_FREE_ROUTE.PRIVACY_POLICY}
                    underline={isDownSm ? "none" : "always"}
                    target="_blank"
                    rel="noopener"
                    sx={cssObject.link}
                  >
                    Privacy Policy
                  </Link>
                  {isUpMd && (
                    <Divider orientation="vertical" variant="middle" flexItem />
                  )}
                  <Link
                    href={FPL_STRESS_FREE_ROUTE.CONTRACTOR_LOGIN}
                    underline={isDownSm ? "none" : "always"}
                    target="_blank"
                    rel="noopener"
                    sx={cssObject.link}
                  >
                    Contractors Login
                  </Link>
                </Stack>
              </Box>
            </Grid>
            <Grid
              container
              justifyContent={"center"}
              item
              xs={12}
              sm={12}
              md={4}
            >
              <WizardLogoNewWithLink id={"nav-home"} />
            </Grid>
            <Grid
              container
              justifyContent={isUpMd ? "flex-end" : "flex-start"}
              item
              xs={12}
              sm={12}
              md={4}
            >
              <Stack
                direction="row"
                alignItems={"center"}
                spacing={2}
                sx={{
                  width: "100%",
                  textAlign: {
                    xs: "center",
                    md: "right",
                  },
                  justifyContent: {
                    xs: "center",
                    md: "flex-end",
                  },
                }}
              >
                <Link
                  href={FPL_STRESS_FREE_ROUTE.FACEBOOK}
                  underline="none"
                  sx={cssObject.socialLink}
                  target="_blank"
                  rel="noopener"
                >
                  <FacebookOutlinedIcon
                    sx={{ fontSize: "18px" }}
                    color="secondary"
                  />
                </Link>
                <Link
                  href={FPL_STRESS_FREE_ROUTE.INSTAGRAM}
                  underline="none"
                  sx={cssObject.socialLink}
                  target="_blank"
                  rel="noopener"
                >
                  <InstagramIcon sx={{ fontSize: "18px" }} color="secondary" />
                </Link>
                <Link
                  href={FPL_STRESS_FREE_ROUTE.TWITTER}
                  target="_blank"
                  rel="noopener"
                  underline="none"
                  sx={cssObject.socialLink}
                >
                  <XIcon sx={{ fontSize: "18px" }} color="secondary" />
                </Link>
              </Stack>
              {isDownSm && (
                <Stack sx={{ mt: 1, width: "100%", justifyContent: "center" }}>
                  <Paragraph
                    fontSizeVerySmall
                    sx={{ color: "white", textAlign: "center" }}
                  >
                    ©2009-2024, Nextera Energy. All rights reserved.
                  </Paragraph>
                </Stack>
              )}
            </Grid>
          </Grid>
          {isDownSm && <Divider sx={{ mt: 2 }} />}
          <Grid container>
            <Grid item xs={12}>
              <Paragraph fontSizeVerySmall sx={cssObject.footerText}>
                FPL HOME IS AN UNREGULATED SUBSIDIARY OF FLORIDA POWER & LIGHT
                COMPANY (FPL). The FPL Home Stress-Free SM program is offered
                and provided by FPL Home, License No. CAC1820493, and not FPL.
              </Paragraph>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Box>
  );
}
