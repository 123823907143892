import React from "react";
import { useField } from "formik";
import { DateFieldSchedulePage } from "./DateFieldSchedulePage";

interface Props {
    id?: string;
    fullWidth?: boolean;
    name: string;
    label?: string;
    type?: string;
    placeholder?: string;
    maxDate?: Date;
    minDate?: Date;
    shouldDisableDate?: (date: Date) => boolean;
}

export default function FormikDateField(props: Props) {
  const { id, fullWidth = false, name, label, ...other } = props;
  const [field, , { setValue }] = useField(props);
  return (
    <DateFieldSchedulePage
      id={id ? id : `${name}-input`}
      fullWidth={fullWidth}
      label={label}
      {...other}
      {...field}
      enableErrorDisplay={false}
      onChange={(v: any) => setValue(v || null)}
    />
  );
}
