import React from 'react';
import { useSelector } from 'react-redux';
import { selectProductLoading } from '../../store/selectors/productSearch';
import { selectProductCriteriaState } from '../../store/selectors/productCriteria';
import EquipmentSelection from './EquipmentSelection';
import { AppRoute } from '../../models/route';
import { selectCombinedList, selectPreCartAddon, selectPreCartIAQ, selectPreCartMain } from '../../store/selectors/selection';
import { Navigate, useLocation } from 'react-router-dom';
import { selectGenerateQuoteLoading } from '../../store/selectors/cartSelector';

function EquipmentSelectionContainer() {
    const items: any[] = useSelector(selectCombinedList);
    const preCartItemsMain = useSelector(selectPreCartMain);
    const preCartItemsIAQ = useSelector(selectPreCartIAQ);
    const preCartItemsAddons = useSelector(selectPreCartAddon);

    const criteria = useSelector(selectProductCriteriaState);

    const isproductSearchLoading: boolean = useSelector(selectProductLoading);
    const isgenerateQuoteLoading: boolean = useSelector(selectGenerateQuoteLoading);
    

    const secondSystemWizard = useLocation();
    if (!items || items.length === 0) {
        return <Navigate to={AppRoute.AboutYourHome} />;
    }
    return (
        <EquipmentSelection
            criteria={criteria}
            secondSystemWizard={secondSystemWizard}
            preCartItemsMain={preCartItemsMain}
            preCartItemsIAQ={preCartItemsIAQ}
            preCartItemsAddons={preCartItemsAddons}
            loading={isproductSearchLoading || isgenerateQuoteLoading} 
        />
    );
}

export default EquipmentSelectionContainer;
