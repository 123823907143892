export interface ProductCriteria {
    squareFootage?: SquareFootage;
    homeSystem?: HomeSystem;
    splitOrPackage?: SplitOrPackage;
    numberOfHVAC?: NumberOfHVAC;
    promotionCode?: string;
    splitReplacementType?: SplitReplacementType;
    packageReplacementType?: PackageReplacementType;
    partialSystemType?: PartialSystemType;
    ductlessFirstSquareFootage?: DuctlessFirstSquareFootage;
    efficiency?: FurnaceEfficiency | string;

    ductlessSecondSquareFootage?: DuctlessSecondSquareFootage;

    ductlessNumberOfRooms?: DuctlessNumberOfRooms;

    doubleSystemFlag?: DoubleSystemWizardState;
}
export enum SquareFootage {
    Square750 = '750',
    Square1000 = '1000',
    Square1250 = '1250',
    Square1500 = '1500',
    Square1750 = '1750',
    Square2000 = '2000',
    Square2500 = '2500',
    Square2600 = '2600',
}
export enum DuctlessFirstSquareFootage {
    Square250 = '250',
    Square400 = '400',
    Square600 = '600',
    Square850 = '850',
}
export enum DuctlessSecondSquareFootage {
    Square250 = '250',
    Square400 = '400',
    Square600 = '600',
    Square850 = '850',
}
export enum DuctlessNumberOfRooms {
    Single = 'One',
    Double = 'Two',
    Multiple = 'Three+',
}
export enum HomeSystem {
    Complete = 'Complete',
    Partial = 'Partial',
    Ductless = 'Ductless',
    IAQ = 'IAQ',
}
export enum SplitReplacementType {
    HeatPump = 'Split Heat Pump',
    FurnaceAC = 'AC & Gas Furnace',
    ElectricalFurnaceAC = 'AC & Electric Furnace',
    HighEfficiencyFurnaceAC = 'Replace AC & Upgrade Furnace Efficiency',
}
export enum PackageReplacementType {
    HeatPumpPack = 'Heat Pump',
    ElectricalHeatAC = 'Electric Pack',
    GasHeatAC = 'Gas Pack',
}
export enum PartialSystemType {
    CoilAC = 'AC/Coil',
    Furnace = 'Furnace',
    FurnaceEfficiency = 'Upgrade Furnace Efficiency',
}
export enum SplitOrPackage {
    Split = 'Split',
    Package = 'Package',
}
export enum NumberOfHVAC {
    Single = 'Single',
    Double = 'Double',
    Multiple = 'Multiple',
}
export enum FurnaceEfficiency {
    Percent80 = '80',
    Percent90 = '90',
}

//traking the state of wizard in double system flow( in 1st or 2nd system)
export enum DoubleSystemWizardState {
    First = 'First',
    Second = 'Second',
    NotDoubleSystemFlow = '',
}