import * as React from 'react';

function TonCapacity(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
            <defs>
                <style>
                    {
                        '.prefix__b6,.prefix__c1{fill:none;stroke-width:1.5px}.prefix__b6{stroke-miterlimit:10}.prefix__c1{stroke-linecap:round;stroke-linejoin:round}'
                    }
                </style>
            </defs>
            <g transform="translate(-436.294 -289.175)">
                <rect
                    className="prefix__b6"
                    width={24.073}
                    height={14.405}
                    rx={2.515}
                    stroke={`#111111`}
                    transform="translate(444.294 298.176)"
                />
                <path
                    className="prefix__b6"
                    stroke={`#111111`}
                    d="M447.05 312.582l1.5-2.716a2.038 2.038 0 011.786-1.055h12.546a2.04 2.04 0 011.807 1.094l1.378 2.632"
                />
                <path
                    className="prefix__c1"
                    stroke={`#111111`}
                    d="M449.279 315.696v3.287M452.918 315.696v5.478M456.557 315.696v3.287M460.195 315.696v5.478M463.834 315.696v3.287"
                />
                <circle
                    cx={0.777}
                    cy={0.777}
                    r={0.777}
                    stroke={`#111111`}
                    transform="translate(447.411 300.984)"
                    fill="none"
                />
            </g>
        </svg>
    );
}

export default TonCapacity;
