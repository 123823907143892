import React, { useRef } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import CalendarTodayOutlined from '@mui/icons-material/CalendarTodayOutlined';

import { Box, FormControl, FormHelperText, FormLabel } from '@mui/material';
import { SXCssObject } from '../models/ThemeImages';
const createCssObject = (theme: Theme): SXCssObject => ({
    root: {},
    label: {
      mb: theme.spacing(1),
      fontSize: "14px",
      color: theme.palette.text.primary,
      "&.Mui-focused": {
        color: theme.palette.text.primary,
      },
      "&.Mui-error": {
        color: theme.palette.error.main,
      },
    },
    datePicker: {
        '& .MuiTextField-root': {
            height: '48px',
            margin: '0px ',
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            fontSize: '1.25rem',
            '& .MuiOutlinedInput-inputMarginDense': {
                padding: '10px 10px !important',
                fontSize: '1rem',
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.action.hover}`,
        },
        '& .MuiTextField-root .MuiInputBase-root': {
            height: '100%',
            borderRadius: '0',
            paddingRight: '0px',
            fontSize: '0.875rem',
            width: '100%',
            margin: '0px !important',
        },
    },
    "&.Mui-error": {
      color: theme.palette.error.main,
    },
});

interface Props {
    id: string;
    type?: string;
    label?: string;
    fullWidth?: boolean;
    helperText?: string;
    error?: boolean;
    format?: string;
    placeholder?: string;
    disablePast?: boolean;
    enableErrorDisplay?: boolean;
    value?: any;
    shouldDisableDate?: (date: Date) => boolean;
    onChange: (value: Date | null) => void;
    minDate?: Date;
}
export function DateFieldSchedulePage(props: Props) {
  const theme = useTheme();
  const classes: SXCssObject = createCssObject(theme);
  const {
    id,
    label,
    fullWidth,
    helperText,
    error,
    // placeholder,
    // format,
    enableErrorDisplay = false,
    value,
    minDate,
    // maxDate,
    onChange,
    ...other
  } = props;

    const ref = useRef<HTMLDivElement>(null);

    return (
        <Box sx={classes.datePicker} ref={ref}>
            <FormControl id={`${id}-root`} fullWidth={fullWidth} sx={classes.root} error={enableErrorDisplay && error}>
            {label && (
          <FormLabel id={`${id}-label`} htmlFor={id} sx={classes.label}>
            {label}
          </FormLabel>
        )}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        shouldDisableDate={props.shouldDisableDate}
                        value={value || minDate}
                        onChange={(v: Date | null) => {
                            onChange(v);
                        }}
                        disablePast={true}
                        minDate={minDate}
                        slotProps={{
                            openPickerIcon: CalendarTodayOutlined,
                            mobilePaper: {
                                sx: {
                                    '& .MuiTypography-root': {
                                        fontSize: '13px !important',
                                    },
                                    '& .MuiButtonBase-root': {
                                        fontSize: '13px !important',
                                    },
                                    '& .Mui-selected': {
                                        color: '#ffffff !important',
                                    },
                                },
                            },
                            desktopPaper: {
                                sx: {
                                    '& .MuiTypography-root': {
                                        fontSize: '13px !important',
                                    },
                                    '& .MuiButtonBase-root': {
                                        fontSize: '13px !important',
                                    },
                                    '& .Mui-selected': {
                                        color: '#ffffff !important',
                                    },
                                },
                            },
                        }}
                        sx={{
                            svg: { color: 'primary.main' },
                            input: { width: 'calc(100% - 65px)' },
                        }}
                        {...other}
                    />
                </LocalizationProvider>

                {enableErrorDisplay && (
                    <FormHelperText id={`${id}-helper`} sx={classes.helperText}>
                        {helperText}
                    </FormHelperText>
                )}
            </FormControl>
        </Box>
    );
}
