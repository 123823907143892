import React from 'react';
import Grid from '@mui/material/Grid';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import DoneIcon from '@mui/icons-material/Done';
import { SXCssObject } from '../../models/ThemeImages';
import { selectPreCartAddon, selectPreCartIAQ, selectPreCartMain } from '../../store/selectors/selection';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../models/route';
import { setDoubleSystemFlag } from '../../store/slice/productCriteriaSlice';
import { DoubleSystemWizardState } from '../../models/productCriteria';
import { Paragraph } from '../../components/Paragraph';
import EditIconSmall from '../../components/icons/EditIconSmall';
import { SystemGroup } from '../../models/cart';

const createCssObject = (theme: Theme): SXCssObject => ({
    b: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    firstSystemContainer: {
        background: theme.palette.common.white,
        borderRadius: '4px',
        justifyContent: 'flex-end',
        padding: '0px 20px 25px 20px',
    },
    firstSystemBox: {
        background: theme.palette.common.white,
        borderRadius: '4px',
        padding: '20px 20px 10px 20px',
        flexWrap: 'nowrap',
    },
    firstSystemBoxHeading: {
        fontSize: '1.25rem',
        lineHeight: '1.625rem',
    },
    firstSystemBoxContent: {
        borderLeft: {
            sm: '1px solid #D5D5D5',
        },
        paddingLeft: {
            sm: '15px !important',
        },
    },
    doneIconStyle: {
        color: theme.palette.primary.dark,
        fontSize: '15px',
        fontWeight: 900,
        position: 'relative',
        top: '3px',
    },
    singleLine: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    readMoreText: {
        color: theme.palette.primary.dark,
        cursor: 'pointer',
        fontWeight: theme.typography.fontWeightMedium,
        float: 'right',
        paddingRight: '20px',
        paddingBottom: '10px',
        paddingLeft: theme.spacing(0.3),
    },
    readLessText: {
        color: theme.palette.primary.dark,
        cursor: 'pointer',
        fontWeight: theme.typography.fontWeightMedium,
        float: 'right',
        paddingBottom: '10px',
        paddingLeft: theme.spacing(0.3),
    },
    editIcon: {
        display: 'flex',
        justifyContent: 'end',
        cursor: 'pointer',
    },
});

export default function FirstSystemInfo() {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const dispatch = useDispatch();
    const theme = useTheme();
    const preCartItemsMain = useSelector(selectPreCartMain);
    const preCartItemsIaq = useSelector(selectPreCartIAQ);
    const preCartItemsAddons = useSelector(selectPreCartAddon);
    const navigate = useNavigate();
    const [showFullText, setShowFullText] = React.useState(false);
    const preCartExistingAddons = preCartItemsAddons.filter((addon:any) => addon.quantity > 0);
    const mainSystemsInCart = preCartItemsMain?.filter((cartItem:any) => cartItem.systemGroup === SystemGroup.SystemGroup1);
    const iaqSystemsInCart = preCartItemsIaq?.filter((cartItem:any) => cartItem.systemGroup === SystemGroup.SystemGroup1);
    const addOnsInCart = preCartExistingAddons;
    const editClickHandle = () => {
        dispatch(setDoubleSystemFlag({ doubleSystemFlag: DoubleSystemWizardState.First }));
        navigate(AppRoute.EquipmentSelection);
    };
    const toggleFullText = () => {
        setShowFullText(!showFullText);
    };

    const firstSystemMinimisedDescriptionHVAC = () =>
        mainSystemsInCart &&
        mainSystemsInCart.length > 0 && (
            <Box>
                <Paragraph fontSizeVerySmall={true} size={'small'}>
                    {mainSystemsInCart[0]?.productAttributes.systemType !== 'IAQ' ? (
                        <Box sx={iaqSystemsInCart && cssObject.singleLine}>
                            <Box component={'b'} sx={cssObject.b}>
                                HVAC:{' '}
                            </Box>
                            {mainSystemsInCart[0]?.productAttributes.level +
                                ' ' +
                                mainSystemsInCart[0]?.productAttributes.systemType}
                            {iaqSystemsInCart &&
                                iaqSystemsInCart.length > 0 &&
                                mainSystemsInCart[0]?.productAttributes &&
                                mainSystemsInCart[0]?.productAttributes.tonnage &&
                                ', ' +
                                    mainSystemsInCart[0]?.productAttributes.tonnage +
                                    ' Ton' +
                                    mainSystemsInCart[0]?.productAttributes &&
                                mainSystemsInCart[0]?.productAttributes.seer2 &&
                                ', ' +
                                    mainSystemsInCart[0]?.productAttributes.seer2 +
                                    ' SEER2' +
                                    mainSystemsInCart[0]?.productAttributes &&
                                mainSystemsInCart[0]?.productAttributes.efficiency &&
                                ', ' + mainSystemsInCart[0]?.productAttributes.efficiency + '% Efficiency'}
                        </Box>
                    ) : (
                        <Box>
                            {' '}
                            <Box component={'b'} sx={cssObject.b}>
                                HVAC:{' '}
                            </Box>
                            {mainSystemsInCart[0]?.name}
                        </Box>
                    )}
                </Paragraph>

                {iaqSystemsInCart && iaqSystemsInCart.length === 0 && (
                    <Grid container>
                        {mainSystemsInCart[0]?.productAttributes.tonnage && (
                            <Grid item>
                                <Paragraph fontSizeVerySmall={true}>
                                    <DoneIcon sx={cssObject.doneIconStyle} />
                                    &nbsp;Ton&nbsp;{mainSystemsInCart[0]?.productAttributes.tonnage}&ensp;
                                </Paragraph>
                            </Grid>
                        )}
                        {mainSystemsInCart[0]?.productAttributes.seer2 && (
                            <Grid item>
                                <Paragraph fontSizeVerySmall={true}>
                                    <DoneIcon sx={cssObject.doneIconStyle} /> SEER2&nbsp;
                                    {mainSystemsInCart[0]?.productAttributes.seer2} &ensp;
                                </Paragraph>
                            </Grid>
                        )}
                        {mainSystemsInCart[0]?.productAttributes.efficiency &&
                            mainSystemsInCart[0]?.productAttributes.efficiency !== 'N/A' && (
                                <Grid item>
                                    <Paragraph fontSizeVerySmall={true}>
                                        <DoneIcon sx={cssObject.doneIconStyle} /> Efficiency:&nbsp;
                                        {mainSystemsInCart[0]?.productAttributes.efficiency}% &ensp;
                                    </Paragraph>
                                </Grid>
                            )}
                    </Grid>
                )}
            </Box>
        );
    const firstSystemMinimisedDescriptionIAQ = () =>
        iaqSystemsInCart &&
        iaqSystemsInCart.length > 0 && (
            <Box>
                <Paragraph fontSizeVerySmall={true} size={'small'}>
                    <Box sx={cssObject.singleLine}>
                        <Box component={'b'} sx={cssObject.b}>
                            {iaqSystemsInCart[0] && iaqSystemsInCart[0].productAttributes.level} IAQ Bundle:{' '}
                        </Box>
                        {iaqSystemsInCart[0]?.name}
                    </Box>
                </Paragraph>
            </Box>
        );
    const firstSystemMinimisedDescriptionAddon = () =>
        addOnsInCart &&
        addOnsInCart.length > 0 && (
            <Box>
                <Paragraph fontSizeVerySmall={true} size={'small'}>
                    <Box sx={cssObject.singleLine}>
                        <Box component={'b'} sx={cssObject.b}>
                            Addons:
                        </Box>{' '}
                        {addOnsInCart.map((addOn:any, index:any) => {
                            if (index === 0) return addOn.name;
                            else return ', ' + addOn.name;
                        })}
                    </Box>
                    {/* {trimLength && striptags(addOnsInCart[0].name + ' ').slice(0, trimLength) + '...'} */}
                </Paragraph>
            </Box>
        );

    function firstSystemMaximisedContent() {
        return (
            <Box sx={cssObject.firstSystemContainer}>
                {mainSystemsInCart && (
                    <Box>
                        <hr style={{ border: '1px solid #EAEAF0' }} />
                        <Box>
                            <Paragraph semibold>HVAC</Paragraph>{' '}
                        </Box>
                        <Grid container>
                            <Grid item>
                                {iaqSystemsInCart && iaqSystemsInCart.length === 0 ? (
                                    <Paragraph>{mainSystemsInCart[0]?.name}</Paragraph>
                                ) : (
                                    <Paragraph>
                                        {mainSystemsInCart[0]?.productAttributes.level}{' '}
                                        {mainSystemsInCart[0]?.productAttributes.systemType}
                                    </Paragraph>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container>
                            {mainSystemsInCart[0]?.productAttributes.tonnage && (
                                <Grid item>
                                    <Paragraph>
                                        <DoneIcon sx={cssObject.doneIconStyle} />
                                        &nbsp;Ton&nbsp;{mainSystemsInCart[0]?.productAttributes.tonnage}&ensp;
                                    </Paragraph>
                                </Grid>
                            )}
                            {mainSystemsInCart[0]?.productAttributes.seer && (
                                <Grid item>
                                    <Paragraph>
                                        <DoneIcon sx={cssObject.doneIconStyle} /> SEER2&nbsp;
                                        {mainSystemsInCart[0]?.productAttributes.seer2} &ensp;
                                    </Paragraph>
                                </Grid>
                            )}
                            {mainSystemsInCart[0]?.productAttributes.efficiency &&
                                mainSystemsInCart[0]?.productAttributes.efficiency !== 'N/A' && (
                                    <Grid item>
                                        <Paragraph>
                                            <DoneIcon sx={cssObject.doneIconStyle} /> Efficiency:&nbsp;
                                            {mainSystemsInCart[0]?.productAttributes.efficiency}% &ensp;
                                        </Paragraph>
                                    </Grid>
                                )}
                        </Grid>
                    </Box>
                )}

                {iaqSystemsInCart && iaqSystemsInCart.length > 0 && (
                    <Box>
                        <hr style={{ border: '1px solid #EAEAF0' }} />
                        <Grid container>
                            <Grid item xs={12}>
                                <Paragraph semibold>
                                    {iaqSystemsInCart[0]?.productAttributes.level}{' '}
                                    {iaqSystemsInCart[0]?.productAttributes.systemType} Bundle
                                </Paragraph>
                            </Grid>
                            <Grid item xs={12}>
                                <Paragraph>
                                    {/* {iaqSystemsInCart[0]?.categories.map((category) =>
                                    category.name.replace('IAQ Bundle', '')
                                )} */}
                                    {iaqSystemsInCart[0]?.name}{' '}
                                </Paragraph>
                            </Grid>
                        </Grid>
                    </Box>
                )}
                {addOnsInCart && addOnsInCart.length > 0 && (
                    <Box>
                        <hr style={{ border: '1px solid #EAEAF0' }} />

                        <Grid container>
                            <Grid item xs={12}>
                                <Paragraph semibold>Addons</Paragraph>
                            </Grid>
                            {addOnsInCart?.map((addon:any) => (
                                <Grid item xs={12}>
                                    <Paragraph>
                                        {addon.categories[0].name}
                                        {' : '}
                                        {addon.quantity}x&nbsp;{addon.name}
                                    </Paragraph>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}
                <Box sx={cssObject.readLessText} onClick={toggleFullText}>
                    Read less
                </Box>
            </Box>
        );
    }
    return (
        <div style={{ background: theme.palette.common.white }}>
            <Grid container sx={cssObject.firstSystemBox}>
                <Grid container item xs={11} style={{ minWidth: '97%' }}>
                    <Grid item sm={5} xs={12}>
                        <Paragraph>Your First Replacement</Paragraph>
                        <Paragraph semibold>
                            <Box component={'span'} sx={cssObject.firstSystemBoxHeading}>
                                {/* {mainSystemsInCart && getSystemType(mainSystemsInCart![0], 0)} */}
                            </Box>
                        </Paragraph>
                    </Grid>
                    {!showFullText && (
                        <Grid sx={cssObject.firstSystemBoxContent} item sm={7} xs={12}>
                            <Paragraph size="small" fontSizeVerySmall>
                                {firstSystemMinimisedDescriptionHVAC()}
                                {firstSystemMinimisedDescriptionIAQ()}
                                {firstSystemMinimisedDescriptionAddon()}
                            </Paragraph>
                        </Grid>
                    )}
                </Grid>

                <Grid item xs={1} onClick={editClickHandle} sx={cssObject.editIcon}>
                    <EditIconSmall />
                </Grid>
            </Grid>
            {!showFullText &&
                ((iaqSystemsInCart && iaqSystemsInCart.length > 0) || (addOnsInCart && addOnsInCart.length > 0)) && (
                    <Grid justifyContent={'flex-end'} container>
                        <Grid item>
                            <Box sx={cssObject.readMoreText} onClick={toggleFullText}>
                                Read More
                            </Box>
                        </Grid>
                    </Grid>
                )}
            {showFullText && firstSystemMaximisedContent()}
        </div>
    );
}
