import * as React from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
    open: boolean;
}
export default function LoadingOverlay(props: Props) {
    return (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={props.open}>
             <CircularProgress sx={{ color: "#fff" }} />
            {/* <LoadingInternal /> */}
        </Backdrop>
    );
}
