import { Schedule } from "../../models/cart";
import React from "react";
import { useSelector } from "react-redux";
import ScheduleInspection from "./ScheduleInspection";
import { useDispatch } from "react-redux";
import ContentContainer from "../../components/ContentContainer";
import { AppRoute } from "../../models/route";
import { selectScheduleList } from "../../store/selectors/selection";
import { useNavigate } from "react-router-dom";
import {
  scheduleInspection,
} from "../../store/slice/selectionSlice";
import {
  getDealByEmail,
  getDealService,
} from "../../store/services/contactServices";
import { selectUserProfileEmail } from "../../store/selectors/userDetails";
import {
  updateProfileData,
} from "../../store/slice/userDetailsSlice";
import { placeOrder } from "../../store/slice/cartSlice";

export function ScheduleInspectionContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDisable, setIsDisable] = React.useState(false);
  const scheduleDataFromReducer = useSelector(selectScheduleList);
  const profileEmail = useSelector(selectUserProfileEmail);

  const getDeal = async () => {
    const result = await getDealByEmail(profileEmail);
    if (result?.data) {
      const getDealId = await getDealService(result?.data?.lastDealId);
      if (getDealId) {
        dispatch(updateProfileData(getDealId.data));
      }
    }
  };

  React.useEffect(() => {
    getDeal();
  }, []);

  const getMinDate = React.useMemo(() => {
    let minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);
    return minDate;
  }, []);

  let maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 30);

  function getInitialDate(start_date: Date, end_date: Date) {
    let start = new Date(start_date);
    let end = new Date(end_date);
    let dates: any = [];
    while (start <= end) {
      if (start.getDay() !== 0) {
        dates.push(new Date(start));
      }
      start.setDate(start.getDate() + 1);
    }
    return dates;
  }

  let initialsDays = getInitialDate(getMinDate, maxDate);

  let defaultScheduleData: Schedule[] = [
    {
      preferredTimeSlot: "",
      schedule: "schedule1",
      preferredDate: initialsDays[0],
    },
    {
      preferredTimeSlot: "",
      schedule: "schedule2",
      preferredDate: initialsDays[1],
    },
    {
      preferredTimeSlot: "",
      schedule: "schedule3",
      preferredDate: initialsDays[2],
    },
  ];
  const [selectedValues] = React.useState(
    scheduleDataFromReducer && scheduleDataFromReducer.length > 0
      ? scheduleDataFromReducer
      : defaultScheduleData
  );
  const handlePlaceOrder = (scheduleList: Schedule[]) => {
    if (isDisable) {
      return;
    }
    setIsDisable(true);
    dispatch(placeOrder(scheduleList));
    // setTimeout(() => {
    //   dispatch(resetUserDetails())
    //   dispatch(resetTaxRate())
    //   // @ts-ignore
    //   dispatch(resetSelection())
    //   dispatch(resetMainProductSearch())
    //   // @ts-ignore
    //   dispatch(clearWizarQuestionData())
    //   dispatch(resetCart())
    //   showSnackbarSuccess("Your order has been submitted");
    //   setTimeout(() => {
    //     window.location.replace(FPL_STRESS_FREE_ROUTE.HOME);
    //   },1000);
    // }, 1000);
  };
  return (
    <ContentContainer disableGrid>
      <ScheduleInspection
        schedule={selectedValues}
        minDate={getMinDate}
        maxDate={maxDate}
        onPrev={() => {
          navigate(-1);
        }}
        onReview={(scheduleList: Schedule[]) => {
          dispatch(scheduleInspection(scheduleList));
          navigate(AppRoute.ReviewSelection);
        }}
        onNext={(scheduleList: Schedule[]) => {
          handlePlaceOrder(scheduleList);
        }}
        isDisableBtn={isDisable}
      />
    </ContentContainer>
  );
}
