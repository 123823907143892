 import { createTheme, Theme } from '@mui/material/styles';
import { ThemeContent, ThemeImages } from '../models/ThemeImages';
import { Product } from '../models/product';
import { CartItem } from '../models/cart';
import config from '../components/common/config';

export interface ThemeWithAdditionalData extends Theme {
    images: ThemeImages;
    content: ThemeContent;
}

// A custom theme for this app
export const theme = createTheme(config.theme, { images: config.images, content: config.content });
export const themeContent = config && config.content;
export function getProductImage(product: Product | CartItem): string {
    return product.image;
}
