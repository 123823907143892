import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from  "components/common/Utilities";
import * as React from 'react';

function CallIcon(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="16"
            height="16"
            viewBox="0 0 16 16"
        >
            <defs>
                <clipPath id="clip-Icon_Glass">
                    <rect width="16" height="16" />
                </clipPath>
            </defs>
            <g id="Icon_Glass" clipPath="url(#clip-Icon_Glass)">
                <rect width="16" height="16" fill="#fff" />
                <g id="Component_8_2" data-name="Component 8 – 2">
                    <rect
                        id="Rectangle_3805"
                        data-name="Rectangle 3805"
                        width="16"
                        height="16"
                        fill="#fff"
                        opacity="0"
                    />
                    <g id="Component_40_1" data-name="Component 40 – 1" transform="translate(4 3)">
                        <path
                            id="Union_25"
                            data-name="Union 25"
                            d="M-915.5,1882.5l2,3Zm-6.6-3.5a4.1,4.1,0,0,1,4.1-4.1,4.1,4.1,0,0,1,4.1,4.1,4.1,4.1,0,0,1-4.1,4.1A4.1,4.1,0,0,1-922.1,1879Z"
                            transform="translate(922.1 -1874.9)"
                            fill="none"
                            stroke={`${themePrimaryMain}`}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.8"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default CallIcon;
