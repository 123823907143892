 
import ToggleButton from '@mui/material/ToggleButton';
 import { styled } from '@mui/material/styles';

const ChooseOptionInput = styled(ToggleButton)(({ theme }) => ({
    '& .MuiToggleButton-root': {
        color: theme.palette.action.active,
        backgroundColor: theme.palette.grey[200],
        minWidth: theme.spacing(10),
        border: '1px solid transparent',
        width: {
            xs: '100%',
            sm: 'inherit',
        },
        '&$selected': {
            color: theme.palette.action.active,
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.primary.main}`,
            '&:hover': {
                border: `1px solid ${theme.palette.primary.main}`,
                backgroundColor: theme.palette.common.white,
            },
        },
    },
}));

export default ChooseOptionInput;
