import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectProductCriteriaState,
} from "../../store/selectors/productCriteria";
import WizardHomeInfo from "./WizardHomeInfo";
import { AppRoute } from "../../models/route";
import {
  DuctlessNumberOfRooms,
  HomeSystem,
  NumberOfHVAC,
  PackageReplacementType,
  ProductCriteria,
} from  "../../models/productCriteria";
import {
  selectProductLoading,
  selectProductsIAQ,
} from  "../../store/selectors/productSearch";

import { resetProductCriteria, setProductCriteria } from  "../../store/slice/productCriteriaSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { searchProductIAQ,  searchProduct, searchSecondProduct, } from "../../store/slice/productSearchSlice";

function AboutYourHome() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const criteria = useSelector(selectProductCriteriaState);
  let loading = useSelector(selectProductLoading);
  const productsIAQ = useSelector(selectProductsIAQ);
  const emptyCriteria: ProductCriteria = {};
  // eslint-disable-next-line
  const [isContactPagePopUpOpen, setContactPagePopUpOpen] = React.useState(false);
    const location: any = useLocation();
    const secondSystem =  location?.state?.isSecondSystem;


  return (
    <WizardHomeInfo
      criteria={criteria}
      loading={isContactPagePopUpOpen ? false : loading}
      isDoubleSystem={secondSystem}
      onNext={(payload) => {
        dispatch(setProductCriteria(payload));
        //do not hit searchproduct api for following cases
        if (
          payload.numberOfHVAC !== NumberOfHVAC.Multiple &&
          payload.packageReplacementType !==
            PackageReplacementType.ElectricalHeatAC &&
          payload.ductlessNumberOfRooms !== DuctlessNumberOfRooms.Multiple
          // payload.homeSystem !== HomeSystem.Ductless
        ) {
          //do not hit searchIAQproduct api if main product itself is iaq
          if (
            payload.homeSystem !== HomeSystem.Ductless &&
            payload.homeSystem !== HomeSystem.IAQ &&
            (!productsIAQ || productsIAQ?.length === 0)
          ) {
            dispatch(
              searchProductIAQ({ ...emptyCriteria, homeSystem: HomeSystem.IAQ })
            );
          }
          secondSystem
            ? dispatch(
                searchSecondProduct({
                  shouldRedirect: true
                })
              )
            : dispatch(
                searchProduct({
                  shouldRedirect:true
                })
              );
        }
        //search product not required scenario => No Product case  && already logged/signedup
        // else if (isTokenAlive) {
        //   dispatch(
        //     searchProductSuccess({ products: [], shouldRedirect: true })
        //   );
        // }
        // if (!isTokenAlive) {
        //   setContactPagePopUpOpen(true);
        // }
      }}
      onExit={() => {
        dispatch(resetProductCriteria());
        navigate(AppRoute.AboutYourHome);
      }}
    />
  );
}

export default AboutYourHome;
