import { combineEpics } from "redux-observable";
import { getCountry$ as getCountryEpic } from "./countryEpics";
import { getProductAttribute$ } from "./productAttributesEpic";
import taxRateEpics$ from "./taxRateEpics";
import storeEpic$ from "./storeEpics";
import productSearchEpic$ from "./productSearchEpic";
import changeProductEpics$ from "./changeProductEpic";
import addressData$ from "./addressEpic";
import hubspotEpics$ from "./hubspotEpics";
import cartEpics$ from "./cartEpics";

export const rootEpic = combineEpics(
  taxRateEpics$,
  storeEpic$,
  productSearchEpic$,
  getCountryEpic,
  getProductAttribute$,
  changeProductEpics$,
  addressData$,
  hubspotEpics$,
  cartEpics$,

);
